import {
  Box,
  Grid,
  FormControlLabel,
  Radio,
  Button,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { MUIRadioGroup } from "../../common";

import { FormStepperStructure } from "../../common";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  futureSubstoragesStep,
  setFutureSubstoragesStatus,
} from "../../../redux/slices/powerOfAttorney/powerOfAttorney.slice";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

function FutureSubstoragesStep({ handleCurrentStep }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const requiredMsg = "validation.required";
  const fields = useSelector(futureSubstoragesStep);

  const submitForm = (data) => {
    dispatch(setFutureSubstoragesStatus(data));
    handleCurrentStep("next");
  };

  const schema = yup.object().shape({
    activePowerOfAttorney: yup.boolean().required(requiredMsg),
  });

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      ...fields,
    },
    resolver: yupResolver(schema),
  });

  return (
    <Box
      sx={{
        margin: "0 auto",
        minHeight: "calc(100vh - 250px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <Box sx={{ margin: "0 auto" }}>
        <Typography mb="20px" variant="body1">
          Do you want to give the power of attorney for the other substorages
          created in the future?
        </Typography>
        <Box>
          <MUIRadioGroup
            error={Boolean(errors.storage)}
            helperText={errors.storage?.message}
            control={control}
            name="activePowerOfAttorney"
          >
            <FormControlLabel
              sx={{ whiteSpace: "nowrap" }}
              value={true}
              control={<Radio />}
              label="Yes, this can be used for all future substorages"
            />

            <FormControlLabel
              sx={{ whiteSpace: "nowrap" }}
              value={false}
              control={<Radio />}
              label="No"
            />
          </MUIRadioGroup>
        </Box>
      </Box>
      <FormStepperStructure.Footer>
        <Button
          onClick={handleSubmit(submitForm)}
          fullWidth
          sx={{ backgroundColor: "info.main", maxWidth: "192px" }}
          variant="contained"
        >
          Weiter
          {/* Next */}
        </Button>
      </FormStepperStructure.Footer>
    </Box>
  );
}

export default FutureSubstoragesStep;
