import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";
import { QUOTE_DIRECTION } from "../../../core/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { dateString, localeFormat } from "../../../core/utils/convert";

const getDetailedPaymentList = async (storageId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  const result = await axios.get(`${BASE_URL}data/payment-transactions/warehouse/${storageId}/`, config);

  if (result?.data?.status != 200)
    throw Error("Zahlungsverkehr nicht geladen werden"); //("payments not fetched");

  let toShowList = result.data.data.value;

  let list = [];
  toShowList.map((itm) => {
    let mainItem = itm;
    mainItem.name = mainItem.arades_name;
    mainItem.createdon = mainItem.arades_ueberweisungsdatum;
    mainItem.createdon_formatted = dateString(
      mainItem.arades_ueberweisungsdatum
    );
    mainItem.arades_ueberweisungsbetrag_buyer_formatted = localeFormat(
      mainItem.arades_betrag, { minimumFractionDigits: 2, currency: "EUR" }
    );
    mainItem.status = "order created";

    list.push(mainItem);
  });

  return list;
};

const getSaleOrderList = async (storageId, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };
  const result = await axios.get(`${BASE_URL}data/sales-orders/details/warehouse/${storageId}/`, config);

  if (result?.data?.status != 200)
    throw Error("Zahlungsverkehr nicht geladen werden"); //("saleorders not fetched");

  let toShowList = result.data.data.value;

  let list = [];
  toShowList.map((detailItem) => {
    let mainItem = {
      name: detailItem["so.name"],
      arades_richtung: detailItem["so.arades_richtung"],
      totallineitemamount: detailItem["so.totallineitemamount"],
      createdon: detailItem["so.createdon"],
      arades_verkaeufer: detailItem["so.arades_verkaeufer"],
      arades_lagerplatz_kaeufer: detailItem["so.arades_lagerplatz_kaeufer"],
      arades_lagerplatz_verkaeufer:
        detailItem["so.arades_lagerplatz_verkaeufer"],
      arades_ueberweisungsbetrag: detailItem["so.arades_ueberweisungsbetrag"],
      arades_anlagebetrag: detailItem["so.arades_anlagebetrag"],
      totalamount: detailItem["so.totalamount"],
      arades_menge: detailItem["so.arades_menge"],
    };
    let gridItem = fillTransactionItem(mainItem, detailItem, { getState });
    list.push(gridItem);
  });

  return list;
};

const getInvoiceList = async (storageId, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };
  const result = await axios.get(`${BASE_URL}data/invoices/details/warehouse/${storageId}/`, config);

  if (result?.data?.status != 200) throw Error("invoices not fetched"); //("invoices not fetched");

  let toShowList = result.data.data.value;

  let list = [];
  toShowList.map((detailItem) => {
    let mainItem = {
      name: detailItem["iv.name"],
      arades_richtung: detailItem["iv.arades_richtung"],
      invoiceid: detailItem["iv.invoiceid"],
      createdon: detailItem["iv.createdon"],
      arades_menge: detailItem["iv.arades_menge"],
      arades_ueberweisungsbetrag: detailItem["iv.arades_ueberweisungsbetrag"],
      arades_anlagebetrag: detailItem["iv.arades_anlagebetrag"],
      totalamount: detailItem["iv.totalamount"],
      arades_leistungserbringung: detailItem["iv.arades_leistungserbringung"],
    };
    let gridItem = fillTransactionItem(mainItem, detailItem, { getState });
    list.push(gridItem);
  });

  return list;
};

const getQuoteList = async (storageId, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };
  const result = await axios.get(`${BASE_URL}data/quotes/details/warehouse/${storageId}/`, config);

  if (result?.data?.status != 200) throw Error("quotes not fetched"); //("quotes not fetched");

  let toShowList = result.data.data.value;

  let list = [];
  toShowList.map((detailItem) => {
    let mainItem = {
      name: detailItem["qo.name"],
      arades_richtung: detailItem["qo.arades_richtung"],
      quoteid: detailItem["qo.quoteid"],
      createdon: detailItem["qo.createdon"],
      arades_verkaeufer: detailItem["qo.arades_verkaeufer"],
      customerid: detailItem["qo.customerid"],
      arades_anlagebetrag: detailItem["qo.arades_anlagebetrag"],
      arades_lagerplatz_kaeufer: detailItem["qo.arades_lagerplatz_kaeufer"],
      arades_lagerplatz_verkaeufer:
        detailItem["qo.arades_lagerplatz_verkaeufer"],
      statecode: detailItem["qo.statecode"],
      statuscode: detailItem["qo.statuscode"],
      arades_ueberweisungsbetrag: detailItem["qo.arades_ueberweisungsbetrag"],
      totalamount: detailItem["qo.totalamount"],
      arades_menge: detailItem["qo.arades_menge"],
    };
    let gridItem = fillTransactionItem(mainItem, detailItem, { getState });
    // mainItem.status =
    //   mainItem.statecode == 0 && mainItem.statuscode == 1
    //     ? "in process"
    //     : mainItem.statecode == 3 && mainItem.statuscode == 6
    //     ? "ratior rejected"
    //     : mainItem.statecode == 3 && mainItem.statuscode == 5
    //     ? "customer canceled"
    //     : "";
    list.push(gridItem);
  });

  return list;
};

const fillTransactionItem = (mainItem, detailItem, { getState }) => {
  mainItem.createdon_formatted = dateString(mainItem.createdon);
  let ratiorData = getState().globals.ratiorData;
  mainItem.quantity =
    localeFormat(detailItem.quantity, { minimumFractionDigits: 4 }) + (detailItem.quantity ? " g" : "");

  //paricher : what to be set for other directions?!
  if (mainItem.arades_richtung == QUOTE_DIRECTION.SELL) {
    mainItem.arades_verfuegbare_menge_formatted =
      localeFormat(detailItem.arades_verfuegbare_menge, { minimumFractionDigits: 4 }) +
      (detailItem.arades_verfuegbare_menge ? " g" : "");
  }

  mainItem.totalPrice =
    mainItem["totallineitemamount@OData.Community.Display.V1.FormattedValue"];

  mainItem.fromStorageName =
    ratiorData?.ratiorStorages?.filter(
      (x) => x?.id == mainItem.arades_lagerplatz_verkaeufer
    ).length > 0
      ? "System Storage"
      : ratiorData?.ratiorStorages.length > 0
      ? mainItem[
          "_arades_lagerplatz_verkaeufer_value@OData.Community.Display.V1.FormattedValue"
        ]
      : "Not Loaded";

  mainItem.toStorageName =
    ratiorData?.ratiorStorages?.filter(
      (x) => x?.id == mainItem.arades_lagerplatz_kaeufer
    ).length > 0
      ? "System Storage"
      : ratiorData?.ratiorStorages.length > 0
      ? mainItem[
          "_arades_lagerplatz_kaeufer_value@OData.Community.Display.V1.FormattedValue"
        ]
      : "Not Loaded";

  mainItem.requestedBy =
    mainItem[
      "_arades_verkaeufer_value@OData.Community.Display.V1.FormattedValue"
    ];

  if (mainItem.arades_richtung == QUOTE_DIRECTION.BUY) {
    mainItem.arades_gebuehr_betrag_buyer = detailItem.arades_gebuehr_betrag;
    mainItem.arades_gebuehr_betrag_buyer_formatted = localeFormat(
      detailItem.arades_gebuehr_betrag, { minimumFractionDigits: 2, currency: "EUR" }
    );

    mainItem.arades_ueberweisungsbetrag_buyer =
      mainItem.arades_ueberweisungsbetrag;
    mainItem.arades_ueberweisungsbetrag_buyer_formatted = localeFormat(
      mainItem.arades_ueberweisungsbetrag, { minimumFractionDigits: 2, currency: "EUR" }
    );

    mainItem.arades_anlagebetrag_formatted = localeFormat(
      mainItem.arades_anlagebetrag, { minimumFractionDigits: 2, currency: "EUR" }
    );

    mainItem.arades_menge_formatted = "+" + localeFormat(mainItem.arades_menge, { minimumFractionDigits: 4 }) + " g";

    mainItem.arades_verfuegbare_menge_formatted =
      localeFormat(detailItem.arades_verfuegbare_menge_kaeufer, { minimumFractionDigits: 4 }) +
      (detailItem.arades_verfuegbare_menge_kaeufer ? " g" : "");
  } else if (mainItem.arades_richtung == QUOTE_DIRECTION.SELL) {
    mainItem.arades_gebuehr_betrag_seller = detailItem.arades_gebuehr_betrag;
    mainItem.arades_gebuehr_betrag_seller_formatted = localeFormat(
      detailItem.arades_gebuehr_betrag, { minimumFractionDigits: 2, currency: "EUR" }
    );

    mainItem.arades_ueberweisungsbetrag_seller =
      mainItem.arades_ueberweisungsbetrag;
    mainItem.arades_ueberweisungsbetrag_seller_formatted = localeFormat(
      mainItem.arades_ueberweisungsbetrag, { minimumFractionDigits: 2, currency: "EUR" }
    );

    mainItem.totalamount_formatted = localeFormat(mainItem.totalamount, { minimumFractionDigits: 2, currency: "EUR" });
    mainItem.extendedamount_formatted = localeFormat(mainItem.totalamount, { minimumFractionDigits: 2, currency: "EUR" });
    mainItem.arades_menge_formatted = "-" + localeFormat(mainItem.arades_menge, { minimumFractionDigits: 4 }) + " g";
  } else if (
    mainItem.arades_richtung == QUOTE_DIRECTION.TRANSFER ||
    mainItem.arades_richtung == QUOTE_DIRECTION.Gift
  ) {
    mainItem.arades_gebuehr_menge_formatted = detailItem.arades_gebuehr_menge
      ? localeFormat(detailItem.arades_gebuehr_menge, { minimumFractionDigits: 4 }) + " g"
      : "";
  }

  return mainItem;
};

const getTransactionDetailedListAction = createAsyncThunk(
  "storageList/getDetailedList",
  async ({ storageid }, { getState }) => {
    const [
      payments,
      saleorders,
      invoices,
      quotes,
    ] = await Promise.all([ // Fetch all data in parallel
      getDetailedPaymentList(storageid, { getState }),
      getSaleOrderList(storageid, { getState }),
      getInvoiceList(storageid, { getState }),
      getQuoteList(storageid, { getState }),
    ]);
    let result = payments
      .concat(saleorders)
      .concat(invoices)
      .concat(quotes)
      .sort((a, b) => (a?.createdon >= b?.createdon ? -1 : 1));
    return result;
  }
);
export default getTransactionDetailedListAction;
