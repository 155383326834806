import React, { useEffect, useState } from "react";
import { FormStepper } from "../../common";

import SellerBankAccountStep from "./sellerBankAccount.step";
import SummeryStep from "./summery.step";
import TaxDetailStep from "./taxDetail.step";
import SelectSignContractStep from "./selectSignContract.step";
import SignContractStep from "./signContract.step";
import VerificationStep from "./Verification.step";

import { useDispatch } from "react-redux";
import { resetAll } from "../../../redux/slices/exchange/exchange.slice";
import { useNavigate, useParams } from "react-router-dom";
import { URL } from "../../../core/constants";
import { useTranslation } from "react-i18next";

function BecomeSellerWith2FAStepManager() {
  const [currentStep, setCurrentStep] = useState(1);
  const stepsCount = 6;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const HandleCurrentStep = (step) => {
    window.scrollTo(0, 0); // Scroll up to the top because it is confusing on mobile if the scroll position stays
    if (isNaN(parseInt(step))) {
      if (step === "next" && currentStep < stepsCount) {
        setCurrentStep((prev) => prev + 1);
      } else if (step === "prev" && currentStep > 1) {
        setCurrentStep((prev) => prev - 1);
      }
    } else {
      if (currentStep <= stepsCount && currentStep >= 1) {
        setCurrentStep(step);
      }
    }
  };

  const handleCancel = () => {
    dispatch(resetAll());
    navigate(URL.DASHBOARD);
  };

  return (
    <div className="container-card">
      <FormStepper
        currentStep={currentStep}
        setCurrentStep={HandleCurrentStep}
        noFooter={[1, 2, 3, 4, 5, 6]}
        hasCancel={[1, 2, 3, 4, 5, 6]}
        onCancel={handleCancel}
        headerTitles={[
          <div>
            &nbsp;{t("become-seller.bank-account")} <br></br>
            {t("become-seller.for-payment")}
          </div>,
          t("become-seller.company-details"),
          t("become-seller.company-information"),
          t("become-seller.contract-documents"),
          t("become-seller.overview-contracts"),
          t("become-seller.two-factor"),
        ]}
      >
        {/* Step 1*/}
        <SellerBankAccountStep handleCurrentStep={HandleCurrentStep} />

        {/* Step 2*/}
        <TaxDetailStep handleCurrentStep={HandleCurrentStep} />

        {/* Step 3 */}
        <SummeryStep
          handleCurrentStep={HandleCurrentStep}
          currentStep={currentStep}
        />

        {/* Step 4 */}
        <SelectSignContractStep handleCurrentStep={HandleCurrentStep} />

        {/* Step 5 */}
        <SignContractStep
          handleCurrentStep={HandleCurrentStep}
          justSign={false}
        />
        {/* Step 6 */}
        <VerificationStep handleCurrentStep={HandleCurrentStep} />
      </FormStepper>
    </div>
  );
}

export default BecomeSellerWith2FAStepManager;
