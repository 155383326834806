import React, { useState, useEffect } from "react";
import { useApi } from "../../../core/hooks/useApi";
import {
  BASE_URL,
  BYPASS_NECT,
} from "../../../core/apiconfig/environment";
import { URL, CONTACT_STATUS, ERROR_CONTACT_US } from "../../../core/constants";
import back from "../../../assets/images/back.svg";
import Input from "../../common/inputs/input";
import AutoComplete from "../../common/inputs/autocompelete";
import Button from "../../common/buttons/button";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  CONTACT_ID,
  STATUS_CODE,
} from "../../../core/apiconfig/constantApi";
import "./registration.scss";
import { ConfirmModal } from "../../common";
import LoadingOverlay from "../../common/LoadingOverlay/LoadingOverlay";
import { resetCache } from "../../../core/utils/cachManager";
import { useIsScreenSizeDesktop } from "../../../core/utils/responsive";

const AddressVerification = () => {
  const { patch, get } = useApi();
  const navigate = useNavigate();
  const isDesktop = useIsScreenSizeDesktop();
  const { enqueueSnackbar } = useSnackbar();

  const [countries, setCountries] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [touched, setTouched] = useState({});
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState({
    arades_land: "",
    address1_city: "",
    address1_postalcode: "",
    address1_line1: "",
    address1_line2: "",
    address1_line3: "",
    //arades_adresse_verifiziert: true,
  });

  const requiredMsg = "erforderlich";

  const fetchCountries = async () => {
    setLoading(true);
    const result = await get(`${BASE_URL}data/countries/`);
    setLoading(false);
    if (result.data && result.data.value) {
      {
        let list = [];
        result.data.value.map((item) => {
          list.push({ key: item.arades_landid, value: item.arades_name });
        });
        setCountries(list);
      }
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const handleChange = (event) => {
    setValue({ ...value, [event.target.name]: event.target.value });
  };

  const handleSubmit = async () => {
    try {
      await saveAddress();
    } catch (err) {
      enqueueSnackbar(err.message + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
    }
  };

  const saveAddress = async () => {
    let saveObj = { ...value };
    delete saveObj.arades_land;

    saveObj["arades_land1@odata.bind"] = "/arades_lands(" + value.arades_land.key + ")";

    //FILLING NECT FIELDS
    saveObj["arades_land4@odata.bind"] = "/arades_lands(" + value.arades_land.key + ")";
    saveObj["arades_strasse"] = value.address1_line1;
    saveObj["arades_ort"] = value.address1_city;
    saveObj["arades_plz"] = value.address1_postalcode;

    if (BYPASS_NECT) {
      saveObj["statuscode"] = CONTACT_STATUS.NEED_DOCUMENTS;
      localStorage.setItem(STATUS_CODE, CONTACT_STATUS.NEED_DOCUMENTS);
    }

    setLoading(true);
    const result = await patch(`${BASE_URL}data/contacts/${localStorage.getItem(CONTACT_ID)}/`, saveObj);
    setLoading(false);
    if (result.status != 200) {
      enqueueSnackbar(result.statusText + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
    }
    if (result.status == 200) {
      if (BYPASS_NECT) { navigate(URL.DOCUMENTSVERIFICATION); }
      else { setConfirmOpen(true); }
    }
  };

  const saveNectLater = async () => {
    let saveObj = { statuscode: CONTACT_STATUS.NECT_LATER };

    setLoading(true);
    const result = await patch(`${BASE_URL}data/contacts/${localStorage.getItem(CONTACT_ID)}/`, saveObj);
    setLoading(false);
    if (result.status != 200) {
      enqueueSnackbar(result.statusText + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
    }
    if (result.status == 200) {
      localStorage.setItem(STATUS_CODE, CONTACT_STATUS.NECT_LATER);
      navigate(URL.DASHBOARD);
      window.location.reload();
    }
  };

  const formIsValid = () => {
    return (
      value.arades_land &&
      value.address1_city &&
      value.address1_postalcode &&
      value.address1_line1
    );
  };

  return (
    <LoadingOverlay show={loading}>
      <ConfirmModal
        open={confirmOpen}
        title={"Ihre Registrierung ist fast geschafft!"}
        showCloseButton={false}
        message={
          <div>
            Im nächsten Schritt werden Sie zu NECT IDENT weitergeleitet. Es
            dient Ihrer sicheren und gleichzeitig bequemen Identifikation.
            <br></br>
            Halten Sie bitte Ihren gültigen Personalausweis oder Reisepass und
            Ihr Smartphone bereit. Nect führt Sie im Handumdrehen durch die
            Identifikation. Sie können diesen Punkt auch überspringen und zu
            einem späteren Zeitpunkt nachholen.
          </div>
        }
        size={"lg"}
        noImage={true}
        rejectTitle={"Später"}
        onReject={saveNectLater}
        showNect={true}
      ></ConfirmModal>

      <div>
        <div className="register-title">{"Registrierung"}</div>
        <div className="step">Ihre Anschrift</div>
        <div className="register-box-wrapper">
          <div className="flex-column register-box padding-50">
            <div>
              Bitte tragen Sie die Adresse von Ihrem Ausweis ein, da diese bei der Verifizierung überprüft wird
            </div>
            <div
              style={{ justifyContent: "flex-start" }}
              className="padding-20 form-row"
            >
              <div className="col-12 ">
                <AutoComplete
                  error={touched.arades_land && value.arades_land === ""}
                  helperText={
                    touched.arades_land && value.arades_land === ""
                      ? requiredMsg
                      : ""
                  }
                  onBlur={() => setTouched({ ...touched, arades_land: true })}
                  options={countries}
                  name={"arades_land"}
                  value={value.arades_land}
                  onChange={handleChange}
                  label={"Land*"}
                ></AutoComplete>
              </div>
            </div>
            <div
              style={{ justifyContent: "flex-start" }}
              className="padding-20 form-row"
            >
              <div className="col-12 ">
                <Input
                  error={touched.address1_line1 && value.address1_line1 === ""}
                  helperText={
                    touched.address1_line1 && value.address1_line1 === ""
                      ? requiredMsg
                      : ""
                  }
                  onBlur={() =>
                    setTouched({ ...touched, address1_line1: true })
                  }
                  name={"address1_line1"}
                  value={value.address1_line1}
                  onChange={handleChange}
                  label={"Straße und Hausnummer*"}
                />
              </div>
            </div>

            <div className="padding-20 form-row">
              <div
                className="col-md-6 col-12 form-item-address"
              >
                <Input
                  error={
                    touched.address1_postalcode &&
                    value.address1_postalcode === ""
                  }
                  helperText={
                    touched.address1_postalcode &&
                    value.address1_postalcode === ""
                      ? requiredMsg
                      : ""
                  }
                  onBlur={() =>
                    setTouched({ ...touched, address1_postalcode: true })
                  }
                  name={"address1_postalcode"}
                  value={value.address1_postalcode}
                  onChange={handleChange}
                  label={"PLZ*"}
                />
              </div>

              <div
                className="col-md-6 col-12 form-item-address"
                style={{ marginRight: 0 }}
              >
                <Input
                  error={touched.address1_city && value.address1_city === ""}
                  helperText={
                    touched.address1_city && value.address1_city === ""
                      ? requiredMsg
                      : ""
                  }
                  onBlur={() => setTouched({ ...touched, address1_city: true })}
                  name={"address1_city"}
                  value={value.address1_city}
                  onChange={handleChange}
                  label={"Ort*"}
                />
              </div>
            </div>
            <div className="form-row padding-20 ">
              <div className="col-12 ">
                <Input
                  name={"address1_line2"}
                  value={value.address1_line2}
                  onChange={handleChange}
                  label={"Adresszusatz"}
                />
              </div>
            </div>
            <div
              className="padding-50 form-row div-btn-register "
              style={{ paddingTop: 90, paddingBottom: 50 }}
            >
              <div
                className="link-btn"
                onClick={() => {
                  resetCache();
                  navigate(URL.LOGIN);
                }}
              >
                <span>
                  <img src={back} className="imgback"></img>{" "}
                  {"Zurück zur Anmeldung"}
                </span>
              </div>
              <Button
                disabled={!formIsValid()}
                className="btn-action"
                onClick={handleSubmit}
              >
                {BYPASS_NECT ? "Weiter" : "Anmeldung"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default AddressVerification;
