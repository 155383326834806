import React, { useState, useEffect } from "react";
import MetalCell from "../../common/grids/metalCell";
import { useApi } from "../../../core/hooks/useApi";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { Box, Grid } from "@mui/material";
import { dateString, localeFormat, timeString } from "../../../core/utils/convert";
import { MetalPart } from "../../../core/utils/business";
import { storageIsPending } from "../../../core/utils/business";
import { LoadingOverlay } from "../../common";
import { useTranslation } from "react-i18next";

import "./storageInfo.scss";

const StorageInfo = ({ storageId, setMetal, setPending, setStorageDetail }) => {
  const { get } = useApi();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [storage, setStorage] = useState({
    msdyn_warehouseid: null,
    _arades_edelmetall_typ_value: "",
    msdyn_name: "",
    arades_menge: "",
    price: "",
  });
  const [bankAccount, setBankAccount] = useState({
    arades_kontoid: null,
    arades_kontoinhaber: "",
    arades_bank: "",
    arades_bic: "",
    arades_iban: "",
  });

  useEffect(() => {
    loadData(storageId);
  }, []);

  useEffect(() => {
    loadData(storageId);
  }, [storageId]);

  const loadData = async (storageid) => {
    setLoading(true);
    try {
      let fetchQuery = `${BASE_URL}data/warehouses/${storageid}/`;
      const result = await get(fetchQuery);

      if (result && result.data) {
        let item = result.data;
        let isPending = storageIsPending(item);
        let fetchedMenge = await fetchMenge(item.msdyn_warehouseid);
        let priceInfo = await fetchPrice(item._arades_edelmetall_typ_value);
        if (item) {
          let storageItem = {
            msdyn_warehouseid: item.msdyn_warehouseid,
            msdyn_name: item.msdyn_name,
            storageDescription: item.msdyn_description,
            arades_lagerplatznummer: item.arades_lagerplatznummer,
            msdyn_description: item.msdyn_description,

            createdon: item.createdon,
            _arades_edelmetall_typ_value:
              item[
                "_arades_edelmetall_typ_value@OData.Community.Display.V1.FormattedValue"
              ],
            arades_verfuegbare_menge: !isPending
              ? localeFormat(fetchedMenge?.arades_verfuegbare_menge, { minimumFractionDigits: 4 }) + " g"
              : "Pending",
            arades_menge: !isPending
              ? localeFormat(fetchedMenge?.arades_menge, { minimumFractionDigits: 4 }) + " g"
              : "Pending",
            price:
              (priceInfo?.price ?? 0) * fetchedMenge?.arades_verfuegbare_menge,
            pricePerUnit: priceInfo?.price ?? 0,
            priceDate: dateString(priceInfo?.createdon ?? ""),
            priceTime: timeString(priceInfo?.createdon ?? ""),
          };
          setStorage(storageItem);
          setMetal(MetalPart(storageItem.msdyn_name));
          setPending(isPending);
          setStorageDetail(item);

          if (item.arades_referenzkonto) {
            let bankAccountItem = {
              arades_kontoid: item.arades_referenzkonto.arades_kontoid,
              arades_bank: item.arades_referenzkonto.arades_bank,
              arades_kontoinhaber:
                item.arades_referenzkonto.arades_kontoinhaber,
              arades_bic: item.arades_referenzkonto.arades_bic,
              arades_iban: item.arades_referenzkonto.arades_iban,
            };
            setBankAccount(bankAccountItem);
          }
        }
      }
    } catch (error) {
      console.log(`error`, error);
    }
    setLoading(false);
  };

  const fetchMenge = async (storageId) => {
    if (!storageId) return { arades_verfuegbare_menge: 0, arades_menge: 0 };
    const result = await get(`${BASE_URL}data/product-inventories/warehouse/${storageId}/`);
    if (result.data && result.data.value) {
      return result.data.value[0];
    } else return { arades_verfuegbare_menge: 0, arades_menge: 0 };
  };

  const fetchPrice = async (productid) => {
    const result = await get(`${BASE_URL}data/product-prices/${productid}/`);
    if (result.data && result.data.value && result.data.value[0].arades_preis) {
      return {
        price: result.data.value[0].arades_preis,
        createdon: result.data.value[0].createdon,
      };
    } else return 0;
  };

  return (
    <LoadingOverlay show={loading}>
      <Box sx={{ display: "flex", marginRight: 10 }}>
        <MetalCell
          hideName={true}
          metal={storage._arades_edelmetall_typ_value}
          width={50}
        ></MetalCell>
        <Grid container spacing={2} ml={10}>
          <Grid item sm={7}>
            <div className="d-flex flex-column">
              <span className="info-header">
                {storage._arades_edelmetall_typ_value}
              </span>
              <span className="storage-cell-value">{storage.msdyn_name}</span>
              <span className="storage-cell-value">{storage.storageDescription}</span>
            </div>
          </Grid>
          <Grid item sm={5}>
            <div className="d-flex flex-column">
              <span className="info-header">{t("storage-detail.amount")} </span>
              <span className="storage-cell-value">{storage.arades_menge}</span>
            </div>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2} ml={10}>
        <Grid item sm={6} mt={10}>
          <div className="d-flex flex-column">
            <span className="cell-header">
              {t("storage-detail.price-unit")}
            </span>
            <Grid container spacing={2} mt={3}>
              <Grid item sm={6} className="storage-cell-value">
                {t("storage-detail.per-gram") + ":"}
              </Grid>
              <Grid
                item
                sm={6}
                className="storage-cell-value"
                sx={{ textAlign: "right" }}
              >
                {localeFormat((isNaN(storage.pricePerUnit) ? 0 : storage.pricePerUnit), { minimumFractionDigits: 2, currency: "EUR" })}
              </Grid>
              <Grid item sm={6} className="storage-cell-value">
                {t("storage-detail.per-ounce") + ":"}
              </Grid>
              <Grid
                item
                sm={6}
                className="storage-cell-value"
                sx={{ textAlign: "right" }}
              >
                {localeFormat((isNaN(storage.pricePerUnit) ? 0 : storage.pricePerUnit) * 31.1, { minimumFractionDigits: 2, currency: "EUR" })}
              </Grid>
              <Grid item sm={6} className="storage-cell-value">
                {t("storage-detail.per-kilo") + ":"}
              </Grid>
              <Grid
                item
                sm={6}
                className="storage-cell-value"
                sx={{ textAlign: "right" }}
              >
                {localeFormat((isNaN(storage.pricePerUnit) ? 0 : storage.pricePerUnit) * 1000, { minimumFractionDigits: 2, currency: "EUR" })}
              </Grid>
              <Grid item sm={12} className="storage-cell-value" mt={2}>
                {t("storage-detail.as-of") + ": "}
                <span style={{ color: "red " }}>{storage.priceDate} </span>
                {" " + t("storage-detail.th") + " "}
                <span style={{ color: "red" }}>{storage.priceTime} </span>
                {" " + t("storage-detail.hour")}
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item sm={1}></Grid>
        <Grid item sm={5} mt={10}>
          <div className="d-flex flex-column">
            <span className="cell-header">
              {t("storage-detail.total-price")}
            </span>
            <Grid container spacing={2} mt={3}>
              <Grid item sm={12} className="storage-cell-value">
                {localeFormat(storage.price, { minimumFractionDigits: 2, currency: "EUR" })}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </LoadingOverlay>
  );
};

export default StorageInfo;
