import React, { useState, useEffect } from "react";
import { Grid, Box, Button } from "@mui/material";
import Input from "../../common/inputs/input";
import { FormStepperStructure } from "../../common";
import {
  taxDetails,
  setTaxDetails,
} from "../../../redux/slices/becomeSeller/becomeSeller.slice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const TaxDetailStep = ({ handleCurrentStep }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const requiredMsg = t("validation.required");
  const taxDetailData = useSelector(taxDetails);

  const onSubmit = (data) => {
    dispatch(setTaxDetails(data));
    handleCurrentStep("next");
  };

  const schema = yup.object().shape({
    arades_steuernummer: yup.string().required(requiredMsg),
    arades_finanzamt_behoerde: yup.string().required(requiredMsg),
  });

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      ...taxDetailData,
    },
    resolver: yupResolver(schema),
  });

  return (
    <Box>
      <Grid spacing={8} sx={{ marginTop: 1 }} container direction="row">
        <Grid xs={12} lg={6} item>
          <Input
            name={"arades_steuernummer"}
            label={t("become-seller.tax-number") + "*"}
            error={Boolean(errors.arades_steuernummer)}
            helperText={errors.arades_steuernummer?.message}
            control={control}
          />
        </Grid>
        <Grid xs={12} lg={6} item>
          <Input
            name={"arades_umsatzsteuerid"}
            label={t("become-seller.vat-id")}
            control={control}
          />
        </Grid>
        <Grid xs={12} lg={6} item>
          <Input
            name={"arades_registernummer"}
            label={t("become-seller.registeration-number")}
            control={control}
          />
        </Grid>
        <Grid xs={12} lg={6} item>
          <Input
            name={"arades_amtsgericht"}
            label={t("become-seller.district-court")}
            control={control}
          />
        </Grid>
        <Grid xs={12} lg={6} item>
          <Input
            name={"arades_finanzamt_behoerde"}
            label={t("become-seller.tax-office") + "*"}
            error={Boolean(errors.arades_finanzamt_behoerde)}
            helperText={errors.arades_finanzamt_behoerde?.message}
            control={control}
          />
        </Grid>
      </Grid>
      <FormStepperStructure.Footer>
        <Button
          onClick={handleSubmit(onSubmit)}
          fullWidth
          sx={{ bgcolor: "info.main", maxWidth: "192px" }}
          variant="contained"
        >
          {t("buttons.next")}
        </Button>
      </FormStepperStructure.Footer>
    </Box>
  );
};

export default TaxDetailStep;
