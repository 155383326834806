import React, { useState } from "react";
import { FormStepper } from "../../common";

import ChangeEmailStep from "./changeEmail.step";
import VerificationStep from "./verification.step";
import { useTranslation } from "react-i18next";

function ChangeEmailStepManager({ handleCurrentStep: handlerParentStep }) {
  const {t} = useTranslation();
  const [currentStep, setCurrentStep] = useState(1);
  const stepsCount = 2;

  const HandleCurrentStep = (step, parent) => {
    window.scrollTo(0, 0); // Scroll up to the top because it is confusing on mobile if the scroll position stays
    if (parent) {
      handlerParentStep(parent);
    } else {
      if (isNaN(parseInt(step))) {
        if (step === "next" && currentStep < stepsCount) {
          setCurrentStep((prev) => prev + 1);
        } else if (step === "prev" && currentStep > 1) {
          setCurrentStep((prev) => prev - 1);
        }
      } else {
        if (currentStep <= stepsCount && currentStep >= 1) {
          setCurrentStep(step);
        }
      }
    }
  };

  return (
    <div className="container-card">
      <FormStepper
        currentStep={currentStep}
        setCurrentStep={HandleCurrentStep}
        noFooter={[1, 2]}
        noBackButton={[2]}
        headerTitles={[t("components.verification.title"), t("components.change-email.title")]}
      >
        {/* Step 1- Verification */}
        <VerificationStep handleCurrentStep={HandleCurrentStep} />

        {/* Step 2- Change Email */}
        <ChangeEmailStep handleCurrentStep={HandleCurrentStep} />
      </FormStepper>
    </div>
  );
}

export default ChangeEmailStepManager;
