import React, { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { FormStepperStructure, Input, LoadingOverlay } from "../common";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  GermanFloatToFloat,
  localeFormat,
} from "../../core/utils/convert";
import { useTranslation } from "react-i18next";
import {
  setEnterAmount,
  enterAmountFields,
  exchangeFromStorage,
  globalData,
  resetActionStatus,
} from "../../redux/slices/exchange/exchange.slice";
import { getStorageAmountAction } from "../../redux/slices/exchange";

function EnterAmount({ handleCurrentStep }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const amountFields = useSelector(enterAmountFields);
  const fromStorage = useSelector(exchangeFromStorage);
  const exchangeGlobalData = useSelector(globalData);
  const [loading, setLoading] = useState(false);

  const requiredMsg = t("validation.required");

  const submitForm = (data) => {
    let enteredAmount = GermanFloatToFloat(data.amount);
    if (enteredAmount > fromStorage.arades_verfuegbare_menge) {
      enqueueSnackbar(t("validation.above-max"), {
        variant: "error",
        autoHideDuration: 20000,
      });
      return;
    }
    dispatch(
      setEnterAmount({
        amount: data.amount,
        amountPerGram: enteredAmount,
      })
    );
    handleCurrentStep("next");
  };

  useEffect(() => {
    if (exchangeGlobalData.actionStatus) {
      setValue(
        "amount",
        localeFormat(amountFields.maxAmountPerGram, { minimumFractionDigits: 4 })
      );
      dispatch(resetActionStatus());
    }
  }, [exchangeGlobalData.actionStatus]);

  useEffect(() => {
    setLoading(exchangeGlobalData.loading);
  }, [exchangeGlobalData.loading]);

  const schema = yup.object().shape({
    amount: yup
      .string()
      .test(
        "is-decimal",
        t("validation.none-zero-decimal"),
        (value) =>
          (value + "").match(/^-?\d{1,3}(?:\.\d{3})*(?:,\d+)?$/) ||
          (value + "").match(/^(?:[1-9]\d*|0(?!(?:\.0+)?$))?(?:\.\d+)?$/)
      )
      .notOneOf([0, "0", "0,00000"], t("validation.none-zero-decimal"))
      .required(requiredMsg)
      .nullable(),
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      ...amountFields,
    },
    resolver: yupResolver(schema),
  });
  const watchAmount = watch("amount");
  return (
    <LoadingOverlay show={loading}>
      <FormStepperStructure.Container minHeight="calc(100vh - 300px)">
        <Box
          sx={{
            width: "100%",
            maxWidth: "600px",
            margin: "80px auto",
          }}
        >
          <Grid spacing={1} container direction="row">
            <Grid xs={12} lg={11} item>
              <Input
                control={control}
                error={Boolean(errors.amount)}
                helperText={
                  errors.amount?.message ||
                  `${t("exchange.max-amount-message")} ${localeFormat(fromStorage.arades_verfuegbare_menge, { minimumFractionDigits: 4 })} (g)`
                }
                name="amount"
                label={`${t("exchange.enter-amount")} (g)`}
              />
            </Grid>
            <Grid xs={12} lg={1} item>
              <Button
                onClick={() =>
                  dispatch(
                    getStorageAmountAction(fromStorage.msdyn_warehouseid)
                  )
                }
                sx={{
                  maxWidth: "192px",
                  height: 54,
                  marginLeft: 5,
                }}
                variant="outlined"
              >
                {t("exchange.max")}
              </Button>
            </Grid>
          </Grid>
        </Box>

        <FormStepperStructure.Footer>
          <Button
            onClick={handleSubmit(submitForm)}
            fullWidth
            sx={{ backgroundColor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            {t("buttons.next")}
          </Button>
        </FormStepperStructure.Footer>
      </FormStepperStructure.Container>
    </LoadingOverlay>
  );
}

export default EnterAmount;
