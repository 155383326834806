import React, { useState, useEffect } from "react";
// import { useApi } from "../../../core/hooks/useApi";
// import { BASE_URL } from "../../../core/apiconfig/environment";
// import { URL, WORKSPACETYPE } from "../../../core/constants";
// import { useSnackbar } from "notistack";
import { FormStepperStructure } from "../../common";
import { Box, Button } from "@mui/material";
import {
  //WORKSPACE_ID,
  WORKSPACE_NAME,
  //WORKSPACE_TYPE,
} from "../../../core/apiconfig/constantApi";
import "./sellerBerief.scss";
//import { LoadingOverlay } from "../../common";
import { useTranslation } from "react-i18next";
import {
  bankAccountInfo,
  taxDetails,
} from "../../../redux/slices/becomeSeller/becomeSeller.slice";
import { useSelector } from "react-redux";

const SummeryStep = ({ handleCurrentStep, currentStep }) => {
  const { t } = useTranslation();
  const bankAccountData = useSelector(bankAccountInfo);
  const taxDetailData = useSelector(taxDetails);

  return (
    <Box>
      <Box>
        <span className="span-check">
          {t("become-seller.summary-description")}
        </span>
        <div className="d-flex flex-column div-brief">
          <div className="d-flex">
            <div className="d-flex flex-column col-3">
              <span className="cell-header">
                {t("become-seller.company-name")}
              </span>
              <span className="cell-value">
                {localStorage.getItem(WORKSPACE_NAME)}
              </span>
            </div>
            <div className="d-flex flex-column col-2">
              <span className="cell-header">
                {t("become-seller.tax-number")}{" "}
              </span>
              <span className="cell-value">
                {taxDetailData.arades_steuernummer}
              </span>
            </div>
            <div className="d-flex flex-column col-2">
              <span className="cell-header">{t("become-seller.vat-id")} </span>
              <span className="cell-value">
                {taxDetailData.arades_umsatzsteuerid}
              </span>
            </div>
            <div className="d-flex flex-column col-2">
              <span className="cell-header">
                {t("become-seller.registeration-number")}{" "}
              </span>
              <span className="cell-value">
                {taxDetailData.arades_registernummer}
              </span>
            </div>
          </div>
          <div className="d-flex">
            <div className="d-flex flex-column col-3">
              <span className="cell-header">
                {t("become-seller.district-court")}{" "}
              </span>
              <span className="cell-value">
                {taxDetailData.arades_amtsgericht}
              </span>
            </div>
            <div className="d-flex flex-column col-3">
              <span className="cell-header">
                {t("become-seller.tax-office")}{" "}
              </span>
              <span className="cell-value">
                {taxDetailData.arades_finanzamt_behoerde}
              </span>
            </div>
          </div>
          <div className="d-flex">
            <div className="d-flex flex-column col-3">
              <span className="cell-header">{t("bank-account.owner")}</span>
              <span className="cell-value">
                {bankAccountData.arades_kontoinhaber}
              </span>
            </div>
            <div className="d-flex flex-column col-2">
              <span className="cell-header">{t("bank-account.bank")}</span>
              <span className="cell-value">{bankAccountData.arades_bank}</span>
            </div>
            <div className="d-flex flex-column col-2">
              <span className="cell-header">BIC</span>
              <span className="cell-value">{bankAccountData.arades_bic}</span>
            </div>
            <div className="d-flex flex-column col-5">
              <span className="cell-header">IBAN</span>
              <span className="cell-value">{bankAccountData.arades_iban}</span>
            </div>
          </div>
          <div className="d-flex">
            <div className="d-flex flex-column col-3">
              <span className="cell-header">
                {t("bank-account.account-number")}
              </span>
              <span className="cell-value">
                {bankAccountData.arades_kontonummer}
              </span>
            </div>
            <div className="d-flex flex-column col-2">
              <span className="cell-header">{t("bank-account.bank-code")}</span>
              <span className="cell-value">
                {bankAccountData.arades_bankleitzahl}
              </span>
            </div>
            <div className="d-flex flex-column col-2">
              <span className="cell-header">{t("bank-account.currency")}</span>
              <span className="cell-value">
                {
                  bankAccountData[
                    "arades_waehrung@OData.Community.Display.V1.FormattedValue"
                  ]
                }
              </span>
            </div>
          </div>
        </div>
      </Box>
      <FormStepperStructure.Footer>
        <Button
          onClick={() => handleCurrentStep(currentStep - 2)}
          fullWidth
          sx={{
            borderColor: "info.main",
            color: "info.main",
            marginRight: 10,
            maxWidth: "200px",
          }}
          variant="outlined"
        >
          {t("buttons.edit")}
        </Button>
        <Button
          onClick={() => handleCurrentStep("next")}
          fullWidth
          sx={{ bgcolor: "info.main", maxWidth: "260px" }}
          variant="contained"
        >
          {t("become-seller.data-correct")}
        </Button>
      </FormStepperStructure.Footer>
    </Box>
  );
};

export default SummeryStep;
