import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Tooltip,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
  HideAreaHandler,
  ConfirmModal,
  Steper,
  LoadingOverlay,
} from "../common";
import MdEmail from "@mui/icons-material/Email";
import MdLocalPhone from "@mui/icons-material/LocalPhone";
import MdFace from "@mui/icons-material/Face";
import MdModeEdit from "@mui/icons-material/ModeEdit";
import MdModeTag from "@mui/icons-material/LocalOffer";
import { Search } from "../common/inputs";
import { useNavigate } from "react-router-dom";
import { URL, ERROR_CONTACT_US, CONTACT_STATUS } from "../../core/constants";
import AddCustomerModal from "./addCustomerModal";
import AssignActionCodeModal from "./assignActionCodeModal";
import { useTranslation } from "react-i18next";
import { getCustomerDetailedListAction } from "../../redux/slices/customerList";
import {
  customerDetailedList,
  globalData,
  resetError,
  resetActionStatus,
  resetAll,
} from "../../redux/slices/customerList/customerList.slice";
import { useDispatch, useSelector } from "react-redux";
import {
  WORKSPACE_ID,
} from "../../core/apiconfig/constantApi";
import { useSnackbar } from "notistack";
import switchToContact from "./switchToContact";
import { dateString } from "../../core/utils/convert";
import { faChild } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LogInAsLegalGuardianModal from "./logInAsLegalGuardianModal";
import { useIsScreenSizeDesktop } from "../../core/utils/responsive";

function CustomerList() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isDesktop = useIsScreenSizeDesktop();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const customerDetailedListData = useSelector(customerDetailedList);
  const customerListGlobalData = useSelector(globalData);

  const [showData, setShowData] = useState(false);
  const [listData, setListData] = useState();
  const [tableData, setTableData] = useState();
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [loginAsLegalGuardianModal, setLoginAsLegalGuardianModal] = useState(null);
  const [selectedContactOrAccount, setSelectedContactOrAccount] = useState();

  useEffect(() => {
    dispatch(resetAll());
    dispatch(
      getCustomerDetailedListAction({
        vermittlerId: localStorage.getItem(WORKSPACE_ID),
      })
    );
  }, []);

  useEffect(() => {
    if (
      customerListGlobalData.actionStatus &&
      customerListGlobalData.actionType === "getDetailedList"
    ) {
      setListData(customerDetailedListData);
      setTableData(customerDetailedListData);
      dispatch(resetActionStatus());
    }
  }, [customerListGlobalData.actionType]);

  useEffect(() => {
    setLoading(customerListGlobalData.loading);
  }, [customerListGlobalData.loading]);

  useEffect(() => {
    if (
      customerListGlobalData.error != null &&
      customerListGlobalData.actionType === "getDetailedList"
    ) {
      enqueueSnackbar(customerListGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
      setLoading(false);
    }
  }, [customerListGlobalData.error]);

  const handleSearch = (term) => {
    if (term) {
      setTableData(
        listData?.filter((item) => {
          return item.name.toLowerCase().includes(term.toLowerCase());
        })
      );
    } else {
      setTableData(listData);
    }
  };

  const editAndActionCodeCell = useCallback((row) => <TableCell>
    <IconButton
      aria-label="Tag"
      component="span"
      onClick={() => {
        setSelectedContactOrAccount(row);
        setAssignModalOpen(true);
      }}
    >
      <MdModeTag />
    </IconButton>
    <IconButton
      aria-label="Edit"
      component="span"
      onClick={() =>
        navigate(URL.CUSTOMERPROFILEBASE + "/" + row.id)
      }
    >
      <MdModeEdit />
    </IconButton>
  </TableCell>);

  return (
    <LoadingOverlay show={loading}>
      <div className="container-card">
        <ConfirmModal
          open={successModalOpen}
          title={t("customer-list.link-sent")}
          showCloseButton={false}
          message={<div>{t("customer-list.email-sent")}</div>}
          okTitle={"Ok"}
          onOk={() => {
            setSuccessModalOpen(false);
          }}
        ></ConfirmModal>
        <AddCustomerModal
          open={customerModalOpen}
          onSuccess={() => setSuccessModalOpen(true)}
          onClose={() => {
            setCustomerModalOpen(false);
            dispatch(
              getCustomerDetailedListAction({
                vermittlerId: localStorage.getItem(WORKSPACE_ID),
              })
            );
          }}
        ></AddCustomerModal>
        <AssignActionCodeModal
          open={assignModalOpen}
          contactId={selectedContactOrAccount?.type === "contact" ? selectedContactOrAccount?.id : undefined}
          accountId={selectedContactOrAccount?.type === "account" ? selectedContactOrAccount?.id : undefined}
          customerName={selectedContactOrAccount?.name}
          onClose={() => {
            setAssignModalOpen(false);
          }}
        ></AssignActionCodeModal>
        <LogInAsLegalGuardianModal
          open={!!loginAsLegalGuardianModal}
          underageContact={loginAsLegalGuardianModal}
          onClose={() => setLoginAsLegalGuardianModal(null)}
        ></LogInAsLegalGuardianModal>
        <Box
          mb={10}
          sx={{
            display: "flex",
            height: "64px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontWeight: 500 }} variant="h5" component="h5" mr={ 8 }>
            {t("customer-list.title")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              height: "64px",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            {isDesktop && <Box sx={{ flex: 3 }}>
              <Search
                onSearch={handleSearch}
                placeholder={t("customer-list.search-title")}
              />
            </Box>}
            <Button
              sx={{ fontWeight: 400, bgcolor: "info.main", marginLeft: 10, flex: 2 }}
              variant="contained"
              fullWidth
              onClick={() => setCustomerModalOpen(true)}
            >
              {t("customer-list.add")}
            </Button>
          </Box>
        </Box>
        {!isDesktop && <Box mb={2}>
          <Search
            onSearch={handleSearch}
            placeholder={t("customer-list.search-title")}
          />
        </Box>}
        <HideAreaHandler
          showArea={showData}
          handleShowArea={() => setShowData(true)}
          message={t("customer-list.not-shown")}
          btnText={t("customer-list.not-shown-btn")}
        >
          <TableContainer sx={{ maxHeight: 600 }}>
            <Table sx={{ minWidth: 930 }} aria-label="simple table" stickyHeader>
              <TableHead sx={{ backgroundColor: "rgba(33, 33, 33, 0.08)" }}>
                <TableRow>
                  {!isDesktop ? <TableCell sx={{ width: 0 /* Column width as small as possible */ }}></TableCell> : <></> /* Edit and action code buttons (on the left on mobile) */}
                  <TableCell>{t("personal-info.name")}</TableCell>
                  <TableCell>{t("personal-info.ratior-key")}</TableCell>
                  <TableCell>{t("customer-list.status")}</TableCell>
                  {/* <TableCell>Lagerplatz</TableCell> */}
                  <TableCell>{t("customer-list.create-date")}</TableCell>
                  <TableCell>{t("customer-list.verification")}</TableCell>
                  {isDesktop ? <TableCell></TableCell> : <></> /* Edit and action code buttons (on the right on desktop) */}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData?.map((row, index) => (
                  <TableRow
                    key={"r" + index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {!isDesktop ? editAndActionCodeCell(row) : <></>}
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: isDesktop ? "row" : "column",
                        }}
                      >
                        <Box>
                          <Typography>
                            {row.isUnderage ? <Box component="span" mr={2}><FontAwesomeIcon icon={faChild} /></Box> : ""}
                            {row.name}
                          </Typography>
                          {row.isUnderage && <Typography variant="footnote">{t("customer-list.guardians")} {row.guardians.map(contact => contact.fullname).join(", ")}</Typography>}
                        </Box>
                        {(row.type == "contact" && row.verifiedStatus == "3") ? (
                          <Button
                            aria-label="login"
                            component="span"
                            onClick={() => switchToContact(row.id, row.name, row.vermittlerId, navigate)}
                          >
                            {t("customer-list.log-in-as-customer")}
                          </Button>
                        ) : (row.isUnderage ? (
                          <Button
                            aria-label="login"
                            component="span"
                            onClick={() => setLoginAsLegalGuardianModal(row)}
                          >
                            {t("customer-list.log-in-as-underage")}
                          </Button>
                        ) : undefined)}
                      </Box>
                    </TableCell>
                    <TableCell>{row.ratiorKey || "-"}</TableCell>
                    <TableCell>
                      <span
                        style={{
                          color:
                            (row.statuscode?.toString() === CONTACT_STATUS.INVITED || row.statuscode?.toString() === CONTACT_STATUS.UNDER_REVIEW)
                            ? "rgba(0, 0, 0, 0.38)" :
                            (row.statuscode?.toString() === CONTACT_STATUS.LOCKED_DEV || row.statuscode?.toString() === CONTACT_STATUS.LOCKED_PROD)
                              ? "rgba(200, 0, 0, 0.87)"
                              : "rgba(0, 0, 0, 0.87)",
                        }}
                      >
                        {
                          (row.statuscode?.toString() === CONTACT_STATUS.LOCKED_DEV || row.statuscode?.toString() === CONTACT_STATUS.LOCKED_PROD)
                          ? <Tooltip title={ <span style={{ fontSize: "1.4em" }}>{ t("customer-list.tooltip.locked") }</span> }>{ t(row.status) }</Tooltip>
                          : t(row.status)
                        }
                      </span>
                    </TableCell>
                    <TableCell>{dateString(row.createDate)} </TableCell>
                    <TableCell>
                      {row.isUnderage ? 
                        "-" :
                        <Steper
                          width="94px"
                          height="26px"
                          currentStep={row.verifiedStatus}
                          data={[
                            { id: "email", icon: <MdEmail />, color: "" },
                            { id: "mobile", icon: <MdLocalPhone />, color: "" },
                            { id: "nect", icon: <MdFace />, color: "" },
                          ]}
                        />
                      }
                    </TableCell>
                    {isDesktop ? editAndActionCodeCell(row) : <></>}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </HideAreaHandler>
      </div>
    </LoadingOverlay>
  );
}

export default CustomerList;
