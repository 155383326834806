import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  FormStepperStructure,
  LoadingOverlay,
  ConfirmModal,
  DownloadGroup,
  MUICheckBox,
} from "../../../common";
import { Box, Button, Typography, Grid } from "@mui/material";
import {
  setSignContract,
  resetActionStatus,
  resetError,
  storageFullData,
  StorageTypeStep,
} from "../../../../redux/slices/storage/storage.slice";
import {
  signContractWorkflowAction,
  verificationSendSMSCodeAction,
} from "../../../../redux/slices/storage";
import {
  getStorageNumbersAction,
} from "../../../../redux/slices/storage";
import { useSelector, useDispatch } from "react-redux";
import blueCircle from "../../../../assets/images/blueCircle.svg";
import { useNavigate } from "react-router-dom";
import {
  URL,
  ERROR_CONTACT_US,
  STORAGE_TYPE,
} from "../../../../core/constants";
import { useParams } from "react-router";
import {
  WORKSPACE_ID,
  MOBILE_NUMBER,
} from "../../../../core/apiconfig/constantApi";
import { useTranslation } from "react-i18next";
import { BYPASS_ADOBE_SIGN } from "../../../../core/apiconfig/environment";

function SignStorageContract({ handleCurrentStep = null, justSign = false }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const storageType = useParams()?.type;
  const storageTypeData = useSelector(StorageTypeStep);
  const [isJunior, setIsJunior] = useState(false);

  useEffect(() => {
    setIsJunior(storageTypeData.isForUnderAge || storageType == STORAGE_TYPE.UNDER_AGE);
  }, []);

  const storageAllData = useSelector(storageFullData);
  const [loading, setLoading] = useState(false);
  const [agreement, setAgreement] = useState(false);

  const [openConfirm, setOpenConfirm] = useState(false);
  const [openSuccessJustSignModal, setOpenSuccessJustSignModal] = useState(false);

  const wait14Days = useParams()?.wait14Days;

  useEffect(() => {
    if (
      storageAllData?.globalData?.actionStatus &&
      !storageAllData.error &&
      storageAllData.globalData.actionType == "getStorageNumbers"
    ) {
      dispatch(resetActionStatus);
      handleCurrentStep("next");
    } else if (
      storageAllData?.globalData?.actionStatus &&
      !storageAllData.error &&
      storageAllData.globalData.actionType == "signContractWorkflow"
    ) {
      dispatch(resetActionStatus);
      setOpenSuccessJustSignModal(true);
      //handleCurrentStep("next");
      //navigate(URL.STORAGE);
    }
  }, [storageAllData.globalData.actionStatus]);

  const onSubmit = (skipSign) => {
    if (skipSign) {
      dispatch(setSignContract({ signDone: false }));
      if (justSign) navigate(URL.STORAGE);
      else dispatch(getStorageNumbersAction());
    } else setOpenConfirm(true);
  };

  useEffect(() => {
    setLoading(storageAllData.loading);
  }, [storageAllData.loading]);

  useEffect(() => {
    if (storageAllData.error) {
      enqueueSnackbar(storageAllData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [storageAllData.error]);

  return (
    <Box
      sx={{
        marginTop: "32px",
      }}
    >
      <LoadingOverlay show={loading}>
        <ConfirmModal
          open={openConfirm}
          size="md"
          title="Verträge unterzeichnen"
          imageSrc={blueCircle}
          showCloseButton={true}
          onClose={() => setOpenConfirm(false)}
          message={
            <div>
              Sie erhalten im Anschluss eine E-Mail mit Instruktionen zur
              Unterzeichnung Ihrer Verträge mit der Ratior AG, der Ratior GmbH
              und der Ratior Gold GmbH.<br></br>
              Verwenden Sie den darin enthaltenen Link zu ADOBE SIGN, um den
              Unterzeichnungsvorgang zu starten.
            </div>
          }
          acceptTitle="E-MAIL SENDEN"
          onAccept={() => {
            setOpenConfirm(false);
            dispatch(setSignContract({ signDone: true }));
            if (justSign)
              dispatch(
                signContractWorkflowAction({
                  storageType: storageType,
                  ownerId: localStorage.getItem(WORKSPACE_ID),
                  wait14Days: wait14Days,
                })
              );
            else dispatch(getStorageNumbersAction());
          }}
          rejectTitle="SPÄTER UNTERSCHREIBEN"
          onReject={() => {
            setOpenConfirm(false);
            dispatch(setSignContract({ signDone: false }));
            if (justSign) navigate(URL.STORAGE);
            else dispatch(getStorageNumbersAction());
          }}
        ></ConfirmModal>
        <ConfirmModal
          open={openSuccessJustSignModal}
          showCloseButton={false}
          title={"Ihre Vertragsunterlagen sind auf dem Weg"} //this legal guardian does nothave access
          message={
            <div>
              In Kurze erhalten Sie eine E-Mail mit Ihren Vertragsunterlagen.
              Sollten Sie unsere E-Mail nicht erhalten haben, wenden Sie sich
              bitte per Telefon +49-(0)6227-65506 10 oder per E-Mail
              Kunde@Ratior.de an uns an.
            </div>
          }
          noImage={true}
          okTitle={"OK"}
          onOk={() => {
            setOpenSuccessJustSignModal(false);
            navigate(URL.STORAGE);
          }}
        ></ConfirmModal>
        <Box
          sx={{
            marginTop: "32px",
          }}
        >
          <Typography
            variant="subtitle2"
            mb="13px"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            Fantastisch, alle notwendigen Angaben liegen nun vor.
          </Typography>
          <Box
            sm={12}
            sx={{
              width: "100%",
              borderRadius: "8px",
              backgroundColor: "#E3F2FD",
              padding: 10,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Sie können nun Ihre Verträge mit der Ratior AG, der Ratior GmbH
              und der Ratior Gold GmbH abschließen. Hier im Überblick die
              Vertragsdokumente der drei Firmen. Sie können sich vor der
              Unterzeichnung noch einmal ansehen:
            </Typography>
          </Box>
          <Box
            sm={12}
            mt={20}
            mb={30}
            sx={{
              width: "100%",
              "& span": {
                marginRight: "0 !important",
              },
            }}
          >
            <Grid container spacing={10}>
              <DownloadGroup
                title={"Allgemein"}
                downloads={[
                  { name: "Ratior Datenschutzhinweis", downloadPath: "allgemein/Ratior Datenschutzhinweise.pdf" },
                  { name: "Ratior Vertragsdokumente",  downloadPath: "allgemein/MyRatior Nutzungsbedingungen.pdf" },
                ]}
              ></DownloadGroup>

              <DownloadGroup
                title={"Ratior AG"}
                downloads={[
                  { name: "Ratior AG AGB",                      downloadPath: "Ratior AG/Ratior AG AGB.pdf" },
                  { name: "Ratior AG Gebührentabelle",          downloadPath: "Ratior AG/Ratior AG Gebuehrentabelle.pdf" },
                  { name: "Ratior AG Verbraucherinformationen", downloadPath: "Ratior AG/Ratior AG Verbraucherinformationen.pdf" },
                  { name: "Ratior AG Widerrufsbelehrung",       downloadPath: "Ratior AG/Ratior AG Widerrufsbelehrung.pdf" },
                ]}
              ></DownloadGroup>

              <DownloadGroup
                title={"Ratior Gold GmbH"}
                downloads={[
                              { name: "Ratior Gold GmbH AGB",                      downloadPath: "Ratior Gold GmbH/Ratior Gold GmbH AGB.pdf" },
                  !isJunior ? { name: "Ratior Gold GmbH Gebührentabelle Gold",     downloadPath: "Ratior Gold GmbH/Ratior Gold GmbH Gebuehrentabelle Gold.pdf" } : { name: "Ratior Gold GmbH Gebuehrentabelle Gold (Junior)", downloadPath: "Ratior Gold GmbH/Ratior Gold GmbH Gebührentabelle Gold (Junior).pdf" },
                              { name: "Ratior Gold GmbH Verbraucherinformationen", downloadPath: "Ratior Gold GmbH/Ratior Gold GmbH Verbraucherinformationen.pdf" },
                              { name: "Ratior Gold GmbH Risikohinweise zu AGB",    downloadPath: "Ratior Gold GmbH/Ratior Gold GmbH Risikohinweise zu AGB.pdf" },
                ]}
              ></DownloadGroup>

              <DownloadGroup
                title={"Ratior GmbH"}
                downloads={[
                              { name: "Ratior GmbH AGB",                       downloadPath: "Ratior GmbH/Ratior GmbH AGB.pdf" },
                  !isJunior ? { name: "Ratior GmbH Gebührentabelle Silber",    downloadPath: "Ratior GmbH/Ratior GmbH Gebuehrentabelle Silber.pdf" }    : { name: "Ratior GmbH Gebührentabelle Silber (Junior)",    downloadPath: "Ratior GmbH/Ratior GmbH Gebuehrentabelle Silber (Junior).pdf" },
                  !isJunior ? { name: "Ratior GmbH Gebührentabelle Platin",    downloadPath: "Ratior GmbH/Ratior GmbH Gebuehrentabelle Platin.pdf" }    : { name: "Ratior GmbH Gebührentabelle Platin (Junior)",    downloadPath: "Ratior GmbH/Ratior GmbH Gebuehrentabelle Platin (Junior).pdf" },
                  !isJunior ? { name: "Ratior GmbH Gebührentabelle Palladium", downloadPath: "Ratior GmbH/Ratior GmbH Gebuehrentabelle Palladium.pdf" } : { name: "Ratior GmbH Gebührentabelle Palladium (Junior)", downloadPath: "Ratior GmbH/Ratior GmbH Gebuehrentabelle Palladium (Junior).pdf" },
                              { name: "Ratior GmbH Verbraucherinformationen",  downloadPath: "Ratior GmbH/Ratior GmbH Verbraucherinformationen.pdf" },
                              { name: "Ratior GmbH Risikohinweise zu AGB",     downloadPath: "Ratior GmbH/Ratior GmbH Risikohinweise zu AGB.pdf" },
                ]}
              ></DownloadGroup>
            </Grid>
          </Box>

          <Typography
            variant="subtitle2"
            mb="13px"
            mt="13px"
            sx={{
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Im nächsten Schritt bestätigen Sie durch Ihre digitale Unterschrift mit Verifizierung durch eine PIN-Nummer, die Sie per SMS auf Ihre bei uns hinterlegte Handy Nr. zugeschickt bekommen.
          </Typography>
          <Grid xs={12} lg={12} item>
            <MUICheckBox
              name="agreement"
              onClick={() => setAgreement(!agreement)}
              multlineLabel={true}
              label={
                "Hiermit bestätige ich die oben aufgelisteten Dokumente gelesen und verstanden zu haben. " +
                "Weiter erkläre ich mich, mit dem Inhalt der Dokumente einverstanden zu sein.\n" + 
                "Ihnen wir das Vertragswerk als PDF-Datei (datenschutzkonform) unausgefüllt per E-Mail zugeschickt."
              }
            />
          </Grid>
          <FormStepperStructure.Footer>
            {!BYPASS_ADOBE_SIGN && (
              <>
                <Button
                  onClick={() => onSubmit(true)}
                  fullWidth
                  sx={{
                    borderColor: "info.main",
                    color: "info.main",
                    marginRight: 10,
                    maxWidth: "250px",
                  }}
                  variant="outlined"
                >
                  Später unterschreiben
                </Button>
                <Button
                  onClick={() => onSubmit(false)}
                  fullWidth
                  sx={{ bgcolor: "info.main", maxWidth: "300px" }}
                  variant="contained"
                >
                  Verträge jetzt unterzeichnen
                </Button>
              </>
            )}
            {BYPASS_ADOBE_SIGN && (
              <>
                <Button
                  disabled={!agreement}
                  onClick={() => {
                    dispatch(
                      verificationSendSMSCodeAction({
                        mobileNumber: localStorage.getItem(MOBILE_NUMBER),
                      })
                    );
                    handleCurrentStep("next");
                  }}
                  fullWidth
                  sx={{ bgcolor: "info.main", maxWidth: "192px" }}
                  variant="contained"
                >
                  Weiter
                </Button>
              </>
            )}
          </FormStepperStructure.Footer>
        </Box>
      </LoadingOverlay>
    </Box>
  );
}

export default SignStorageContract;
