import React, { useState } from "react";
import { FormStepper } from "../../common";

import VermittlerDashboard from "./vermittlerDashboard";
import ActionCodeAddStep from "./actionCodeAdd.step";

function VermittlerDashboardStepManager() {
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const stepsCount = 2;

  const HandleCurrentStep = (step) => {
    window.scrollTo(0, 0); // Scroll up to the top because it is confusing on mobile if the scroll position stays
    if (isNaN(parseInt(step))) {
      if (step === "next" && currentStep < stepsCount) {
        setCurrentStep((prev) => prev + 1);
      } else if (step === "prev" && currentStep > 1) {
        setCurrentStep((prev) => prev - 1);
      }
    } else {
      if (currentStep <= stepsCount && currentStep >= 1) {
        setCurrentStep(step);
      }
    }
  };

  return (
    <FormStepper
      currentStep={currentStep}
      setCurrentStep={HandleCurrentStep}
      loading={loading}
      noHeader={[1, 2]}
      noFooter={[1, 2]}
      headerTitles={["", ""]}
    >
      {/* Step 1 */}
      <VermittlerDashboard handleCurrentStep={HandleCurrentStep} />
      <ActionCodeAddStep handleCurrentStep={HandleCurrentStep} />
    </FormStepper>
  );
}

export default VermittlerDashboardStepManager;
