import React, { useState, useEffect } from "react";
import { useApi } from "../../../core/hooks/useApi";
import Input from "../../common/inputs/input";
import Datepicker from "../../common/inputs/datepicker";
import Button from "../../common/buttons/button";
import CheckBox from "../../common/inputs/checkbox";
import Select from "../../common/inputs/select";
import { useNavigate, useSearchParams } from "react-router-dom";
import back from "../../../assets/images/back.svg";
import "./registration.scss";
import { emailIsValid } from "../../../core/validations";
import { URL } from "../../../core/constants";
import { BASE_URL, BASE_URL_UI } from "../../../core/apiconfig/environment";
import { longDateTo10 } from "../../../core/utils/convert";
import { useSnackbar } from "notistack";
import ConfirmModal from "../../common/popups/confirmModal";
import { calculateAge } from "../../../core/utils/commonfuncs";
import eighteen from "../../../assets/images/eighteen.svg";
import LoadingOverlay from "../../common/LoadingOverlay/LoadingOverlay";
import { resetCache } from "../../../core/utils/cachManager";
import AGB from "../../../assets/documents/AGB.pdf";
import DataProtection from "../../../assets/documents/DataProtection.pdf";
import { Box, Tab, Grid, Typography } from "@mui/material";

const PersonalInfoVerification = () => {
  const { fetchOptions, post, get } = useApi();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [searchParams, setSearchParams] = useSearchParams();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [ageConfirmOpen, setAgeConfirmOpen] = useState(false);
  const [touched, setTouched] = useState({});
  const [genders, setGenders] = useState([]);
  const [titles, setTitles] = useState([]);
  const [agreed1, setAgreed1] = useState(false);
  const [agreed2, setAgreed2] = useState(false);
  const [agreed3, setAgreed3] = useState(false);
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState({
    firstname: "",
    lastname: "",
    birthdate: null,
    gender: "",
    email: "",
    promotionCode: searchParams.get("ac"),
    email_repeat: "",
    partnerNumber: "",
    title: "",
  });

  const requiredMsg = "erforderlich";
  const emailMismatchMsg = "E-Mail stimmt nicht überein.";
  const emailIsValidMsg = "E-Mail-Adresse ist ungültig";
  const promotionCodeMsg =
    "Entweder Aktionscode oder Partner Nr muss ausgefüllt werden";

  useEffect(() => {
    fetchGenders();
    fetchTitles();
  }, []);

  const fetchGenders = async () => {
    setLoading(true);
    const result = await get(`${BASE_URL}/auth/genders`);
    setLoading(false);
    setGenders(result);
  };

  const fetchTitles = async () => {
    setLoading(true);
    const result = await get(`${BASE_URL}/auth/titles`);
    setLoading(false);
    let list = [];
    list.push({ key: null, value: "--auswählen--" });
    if (result.data && result.data.value) {
      {
        result.data.value.map((item) => {
          list.push({ key: item.arades_titelid, value: item.arades_name });
        });
        setTitles(list);
      }
    }
  };

  const handleChange = (event) => {
    if (event.target.name == "email" || event.target.name == "email_repeat")
      if (!touched[event.target.name])
        setTouched({ ...touched, [event.target.name]: true });

    setValue({ ...value, [event.target.name]: event.target.value });
  };

  const registered = async (info) => {
    let registerObj = {
      email: info.email,
      birthdate: longDateTo10(info.birthdate),
      firstname: info.firstname,
      lastname: info.lastname,
      title: info.title,
      gender: info.gender,
      promotionCode: info.promotionCode ?? null,
      partnerNumber: info.partnerNumber ?? null,
      dataPolicy: true,
      redirectUrl: BASE_URL_UI + "definepass",
      newsletter: agreed3,
    };

    if (registerObj.title == "") registerObj.title = null;
    setLoading(true);
    let registerResult = await post(`${BASE_URL}auth/register`, registerObj);
    setLoading(false);
    if (
      registerResult &&
      registerResult.status != 201 &&
      registerResult.status != 200 &&
      registerResult.data.length > 0
    ) {
      enqueueSnackbar(registerResult.data[0].msg, {
        variant: "error",
        autoHideDuration: 20000,
      });
      return false;
    } else if (
      registerResult &&
      (registerResult.status == 201 || registerResult.status == 200)
    )
      return true;
    else return false;
  };

  const handleSubmit = async () => {
    let age = calculateAge(value.birthdate);
    if (age < 18) setAgeConfirmOpen(true);
    else {
      if (!formIsValid()) return;

      let registeredsuccess = await registered(value);
      if (registeredsuccess) {
        setConfirmOpen(true);
      }
    }
  };

  const backToLogin = () => {
    navigate(URL.LOGIN);
  };

  const formIsValid = () => {
    let formValid = true;
    if (
      !(
        agreed1 &&
        agreed2 &&
        value.firstname &&
        value.birthdate <= Date.now() &&
        value.gender &&
        value.lastname &&
        value.email &&
        value.email_repeat &&
        value.email.toLowerCase() == value.email_repeat.toLowerCase()
      ) ||
      (value.promotionCode && value.partnerNumber)
    )
      formValid = false;
    else if (!emailIsValid(value.email) || !emailIsValid(value.email_repeat))
      formValid = false;

    return formValid;
  };
  return (
    <LoadingOverlay show={loading}>
      <ConfirmModal
        open={ageConfirmOpen}
        title={"Tut mir leid, du bist minderjährig"}
        imageSrc={eighteen}
        showCloseButton={true}
        message={
          "Wir können nichts für dich erstellen, weil du unter 18 bist. Aber du kannst deine Eltern bitten, ein Konto für dich zu erstellen."
        }
        okTitle={"AUSLOGGEN"}
        onOk={() => {
          resetCache();
          navigate(URL.LOGIN);
          window.location.reload();
        }}
        onClose={() => setAgeConfirmOpen(false)}
      ></ConfirmModal>
      <ConfirmModal
        open={confirmOpen}
        title={"Glückwunsch"}
        showCloseButton={false}
        message={
          "Wir haben Ihnen einen Einladungslink geschickt. Bitte loggen Sie sich in Ihrer E-Mail-Adresse ein und bestätigen Sie über den Link in der E-Mail."
        }
        okTitle={"LOGIN"}
        onOk={() => {
          resetCache();
          navigate(URL.LOGIN);
          window.location.reload();
        }}
      ></ConfirmModal>
      {/* <Box
        sx={{
          maxWidth: "816px",
          margin: "0 auto",
          marginTop: "32px",
        }}
      >
        <Box mt="80px" mb="60px">
          <Grid spacing={8} direction="column" container>
            <Grid item>
              <Typography
                variant="subtitle2"
                mb="10px"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                {
                  "Bitte wählen Sie, in welcher Währungseinheit Sie Ihre"
                }
                <br></br>
                {" Edelmetallbestände angezeigt bekommen möchten:"}
              </Typography>
            </Grid>
            <Grid item>
              <Select
                error={Boolean(errors.currencyUnit)}
                helperText={errors.currencyUnit?.message}
                control={control}
                name="currencyUnit"
                label="Währung"
                options={currencyList}
              />
            </Grid>

            <Grid item>
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  width: "100%",
                }}
              >
                <center>
                  {"Bitte wählen Sie die Währung sorgfältig!"}
                  <br></br>
                  {"Eine nachträgliche Änderung ist nicht mehr möglich."}
                </center>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box> */}
      <div>
        <div className="register-title">{"Anmeldung"}</div>
        <div className="step">Information</div>
        <div className="register-box-wrapper">
          <div className="flex-column register-box">
            <div className="col-12 padding-20">
              Bitte tragen Sie alle Namen und Vornamen wie im Ausweis ein,
              <br></br> da diese bei der Verifizierung überprüft werden.
            </div>
            <div className="padding-20 form-row">
              <div className="col-md-6 col-12 form-item">
                <Select
                  error={touched.gender && value.gender === ""}
                  helperText={
                    touched.gender && value.gender === "" ? requiredMsg : ""
                  }
                  onBlur={() => setTouched({ ...touched, gender: true })}
                  name={"gender"}
                  value={value.gender}
                  onChange={handleChange}
                  options={genders}
                  label={"Anrede*"}
                />
              </div>
              <div className="col-md-6 col-12 form-item">
                <Select
                  name={"title"}
                  value={value.title}
                  onChange={handleChange}
                  options={titles}
                  label={"Title"}
                />
              </div>
            </div>
            <div className="padding-20 form-row">
              <div className="col-md-6 col-12 form-item">
                <Input
                  error={touched.firstname && value.firstname === ""}
                  helperText={
                    touched.firstname && value.firstname === ""
                      ? requiredMsg
                      : ""
                  }
                  onBlur={() => setTouched({ ...touched, firstname: true })}
                  name={"firstname"}
                  value={value.firstname}
                  onChange={handleChange}
                  label={"Vorname*"}
                />
              </div>
              <div className="col-md-6 col-12 form-item">
                <Input
                  error={touched.lastname && value.lastname === ""}
                  helperText={
                    touched.lastname && value.lastname === "" ? requiredMsg : ""
                  }
                  onBlur={() => setTouched({ ...touched, lastname: true })}
                  name={"lastname"}
                  value={value.lastname}
                  onChange={handleChange}
                  label={"Nachname*"}
                />
              </div>
            </div>
            <div className="padding-20 form-row">
              <div className="col-md-6 col-12 form-item">
                <Datepicker
                  maxDate={Date.now()}
                  name={"birthdate"}
                  value={value.birthdate}
                  onChange={handleChange}
                  label={"Geburtsdatum"}
                  required={false}
                />
              </div>
              <div className="col-md-6 col-12 form-item"> </div>
            </div>
            <div className="padding-20 form-row">
              <div className="col-md-6 col-12 form-item">
                <Input
                  error={
                    touched.email &&
                    (value.email === "" ||
                      (value.email && !emailIsValid(value.email)) ||
                      (value.email &&
                        value.email_repeat &&
                        value.email.toLowerCase() !=
                          value.email_repeat.toLowerCase()))
                  }
                  helperText={
                    !touched.email
                      ? ""
                      : value.email === ""
                      ? requiredMsg
                      : value.email && !emailIsValid(value.email)
                      ? emailIsValidMsg
                      : value.email &&
                        value.email_repeat &&
                        value.email.toLowerCase() !=
                          value.email_repeat.toLowerCase()
                      ? emailMismatchMsg
                      : ""
                  }
                  onBlur={() => setTouched({ ...touched, email: true })}
                  name={"email"}
                  type={"email"}
                  value={value.email}
                  onChange={handleChange}
                  label={"Email*"}
                  placeholder={"Exp: mail@website.com"}
                />
              </div>
              <div className="col-md-6 col-12 form-item">
                <Input
                  error={
                    touched.email_repeat &&
                    (!value.email_repeat ||
                      (value.email_repeat &&
                        !emailIsValid(value.email_repeat)) ||
                      (value.email_repeat &&
                        value.email &&
                        value.email.toLowerCase() !=
                          value.email_repeat.toLowerCase()))
                  }
                  helperText={
                    !touched.email_repeat
                      ? ""
                      : !value.email_repeat
                      ? requiredMsg
                      : value.email_repeat && !emailIsValid(value.email_repeat)
                      ? emailIsValidMsg
                      : value.email_repeat &&
                        value.email &&
                        value.email.toLowerCase() !=
                          value.email_repeat.toLowerCase()
                      ? emailMismatchMsg
                      : ""
                  }
                  onBlur={() => setTouched({ ...touched, email_repeat: true })}
                  name={"email_repeat"}
                  type={"email"}
                  value={value.email_repeat}
                  onChange={handleChange}
                  label={"Email wiederholen*"}
                  placeholder={"Exp: mail@website.com"}
                />
              </div>
            </div>
            <div className="padding-20 form-row">
              <div className="col-md-6 col-12 form-item">
                <Input
                  error={value.promotionCode && value.partnerNumber}
                  helperText={
                    value.promotionCode &&
                    value.partnerNumber &&
                    promotionCodeMsg
                  }
                  onBlur={() => setTouched({ ...touched, promotionCode: true })}
                  disabled={searchParams.get("ac")}
                  name={"promotionCode"}
                  value={value.promotionCode}
                  onChange={handleChange}
                  label={"Aktionscode"}
                />
              </div>
              <div className="col-md-6 col-12 form-item">
                <Input
                  error={value.promotionCode && value.partnerNumber}
                  helperText={
                    value.promotionCode &&
                    value.partnerNumber &&
                    promotionCodeMsg
                  }
                  onBlur={() => setTouched({ ...touched, partnerNumber: true })}
                  disabled={searchParams.get("ac")}
                  name={"partnerNumber"}
                  value={value.partnerNumber}
                  onChange={handleChange}
                  label={"Partner Nr"}
                />
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="form-row form-check-row padding-10 justify-content-start">
                <CheckBox
                  id="checkme-agreed1"
                  name={"agreed1"}
                  value={agreed1}
                  onChange={() => setAgreed1(!agreed1)}
                ></CheckBox>
                <label className="d-flex" htmlFor={"checkme-agreed1"}>
                  <span>
                    Ich habe die{" "}
                    <a
                      className="blue"
                      href={AGB}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Nutzungsbedingungen
                    </a>
                    &nbsp; des MyRatior Bereiches gelesen und akzeptiere diese.*
                  </span>
                </label>
              </div>
              <div className="form-row form-check-row padding-10 justify-content-start">
                <CheckBox
                  id="checkme-agreed2"
                  name={"agreed2"}
                  value={agreed2}
                  onChange={() => setAgreed2(!agreed2)}
                ></CheckBox>
                <label className="d-flex" htmlFor={"checkme-agreed2"}>
                  <span>
                    Ich habe die{" "}
                    <a
                      className="blue"
                      href={DataProtection}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Datenschutzinformationen
                    </a>
                    &nbsp; gelesen und akzeptiere diese. *
                  </span>
                </label>
              </div>
              <div className="form-row form-check-row padding-10 justify-content-start">
                <CheckBox
                  id="checkme-agreed3"
                  name={"agreed3"}
                  value={agreed3}
                  onChange={() => setAgreed3(!agreed3)}
                ></CheckBox>
                <label className="d-flex" htmlFor={"checkme-agreed3"}>
                  <span>Ja, ich will den Newsletter. </span>
                </label>
              </div>
            </div>
            <div className="padding-30 form-row div-btn-register ">
              <div className="link-btn" onClick={backToLogin}>
                <span>
                  <img src={back} className="imgback"></img>{" "}
                  {"Zurück zur Anmeldung"}
                </span>
              </div>
              <Button
                disabled={!formIsValid()}
                className="btn-action"
                onClick={handleSubmit}
                style={{ marginRight: 20 }}
              >
                {"Anmeldung"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default PersonalInfoVerification;
