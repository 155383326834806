import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const ModalBox = ({
  title,
  content,
  open,
  showCloseButton,
  width,
  onClose,
  sx
}) => {
  const [openMe, setOpenMe] = useState(open);

  const styles = {
    textAlign: "center",
    fontSize: 18,
    width: width || 500,
    maxWidth: "100vw", // No overflow on mobile
    borderRadious: 8,
  };

  const titleStyles = {
    fontSize: "18px",
    paddingLeft: "30px",
    paddingTop: "30px",
    paddingRight: "30px",
  };

  const modalStyle = {
    closeIcon: { display: showCloseButton ? "true" : "none" },
    overlay: {
      background: "#000000",
      opacity: 0.6,
    },
  };

  useEffect(() => {
    setOpenMe(open);
  }, [open]);

  return (
    <div style={styles}>
      <Modal
        open={openMe}
        onClose={onClose}
        center
        styles={modalStyle}
        closeIconSize={106}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        sx={sx}
      >
        <h2 className={`modal-title`} style={titleStyles}>
          {title || ""}
        </h2>
        <div>{content || ""}</div>
      </Modal>
    </div>
  );
};
export default ModalBox;
