import React from "react";

const MetalCell = ({ metal, width, disabled, hideName, nameBelowSymbol }) => {
  const avatar = (color, letter) => (
    <div
      style={{
        display: "flex",
        width: width || 26,
        height: width || 26,
        borderRadius: (width && width / 2) || 13,
        backgroundColor: color,
        justifyContent: "center",
        alignItems: "center",
        color: "black",
        opacity: disabled ? 30 : 100,
        fontSize: width > 10 ? 20 : 13,
      }}
    >
      {letter}
    </div>
  );

  return (
    <div>
      {metal && (
        <div className="d-flex" style={{ flexDirection: nameBelowSymbol ? "column" : "row", alignItems: nameBelowSymbol ? "flex-start" : "center", padding: "2px" }}>
          {metal.toLowerCase().indexOf("gold") >= 0
            ? avatar("#EECC24", "G")
            : metal.toLowerCase().indexOf("silber") >= 0 ||
              metal.toLowerCase().indexOf("silver") >= 0
            ? avatar("#EEEEEE", "S")
            : metal.toLowerCase().indexOf("palladium") >= 0
            ? avatar("#FCD9B9", "PL")
            : metal.toLowerCase().indexOf("platin") >= 0
            ? avatar("#E3F2FD", "PT")
            : avatar("#ff7043", metal[0].toUpperCase())}
          {!hideName && (
            <span style={{ paddingTop: nameBelowSymbol ? "5px" : undefined }}>
              {nameBelowSymbol ? "" : <>&nbsp;</>}
              {metal.toLowerCase().indexOf("gold") >= 0
                ? "Gold"
                : metal.toLowerCase().indexOf("silber") >= 0 ||
                  metal.toLowerCase().indexOf("silver") >= 0
                ? "Silber"
                : metal.toLowerCase().indexOf("palladium") >= 0
                ? "Palladium"
                : metal.toLowerCase().indexOf("platin") >= 0
                ? "Platin"
                : metal}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default MetalCell;
