import React, { useEffect, useState } from "react";
import ReactDom from "react-dom";
import { Box, Grid, Button, Typography } from "@mui/material";
import { Input, LoadingOverlay } from "../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { ERROR_CONTACT_US } from "../../core/constants";
import {
  newMessage,
  setNewMessage,
  hideNewMessageForm,
  globalData,
  resetError,
} from "../../redux/slices/inbox/inbox.slice";
import { postNewMessageAction } from "../../redux/slices/inbox";
import pdfIcon from "../../assets/images/pdf.svg";
import jpgIcon from "../../assets/images/jpg.svg";

function SendMessage() {
  const dispatch = useDispatch();
  const fields = useSelector(newMessage);
  const globalFields = useSelector(globalData);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFiles, setSelectedFiles] = useState([]);
  useEffect(() => {
    if (fields.sendTo) {
      setValue("sendTo", fields.sendTo);
    }
  }, [fields]);

  useEffect(() => {
    if (globalFields.loading && globalFields.actionType === "") {
      setLoading(false);
    }
  }, [globalFields.loading, globalFields.actionStatus]);

  useEffect(() => {
    if (globalFields.actionType === "postMessage") {
      enqueueSnackbar("Your message sent successfully", {
        variant: "success",
        autoHideDuration: 3000,
      });
      setLoading(false);
      dispatch(hideNewMessageForm());
    }
  }, [globalFields.actionStatus]);

  useEffect(() => {
    if (globalFields.error != null) {
      enqueueSnackbar(globalFields.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      setLoading(false);
      dispatch(resetError());
    }
  }, [globalFields.error]);

  const requiredMsg = "erforderlich";
  const schema = yup.object().shape({
    sendTo: yup
      .string()
      .email("E-Mail Addresse ist nicht gültig") //("Should be a valid Email address")
      .required(requiredMsg),
    message: yup.string().required(requiredMsg),
  });

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      ...fields,
    },
    resolver: yupResolver(schema),
  });
  const handleSelectedFiles = (event) => {
    const selectedFilesInfo = [];
    for (let file of event.target.files) {
      selectedFilesInfo.push({
        name: file.name,
        size: file.size,
        type: file.type,
      });
    }
    setSelectedFiles(selectedFilesInfo);
    setValue("files", event.target.files);
  };
  const onSubmit = (data) => {
    setLoading(true);
    dispatch(setNewMessage(data));
    dispatch(postNewMessageAction());
  };
  return (
    fields.show &&
    ReactDom.createPortal(
      <Box
        sx={{
          bottom: 0,
          right: "30px",
          borderRadius: "8px 8px 0 0",
          height: "100%",
          maxHeight: "435px",
          backgroundColor: "#fff",
          position: "fixed",
          boxShadow: "0 0 5px #bbb",
          width: "421px",
          zIndex: 1001,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            color: "#fff",
            backgroundColor: "rgba(5, 132, 124, 1)",
            fontSize: "16px",
            height: "56px",
            borderRadius: "8px 8px 0 0",
            padding: "0 20px",
            justifyContent: "space-between",
          }}
        >
          New message
          <Box
            onClick={() => dispatch(hideNewMessageForm())}
            sx={{
              cursor: "pointer",
              p: "8px",
            }}
          >
            <FontAwesomeIcon size="lg" icon={faTimes} />
          </Box>
        </Box>
        <Box
          className="scroll-tiny"
          sx={{
            position: "relative",
            overflowY: "auto",
            height: "calc(100% - 56px)",
            padding: "0 20px 0",
          }}
        >
          <LoadingOverlay show={loading}>
            <Grid container spacing={8} direction="column">
              <Grid item>
                ‍{" "}
                <Input
                  control={control}
                  error={errors.sendTo}
                  helperText={errors.sendTo?.message}
                  name="sendTo"
                  placeholder="Receiver name..."
                  label="Send to"
                />
              </Grid>
              <Grid item>
                <Input
                  control={control}
                  error={errors.message}
                  helperText={errors.message?.message}
                  sx={{ width: "100%" }}
                  id="outlined-multiline-static"
                  label="Multiline"
                  multiline
                  rows={7}
                  name="message"
                />
              </Grid>

              {selectedFiles.length > 0 && (
                <Box
                  sx={{
                    padding: "0 20px",
                    display: "flex",
                    flexWrap: "wrap",
                    mt: "20px",
                    alignItems: "center",
                  }}
                >
                  {selectedFiles.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        border: "1px solid rgba(33, 33, 33, 0.08)",
                        borderRadius: "5px",
                        bgcolor: "rgba(255, 255, 255, 0.04)",
                        p: "9px",
                        display: "flex",
                        alignItems: "center",
                        maxWidth: "214px",
                        mr: "8px",
                        mb: "8px",
                      }}
                    >
                      <Box pr="10px">
                        {item.type == "image/png" ? (
                          <img src={jpgIcon}></img>
                        ) : (
                          <img src={pdfIcon}></img>
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            whiteSpace: "noWrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            maxWidth: "146px",
                            fontSize: "12px",
                          }}
                          variant="subtitle2"
                        >
                          {item.name}
                        </Typography>
                        <Typography sx={{ fontSize: "10px" }} variant="caption">
                          {item.size}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}

              <Grid
                sx={{ display: "flex", mb: "10px", alignItems: "center" }}
                item
              >
                <Button
                  onClick={handleSubmit(onSubmit)}
                  fullWidth
                  sx={{ bgcolor: "rgba(5, 132, 124, 1)", maxWidth: "145px" }}
                  variant="contained"
                >
                  Send Message
                </Button>

                <label htmlFor="btn-upload-file">
                  <Input
                    // control={control}
                    // error={errors.files}
                    // helperText={errors.files?.message}
                    // name="files"
                    sx={{ display: "none" }}
                    onChange={handleSelectedFiles}
                    // accept="image/*"
                    id="btn-upload-file"
                    type="file"
                    inputProps={{ multiple: true }}
                  />
                  <Button
                    startIcon={<FontAwesomeIcon icon={faFileUpload} />}
                    sx={{ ml: "15px" }}
                    variant="contained"
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </Grid>
            </Grid>
          </LoadingOverlay>
        </Box>
      </Box>,
      document.getElementById("message-portal")
    )
  );
}

export default SendMessage;
