import React, { useState, useEffect } from "react";
import { FormStepper } from "../../common";
import SignStorageContract from "../addStorage/sharedSteps/signStorageContract.step";
//import StoragePaymentStep from "../addStorage/sharedSteps/storagePaymentStep.step";
import { useSelector, useDispatch } from "react-redux";
import { getActionCodeCustomerDataAction } from "../../../redux/slices/storage";
import { resetAll } from "../../../redux/slices/storage/storage.slice";
import { useNavigate } from "react-router-dom";
import { URL, ACTION_CODE_TYPE } from "../../../core/constants";

function SignContractStepManager({
  handleCurrentStep: handlerParentStep,
  parent,
}) {

  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const stepsCount = 1;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      getActionCodeCustomerDataAction({
        actionCodeType: ACTION_CODE_TYPE.STORAGE_FEE,
        justActive: true,
      })
    );
  }, []);

  const HandleCurrentStep = (step, parent) => {
    window.scrollTo(0, 0); // Scroll up to the top because it is confusing on mobile if the scroll position stays
    if (parent) {
      handlerParentStep(parent);
    } else {
      if (isNaN(parseInt(step))) {
        if (step === "next" && currentStep < stepsCount) {
          setCurrentStep((prev) => prev + 1);
        } else if (step === "prev" && currentStep > 1) {
          setCurrentStep((prev) => prev - 1);
        }
      } else {
        if (currentStep <= stepsCount && currentStep >= 1) {
          setCurrentStep(step);
        }
      }
    }
  };

  const handleCancel = () => {
    dispatch(resetAll());
    navigate(URL.STORAGE);
  };

  return (
    <div className="container-card">
      <FormStepper
        parent={parent}
        currentStep={currentStep}
        setCurrentStep={HandleCurrentStep}
        loading={loading}
        noBackButton={[1]}
        hasCancel={[1]}
        onCancel={handleCancel}
        noFooter={[1]}
        headerTitles={[
          "Ihre Vertragsunterlagen",
          //, "Payment"
        ]}
      >
        {/* Step 6- SignStorageContract*/}
        <SignStorageContract
          handleCurrentStep={HandleCurrentStep}
          justSign={true}
        />

        {/* Step 8- StoragePaymentStep */}
        {/* <StoragePaymentStep handleCurrentStep={HandleCurrentStep} /> */}
      </FormStepper>
    </div>
  );
}

export default SignContractStepManager;
