import React, { useEffect } from "react";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

function RadioListIcon({
  activeColor = "#2196F3",
  data,
  currentValue,
  handleSetCurrentValue,
}) {
  return (
    <>
      <RadioGroup
        aria-labelledby="radio-group"
        value={currentValue}
        onChange={(e) => handleSetCurrentValue(e.target.value)}
        name="radio-buttons-group"
      >
        {data?.map((item) => {
          return (
            <FormControlLabel
              sm={12}
              key={item.value}
              disabled={item.disabled}
              sx={{
                padding: "20px",
                borderRadius: "8px",
                borderStyle: "solid",
                borderWidth: "1px",
                width: "100%",
                mb: "20px",
                mr: "0",
                ml: "0",
                color: `${
                  item.value === currentValue
                    ? "rgba(0, 0, 0, 0.87)"
                    : "rgba(0, 0, 0, 0.6)"
                }`,
                borderColor: `${
                  item.value === currentValue
                    ? activeColor
                    : "rgba(0, 0, 0, 0.12)"
                }`,
              }}
              value={item.value}
              control={
                <Radio
                  disabled={item.disabled}
                  checked={item.value === currentValue}
                />
              }
              label={
                <Box sx={{ display: "flex" }} sm={12}>
                  <Box sx={{ width: "fit-content", paddingRight: "6px" }} sm={12}>
                    {item?.icon}
                  </Box>
                  <Typography
                    as="div"
                    sm={12}
                    sx={{
                      // whiteSpace: "pre",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "5px",
                      width: "100%",
                    }}
                  >
                    {item.label}
                  </Typography>
                </Box>
              }
            />
          );
        })}
      </RadioGroup>
    </>
  );
}

export default RadioListIcon;
