import React, { useState, useEffect } from "react";
import { DataTable, Select, FilterArrow, Input} from "../common";
import { CELL_RENDER_TYPE, ERROR_CONTACT_US } from "../../core/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getVermittlerStorageListAction,
  getVermittlerBuyInvoiceListAction,
} from "../../redux/slices/vermittlerDashboard";
import { useSnackbar } from "notistack";
import { Typography, Box, Grid, Paper, Button } from "@mui/material";
import { dateString, localeFormat } from "../../core/utils/convert";
import {
  totalMetalPricesList,
  depositMonthly,
  globalData,
  resetError,
  resetActionStatus,
  customerStorages,
} from "../../redux/slices/vermittlerDashboard/vermittlerDashboard.slice";
import LoadingOverlay from "../common/LoadingOverlay/LoadingOverlay";
import { useTranslation } from "react-i18next";
import { METAL_CHARS } from "../../core/constants";
import { MetalPart } from "../../core/utils/business";
import PreciousMetalsChart from "../common/PreciousMetalsChart/PreciousMetalsChart";
import DepositsChart from "../common/DepositsChart/DepositsChart";
import { exportAndDownloadCsv, exportAndDownloadExcel } from "../../core/utils/tableExport";
import { useIsScreenSizeDesktop } from "../../core/utils/responsive";
import { is } from "date-fns/locale";

const CustomerStorageList = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const isDesktop = useIsScreenSizeDesktop();

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [exportButtonDisabled, setExportButtonDisabled] = useState(false);

  const [filter, setFilter] = useState({
    metalType: "0",
  });

  
  const depositMonthlyFields = useSelector(depositMonthly);
  const totalMetalPricesListFields = useSelector(totalMetalPricesList);

  const storageList = useSelector(customerStorages);
  const vermittlerListsGlobalData = useSelector(globalData);
  
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    dispatch(
      getVermittlerBuyInvoiceListAction()
    );

    dispatch(
      getVermittlerStorageListAction()
    );
  }, []);

  const onFilterChange = (event) => {
    //setFilter({ ...filter, [event.target.name]: event.target.value });
    let newFilter = { ...filter, [event.target.name]: event.target.value };
    setFilter(newFilter);

    const filteredData = storageList.filter(
      (x) =>
        (newFilter.storageNumber
          ? x.storageNumber
              ?.toLowerCase()
              .indexOf(newFilter.storageNumber.toLowerCase()) >= 0
          : true) &&
          (newFilter.customerName
            ? x.customerName
                ?.toLowerCase()
                .indexOf(newFilter.customerName.toLowerCase()) >= 0
            : true) &&
        (newFilter.metalType != "0"
          ? (newFilter.metalType == "1" &&
              MetalPart(x.storageNumber) == METAL_CHARS.GOLD) ||
            (newFilter.metalType == "2" &&
              MetalPart(x.storageNumber) == METAL_CHARS.SILVER) ||
            (newFilter.metalType == "3" &&
              MetalPart(x.storageNumber) == METAL_CHARS.PLATIN) ||
            (newFilter.metalType == "4" &&
              MetalPart(x.storageNumber) == METAL_CHARS.PALLADIUM)
          : true) 
    );

    setFilteredList(filteredData);
  };

  useEffect(() => {
    if (
      vermittlerListsGlobalData.actionStatus &&
      vermittlerListsGlobalData.actionType === "getCustomerstorages"
    ) {
      dispatch(resetActionStatus());
    }
  }, [vermittlerListsGlobalData.actionStatus]);

  useEffect(() => {
    if (vermittlerListsGlobalData.error != null) {
      enqueueSnackbar(vermittlerListsGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [vermittlerListsGlobalData.error]);

  useEffect(() => {
    setLoading(vermittlerListsGlobalData.loading);
  }, [vermittlerListsGlobalData.loading]);

  useEffect(() => {
    if (vermittlerListsGlobalData.actionStatus && vermittlerListsGlobalData.actionType === "getVermittlerStorageList") {
      setFilteredList(storageList);
    }
  }, [vermittlerListsGlobalData.actionStatus, storageList]);

  return (
    <LoadingOverlay show={loading}>
      <div className="container-card">
        <Grid mt="20px" spacing={8} container>
          <Grid item xs={12} md={6} lg={7}>
            <Paper sx={{ p: "16px", height: 1 }} variant="outlined">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: "10px",
                }}
              >
                <Typography variant="subtitle1" component="h6">
                  {t("vermittler-dashboard.customer-storages")}
                </Typography>
              </Box>
              <DepositsChart depositMonthlyFields={depositMonthlyFields} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Paper sx={{ p: "16px", height: 1 }} variant="outlined">
              <Typography variant="subtitle1" component="h6">{ t("vermittler-dashboard.precious-metals.title") }</Typography>
              <Typography variant="caption">{ t("vermittler-dashboard.precious-metals.subtitle") }</Typography>
              <Box mt={6}>
                <PreciousMetalsChart prices={totalMetalPricesListFields} />
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Box
          mb={10}
          sx={{
            display: "flex",
            flexDirection: isDesktop ? "row" : "column",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <Typography sx={{ fontWeight: 500 }} variant="h5" component="h5" style={{ width: !isDesktop ? "100%" : undefined }}>
            {t("customer-storage-list.title")}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={(evt) => {
                setExportButtonDisabled(true); // Disable button to prevent downloading twice and to give click feedback
                exportAndDownloadExcel("ratior-kunden-lagerplaetze.xlsx", filteredList, [
                  { column: t("customer-storage-list.customer"),      type: String, width: 25, value: row => row.customerName },
                  { column: t("customer-storage-list.metal-type"),    type: String, width: 17, value: row => row.metalName },
                  { column: t("customer-storage-list.storage"),       type: String, width: 17, value: row => row.storageNumber },
                  { column: t("customer-storage-list.weight"),        type: Number, width: 17, value: row => row.arades_verfuegbare_menge, format: '0.##0,00" g"' },
                  { column: t("customer-storage-list.storage-value"), type: Number, width: 17, value: row => row.totalPrice,               format: '0.##0,00" €"' },
                  { column: t("customer-storage-list.description"),   type: String, width: 25, value: row => row.storageDescription },
                  { column: t("customer-storage-list.status"),        type: String, width: 21, value: row => row.storageStatus },
                  { column: t("customer-storage-list.create-date"),   type: Date,   width: 17, value: row => new Date(row.createDate), format: "DD.MM.YYYY" },
                ]);
              }}
              variant="contained"
              sx={{ mr: 6 }}
              disabled={exportButtonDisabled}
            >
              {t("buttons.export-csv")}
            </Button>
            <FilterArrow show={show} setShow={setShow}></FilterArrow>
          </Box>
        </Box>
        {show && (
          <Box>
            <Grid container spacing={8}>
            <Grid xs={12} lg={3} item>
              <Input
                name="customerName"
                label={t("customer-storage-list.customer")}
                value={filter.customerName}
                onChange={onFilterChange}
              />
            </Grid>
            <Grid xs={12} lg={3} item>
              <Input
                name="storageNumber"
                label={t("customer-storage-list.storage")}
                value={filter.storageNumber}
                onChange={onFilterChange}
              />
            </Grid>
              <Grid xs={12} lg={3} item>
                <Select
                  name="metalType"
                  label={t("customer-storage-list.metal-type")}
                  value={filter.metalType}
                  onChange={onFilterChange}
                  options={[
                    { key: "0", value: t("customer-storage-list.select") },
                    { key: "1", value: t("metals.AU") },
                    { key: "2", value: t("metals.AG") },
                    { key: "3", value: t("metals.PT") },
                    { key: "4", value: t("metals.PL") },
                  ]}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        <DataTable
          hideTitle={true}
          entityName={"msdyn_warehouse"}
          canAdd={false}
          canEdit={false}
          data={filteredList}
          showPageSizeSelector={true}
          columns={[
            {
              name: "customerName",
              caption: t("customer-storage-list.customer"),
            },
            {
              name: "metalName",
              caption: t("customer-storage-list.metal-type"),
              cellRenderType: CELL_RENDER_TYPE.METAL_CELL,
            },
            {
              name: "storageNumber",
              caption: t("customer-storage-list.storage"),
            },
            {
              name: "arades_verfuegbare_menge",
              caption: t("customer-storage-list.weight"),
              alignment: "right",
              dataType: "number", // For correct sorting
              format: ({ value }) => localeFormat(value, { minimumFractionDigits: 4 }) + " g"
            },
            {
              name: "totalPrice",
              caption: t("customer-storage-list.storage-value"),
              alignment: "right",
              dataType: "number", // For correct sorting
              format: ({ value }) => localeFormat(value, { minimumFractionDigits: 2, currency: "EUR" })
            },
            {
              name: "storageDescription",
              caption: t("customer-storage-list.description"),
              width: "320px", // Prevent too large cells if the customer entered a long description
              wordWrapEnabled: true, // Wrap if description is too long instead of truncating with "..."
            },
            {
              name: "storageStatus",
              caption: t("customer-storage-list.status"),
            },
            {
              name: "createDate",
              caption: t("customer-storage-list.create-date"),
              dataType: "date",
              format: ({ value }) => dateString(value),
            },
          ]}
        ></DataTable>
      </div>
    </LoadingOverlay>
  );
};
export default CustomerStorageList;
