import React from "react";
import styled from "@emotion/styled";
import { Box, Button, Typography } from "@mui/material";

const AreaContainer = styled.div`
  position: relative;
  border-radius: 8px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  & .form-area {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
`;

function HideAreaHandler({
  children,
  showArea = false,
  handleShowArea,
  message,
  btnText,
}) {
  return (
    <AreaContainer
      style={{
        userSelect: !showArea ? "none" : "initial",
        borderWidth: !showArea ? "1px" : "0",
        minHeight: "200px", // Make container look better while loading
      }}
    >
      <Box sx={{ filter: !showArea ? "blur(8px)" : "initial" }}>
        {children}
      </Box>
      {!showArea && (
        <Box className="form-area">
          <Typography mb={2} variant="subtitle2" component="h5">
            {message}
          </Typography>

          <Button
            className="btn-center-text btn-color-blue"
            onClick={handleShowArea}
            variant="contained"
            sx={{ minWidth: "210px" }}
          >
            {btnText}
          </Button>
        </Box>
      )}
    </AreaContainer>
  );
}

export default HideAreaHandler;
