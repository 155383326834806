import { Box, Grid, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import Select from "../common/inputs/select";
import { FormStepperStructure } from "../common";
import { useDispatch, useSelector } from "react-redux";
import {
  setLegalGuardianStatus,
  legalGuardianStatusFields,
} from "../../redux/slices/underage/underage.slice";
import { useTranslation } from "react-i18next";
import { useIsScreenSizeDesktop } from "../../core/utils/responsive";

function LegalGuardianStatusStep({ handleCurrentStep, currentStep }) {
  const { t } = useTranslation();
  const isDesktop = useIsScreenSizeDesktop();
  const dispatch = useDispatch();

  const fields = useSelector(legalGuardianStatusFields);
  const [legalStatus, setLegalStatus] = useState(fields.legalStatus);

  const submitForm = () => {
    dispatch(setLegalGuardianStatus({ ...fields, legalStatus: legalStatus }));
    if (legalStatus == "only") handleCurrentStep(currentStep + 3);
    else handleCurrentStep("next");
  };

  return (
    <>
      <Box
        sm={12}
        sx={{
          margin: "0 auto",
          width: "100%",
          borderRadius: "8px",
          backgroundColor: "#E3F2FD",
          padding: 10,
          marginBottom: 10,
        }}
      >
        <Typography
          mb="13px"
          as="div"
          sx={{
            fontSize: "16px",
          }}
        >
          {t("legal-guardian-status.instructions.0")} <br></br>
          {t("legal-guardian-status.instructions.1")} <br></br>
          {t("legal-guardian-status.instructions.2")} <br></br>
          {t("legal-guardian-status.instructions.3")} <br></br>
          {t("legal-guardian-status.instructions.4")}
        </Typography>
      </Box>
      <Typography
        mt="10px"
        mb="50px"
        as="div"
        sx={{
          fontSize: "16px",
        }}
      >
        {t("legal-guardian-status.question-legal-status")}
      </Typography>

      <Box sx={{ maxWidth: "500px", margin: "0 auto" }}>
        <Grid spacing={8} container direction="column">
          <Grid xs={12} lg={6} item>
            <Select
              value={legalStatus}
              name={"legalStatus"}
              options={[
                {
                  key: "only",
                  value: isDesktop ? t("legal-guardian-status.sole-legal-guardian") : t("legal-guardian-status.sole-legal-guardian-short")
                },
                {
                  key: "partner",
                  value: isDesktop ? t("legal-guardian-status.other-legal-guardian") : t("legal-guardian-status.other-legal-guardian-short"),
                },
              ]}
              label={t("legal-guardian-status.legal-status-label")}
              onChange={(e) => setLegalStatus(e.target.value)}
            />
          </Grid>
        </Grid>
      </Box>
      <FormStepperStructure.Footer>
        <Button
          onClick={submitForm}
          fullWidth
          sx={{
            backgroundColor: "info.main",
            maxWidth: "192px",
            marginTop: "30px",
          }}
          variant="contained"
        >
          {t("buttons.next")}
        </Button>
      </FormStepperStructure.Footer>
    </>
  );
}

export default LegalGuardianStatusStep;
