import React from 'react';
import {Typography} from '@mui/material'


function DiscountAmount({price,discount=null,sx,discountPercentage=null,variantBeforeDiscount,variantAfterDiscount,variantDiscount}) {
  
  const variantCalculate=Boolean(discountPercentage) ? variantAfterDiscount : variantBeforeDiscount;

  return (
   <>
    <Typography sx={{
      textDecoration:Boolean(discountPercentage)?'line-through':'initial',
      color:Boolean(discountPercentage)?'rgba(0, 0, 0, 0.57)':'rgba(0, 0, 0, 0.87)',
      ...sx
    }} variant={variantCalculate}>{price}</Typography>
    {Boolean(discountPercentage) && <Typography variant={variantDiscount}>{discount}</Typography>}
   </>
  )
}

export default DiscountAmount;
