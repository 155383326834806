import {
  Box,
  Grid,
  FormControlLabel,
  Radio,
  Button,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { MUICheckBoxGroup, MUICheckBox } from "../../common";

import { FormStepperStructure, LoadingOverlay } from "../../common";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getSubStorageListAction } from "../../../redux/slices/powerOfAttorney";
import { useDispatch, useSelector } from "react-redux";
import { ERROR_CONTACT_US } from "../../../core/constants";
import {
  chooseStorageStep,
  setFinalStorageAndSubStorages,
  chooseFinalStorageAndSubStoragesStep,
  subStorageList,
  globalData,
  resetError,
} from "../../../redux/slices/powerOfAttorney/powerOfAttorney.slice";
import { useSnackbar } from "notistack";

function SelectFinalStorageAndSubstoragesStep({ handleCurrentStep }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const requiredMsg = "validation.required";
  const fields = useSelector(chooseFinalStorageAndSubStoragesStep);
  const selectedSorage = useSelector(chooseStorageStep).storage;
  const subStorageListFields = useSelector(subStorageList);
  const [defaultSubStorages, setDefaultSubStorages] = useState(
    fields?.subStorages
  );
  const globalFields = useSelector(globalData);

  useEffect(() => {
    if (selectedSorage) {
      dispatch(
        getSubStorageListAction({
          storageId: selectedSorage,
          fetchAmount: false,
        })
      );
    }
  }, []);
  useEffect(() => {
    setLoading(globalFields.loading);
  }, [globalFields.loading]);

  const submitForm = (data) => {
    dispatch(setFinalStorageAndSubStorages(data));
    handleCurrentStep("next");
  };

  useEffect(() => {
    if (globalFields.error != null) {
      enqueueSnackbar(globalFields.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      setLoading(false);
      dispatch(resetError());
    }
  }, [globalFields.error]);

  const schema = yup.object().shape({
    // storage:yup.string().required(requiredMsg),
  });

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      ...fields,
    },
    resolver: yupResolver(schema),
  });
  const handleSelectAllSubStorages = () => {
    const subStorages = subStorageListFields.map((item) => item.key);
    setValue("subStorages", [...subStorages]);
    setDefaultSubStorages([...subStorages]);
  };
  return (
    <LoadingOverlay show={loading}>
      <Box
        sx={{
          margin: "0 auto",
          minHeight: "calc(100vh - 250px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box sx={{ margin: "0 auto" }}>
          <Grid spacing={8} container direction="column">
            <Grid xs={12} lg={6} item>
              <Typography mb="20px" variant="body2">
                Please select the main and substorages that you want to give the
                power of attorney for them
              </Typography>
              <MUICheckBox
                control={control}
                error={Boolean(errors.mainStorage)}
                helperText={errors.mainStorage?.message}
                name="mainStorage"
                label="Hauptlagerplatz" //"Main Storage"
              />
            </Grid>
            <Grid xs={12} lg={6} item>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  mb="20px"
                  sx={{ color: "rgba(0, 0, 0, 0.38)" }}
                  variant="subtitle2"
                >
                  Select substorages
                </Typography>

                <Button
                  onClick={handleSelectAllSubStorages}
                  sx={{ color: "info.main" }}
                  variant="link"
                >
                  Select All
                </Button>
              </Box>

              <Box>
                <MUICheckBoxGroup
                  options={subStorageListFields}
                  control={control}
                  error={Boolean(errors.subStorages)}
                  helperText={errors.subStorages?.message}
                  name="subStorages"
                  defaultValues={defaultSubStorages}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <FormStepperStructure.Footer>
          <Button
            onClick={handleSubmit(submitForm)}
            fullWidth
            sx={{ backgroundColor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            Weiter
            {/* Next */}
          </Button>
        </FormStepperStructure.Footer>
      </Box>
    </LoadingOverlay>
  );
}

export default SelectFinalStorageAndSubstoragesStep;
