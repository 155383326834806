import { useState, useEffect } from "react";

// Hook to determine if the screen size is at least "Desktop"
// Usage: const isDesktop = useIsScreenSizeDesktop();
export function useIsScreenSizeDesktop() {
  // Use the media query defined for "Desktop" (e.g., width of at least 1024px)
  const mediaQuery = "(min-width: 1024px)";

  // Initialize state: The initial value is based on the current screen size
  const [isDesktop, setIsDesktop] = useState(() => window.matchMedia(mediaQuery).matches);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery);

    // Event listener function to update the `isDesktop` state
    const handleChange = (event) => {
      setIsDesktop(event.matches);
    };

    // Add the event listener
    mediaQueryList.addEventListener("change", handleChange);

    // Cleanup: Remove the event listener to prevent memory leaks
    return () => {
      mediaQueryList.removeEventListener("change", handleChange);
    };
  }, [mediaQuery]); // Set the dependency to `mediaQuery` to ensure it rebinds when it changes

  return isDesktop;
}
