import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { FormStepperStructure, LoadingOverlay } from "../common";
import InputUpload from "../common/inputs/inputUpload";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../common/popups/confirmModal";
import { URL } from "../../core/constants";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { BASE_URL } from "../../core/apiconfig/environment";
import { useApi } from "../../core/hooks/useApi";
import { getUnderAgeDocumentListAction } from "../../redux/slices/underage";
import {
  underAgedocumnets,
  underAgeFullState,
  childDataStepFields,
  documentsStepData,
  setDocumentsStep,
  fullnameData,
} from "../../redux/slices/underage/underage.slice";

function UploadDocumnetsStep({ handleCurrentStep, contactid }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { post } = useApi();

  const MAXFILESIZE = 20000000;

  const documents = useSelector(underAgedocumnets);
  const underAgeData = useSelector(underAgeFullState);
  const underageInfo = useSelector(childDataStepFields);
  const documentsStepInfo = useSelector(documentsStepData);
  const contactId = contactid ? contactid : underageInfo.contactid;
  const fullname = useSelector(fullnameData);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getUnderAgeDocumentListAction(contactId));
  }, []);

  //parichehr move to redux in refactor
  const handleFileChange = async (e, documentId) => {
    if (!e.target.files || e.target.files.length == 0) return;

    if (e.target.files[0].size > MAXFILESIZE) {
      enqueueSnackbar(t("messages.max-file"), {
        variant: "error",
        autoHideDuration: 20000,
      });
    }

    dispatch(
      setDocumentsStep({
        ...documentsStepInfo.uploadedDouments,
        ["d" + documentId]: e.target.files[0].name,
      })
    );

    const formData = new FormData();
    formData.append("documentId", documentId);
    formData.append("contactId", contactId);
    formData.append("file", e.target.files[0]);
    setLoading(true);
    const result = await post(`${BASE_URL}upload/kontaktdokument`, formData);
    setLoading(false);
    if (result.status == 200)
      enqueueSnackbar("Update erfolgreich", {
        variant: "success",
        autoHideDuration: 3000,
      });
    else
      dispatch(
        setDocumentsStep({
          ...documentsStepInfo.uploadedDouments,
          ["d" + documentId]: null,
        })
      );
  };

  const submitForm = () => {
    setConfirmOpen(true);
  };

  useEffect(() => {
    setLoading(underAgeData.loading);
  }, [underAgeData.loading]);

  const formIsValid = () => {
    let result = true;
    for (let i = 0; i < documents?.length; i++) {
      if (
        documents[i]?.arades_erforderlich &&
        !documentsStepInfo.uploadedDouments[
          "d" + documents[i].arades_kontaktdokumentid
        ]
      )
        result = false;
    }
    return result;
  };

  return (
    <LoadingOverlay show={loading}>
      <div style={{ width: 0 }}>
        <ConfirmModal
          open={confirmOpen}
          title={"Super!"}
          showCloseButton={false}
          size="md"
          message={
            <div>
              Wir werden die Unterlagen prüfen und anschließend Kontakt zu Ihnen
              aufnehmen.<br></br>
              Lagerplätze können Sie einrichten, nachdem Sie von uns die
              Mitteilung über die erfolgte Freigabe erhalten haben.
            </div>
          }
          noImage={true}
          okTitle={"Fertig"}
          onOk={() => {
            navigate(URL.DASHBOARD);
            window.location.reload();
          }}
        ></ConfirmModal>
      </div>
      {fullname && (
        <Typography
          mb="20px"
          sx={{
            fontSize: "18px",
            fontWeight: "400",
          }}
        >
          {fullname}
        </Typography>
      )}

      <Box
        sx={{
          margin: "0 auto",
          minHeight: "calc(100vh - 250px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="subtitle2"
          mb="20px"
          sx={{
            fontSize: "16px",
            fontWeight: "400",
          }}
        >
          Bitte laden Sie folgende Unterlagen hoch:
        </Typography>
        <Typography
          variant="subtitle2"
          mb="50px"
          sx={{
            fontSize: "16px",
            fontWeight: "400",
          }}
        >
          <br></br>* Die maximale Upload-Größe beträgt 20MB
          <br></br>* Erlaubte Dateitypen: PDF, png, jpeg, jpg
        </Typography>

        {documents?.map((doc) => {
          return (
            <div className="col-12 col-md-6 mt-4 " style={{ width: "100%" }}>
              <InputUpload
                name={"d" + doc.arades_kontaktdokumentid}
                value={
                  documentsStepInfo.uploadedDouments[
                    "d" + doc.arades_kontaktdokumentid
                  ]
                }
                onChange={(e) =>
                  handleFileChange(e, doc.arades_kontaktdokumentid)
                }
                placeholder={
                  doc[
                    "_arades_dokument_value@OData.Community.Display.V1.FormattedValue"
                  ]
                }
                label={
                  doc[
                    "_arades_dokument_value@OData.Community.Display.V1.FormattedValue"
                  ]
                }
                //parichehr ask
                accept=".pdf,.jpg,.jpeg,.png,.svg"
              />
            </div>
          );
        })}
        <Box
          sm={12}
          sx={{
            margin: "0 auto",
            width: "100%",
            borderRadius: "8px",
            backgroundColor: "#E3F2FD",
            padding: 10,
            marginTop: 20,
          }}
        >
          <Typography
            mb="13px"
            as="div"
            sx={{
              fontSize: "16px",
            }}
          >
            Der Uploadvorgang kann eine gewisse Zeit in Anspruch nehmen. Bitte
            warten Sie ab, bis das jeweilige Dokument als hochgeladen
            gekennzeichnet ist.
          </Typography>
        </Box>

        <FormStepperStructure.Footer>
          <Button
            disabled={!formIsValid()}
            onClick={submitForm}
            fullWidth
            sx={{ backgroundColor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            {t("buttons.next")}
          </Button>
        </FormStepperStructure.Footer>
      </Box>
    </LoadingOverlay>
  );
}

export default UploadDocumnetsStep;
