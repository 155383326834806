import React, { useEffect, useState } from "react";
import { Box, FormControl, FormLabel, Button } from "@mui/material";
import { RadioListIcon, FormStepperStructure } from "../common";
import { useDispatch, useSelector } from "react-redux";
import { MetalPart } from "../../core/utils/business";
import { METAL_CHARS } from "../../core/constants";
import {
  selectStorageTypeFields,
  setSelectStorageType,
  storagesListWithSubstorages,
  exchangeFromStorage,
} from "../../redux/slices/exchange/exchange.slice";
import { useTranslation } from "react-i18next";

function SelectStorageTypeStep({ handleCurrentStep }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const storageTypeFields = useSelector(selectStorageTypeFields);
  const storageList = useSelector(storagesListWithSubstorages);
  const fromStorage = useSelector(exchangeFromStorage);
  const [storageType, setStorageType] = useState(storageTypeFields.storageType);

  const [radioList, setRadioList] = useState([
    {
      label: t("exchange.main-storage"),
      value: "mainStorage",
      disabled: true,
    },
    {
      label: t("exchange.sub-storage"),
      value: "subStorage",
      disabled: true,
    },
  ]);

  useEffect(() => {
    setCanTransferToStorageOption();
  }, []);

  const setCanTransferToStorageOption = () => {
    let result = { ...storageTypeFields };
    result.canSelectStorage =
      storageList?.filter(
        (x) =>
          !x.pending &&
          !x.arades_uebergeordneter_lagerplatz &&
          x.msdyn_warehouseid != fromStorage.msdyn_warehouseid &&
          ((MetalPart(fromStorage.msdyn_name) == METAL_CHARS.GOLD &&
            MetalPart(x.msdyn_name) == METAL_CHARS.SILVER) ||
            (MetalPart(fromStorage.msdyn_name) == METAL_CHARS.SILVER &&
              MetalPart(x.msdyn_name) == METAL_CHARS.GOLD))
      ).length > 0;

    for (let i = 0; i < storageList?.length; i++) {
      let item = storageList[i];
      if (
        item.subStorageList?.filter(
          (x) =>
            !x.pending &&
            x.msdyn_warehouseid != fromStorage.msdyn_warehouseid &&
            ((MetalPart(fromStorage.msdyn_name) == METAL_CHARS.GOLD &&
              MetalPart(x.msdyn_name) == METAL_CHARS.SILVER) ||
              (MetalPart(fromStorage.msdyn_name) == METAL_CHARS.SILVER &&
                MetalPart(x.msdyn_name) == METAL_CHARS.GOLD))
        ).length > 0
      ) {
        result.canSelectSubstorage = true;
        break;
      }
    }

    if (!result.canSelectStorage && result.canSelectSubstorage)
      result.storageType = "subStorage";

    let myRadioList = [...radioList];
    myRadioList[0].disabled = !result.canSelectStorage;
    myRadioList[1].disabled = !result.canSelectSubstorage;

    setRadioList(myRadioList);

    dispatch(setSelectStorageType(result));
  };

  const submitForm = () => {
    dispatch(
      setSelectStorageType({ ...storageTypeFields, storageType: storageType })
    );
    handleCurrentStep("next");
  };
  return (
    <>
      <Box
        sx={{
          margin: "0 auto",
          minHeight: "calc(100vh - 250px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "50%" } }}>
          <FormControl sx={{ minWidth: "100%" }}>
            <FormLabel sx={{ fontSize: "14px", mb: "20px" }} id="radio-group">
              {t("exchange.select-storage-type")}
            </FormLabel>
            <RadioListIcon
              currentValue={storageType}
              handleSetCurrentValue={(value) => {
                setStorageType(value);
              }}
              data={radioList}
            />
          </FormControl>
        </Box>

        <FormStepperStructure.Footer>
          <Button
            onClick={submitForm}
            fullWidth
            sx={{ backgroundColor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            {t("buttons.next")}
          </Button>
        </FormStepperStructure.Footer>
      </Box>
    </>
  );
}

export default SelectStorageTypeStep;
