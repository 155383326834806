import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getStorageBankAccountFunc = async (storageId, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  const result = await axios.get(`${BASE_URL}data/warehouses/${storageId}/`, config);
  if (result?.data?.status !== 200 || !result?.data?.data)
    throw Error("Bankkonto konnte nicht gefunden werden"); //("storage bank account not found");

  return result.data.data.arades_referenzkonto;
};

export default getStorageBankAccountFunc;
