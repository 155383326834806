import axios from "axios";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";
import { BASE_URL } from "../../../core/apiconfig/environment";

const getVermittlerDataFunc = async (vermittlerId, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };
  
  let result = await axios.get(`${BASE_URL}data/accounts/${vermittlerId}/`, config);
  if (!result?.data?.status === 200 || !result?.data?.data) {
    throw Error("Vermittler konnte nicht geladen werden"); //("vermittler not fetched");
  }

  return result.data.data;
};
export default getVermittlerDataFunc;
