import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { ActivationCodeHandler } from "..";
import { useTranslation } from "react-i18next";
import { MobileNumberHide } from "../../../core/utils/convert";

function Verification({
  number = "+49 9** ** ** 09",
  resendCode,
  handleNext,
  showNext = true,
  activationCode,
  setActivationCode,
  verified = false,
}) {
  const [canNext, setCanNext] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      {verified ? (
        <Box
          sx={{
            color: "#4fb557",
            padding: "40px",
            textAlign: "center",
            backgroundColor: "#f5f4f5",
            fontWeight: "bold",
            border: "1px solid #ccc",
            borderRadius: "8px",
            maxWidth: "400px",
            margin: "20px auto",
          }}
        >
          { t("messages.verification-successful") }
        </Box>
      ) : (
        <>
          <Typography
            sx={{
              color: "rgba(95, 95, 95, 1)",
              textAlign: "center",
              textWrap: "balance", /* Make it look better on mobile */
            }}
            variant="subtitle1"
          >
            {t("components.verification.we-send-code")}
            <br /> <b>{MobileNumberHide(number)}</b>.{" "}
            {t("components.verification.please-enter-the-code")}
          </Typography>
          <ActivationCodeHandler
            resendCode={resendCode}
            time={60}
            loading={false}
            activationCode={activationCode}
            setActivationCode={(data) => {
              setActivationCode(data);
              setCanNext(true);
            }}
            onEnter={() => { if (handleNext) handleNext(); }}
          />
        </>
      )}

      {(handleNext && showNext &&
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            mt: "20px",
          }}
        >
          <Button
            disabled={!verified && !canNext}
            onClick={handleNext}
            fullWidth
            sx={{ backgroundColor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            {t("buttons.next")}
          </Button>
        </Box>
      )}
    </div>
  );
}

export default Verification;
