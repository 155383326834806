import React, { useEffect } from "react";
import { Button, Grid, Box, Divider, Typography } from "@mui/material";
import {
  sellFullState,
  sellGlobalState,
  resetError,
} from "../../redux/slices/sell/sell.slice";
import { FormStepperStructure } from "../common";
import { ERROR_CONTACT_US } from "../../core/constants";
import { useDispatch, useSelector } from "react-redux";
import { verificationSendSMSCodeAction } from "../../redux/slices/sell";
import { useSnackbar } from "notistack";
import { localeFormat } from "../../core/utils/convert";
import {
  globalsRatiorData,
  globalsMetalPrices,
  customerLoyaltyData,
} from "../../redux/slices/globals/globals.slice";
import { MetalPart } from "../../core/utils/business";
import { useTranslation } from "react-i18next";

function SummaryStep({ handleCurrentStep }) {
  const sellData = useSelector(sellFullState);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const ratiorData = useSelector(globalsRatiorData);
  const metalPrices = useSelector(globalsMetalPrices);
  const sellGlobalData = useSelector(sellGlobalState);
  const customerLoyaltyInfo = useSelector(customerLoyaltyData);

  const metalPart = MetalPart(sellData.sellSource.fromStorage.msdyn_name);
  const unitPrice =
    metalPart == "AU"
      ? metalPrices.goldPrice.amount
      : metalPart == "AG"
      ? metalPrices.silverPrice.amount
      : metalPart == "PT"
      ? metalPrices.platinPrice.amount
      : metalPart == "PL"
      ? metalPrices.palladiumPrice.amount
      : 0;
  const feePercent = customerLoyaltyInfo.hasLoyalty
    ? customerLoyaltyInfo.feePercent
    : ratiorData.sellFee;
  const fee =
    (feePercent * sellData.enterAmount.amountPerGram * unitPrice) / 100;

  const onSubmit = (data) => {
    console.log(data);
    handleCurrentStep("next");
    sendSMS();
  };

  useEffect(() => {
    if (sellGlobalData.error !== null) {
      enqueueSnackbar(sellGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [sellGlobalData.error]);

  const sendSMS = () => {
    if (!sellData.verificationSteps.verification) {
      const currentAccountPhoneNumber = localStorage.getItem("mobileNumber");
      dispatch(
        verificationSendSMSCodeAction({
          mobileNumber: currentAccountPhoneNumber,
        })
      );
    }
  };
  const boxStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  };
  const headrStyle = {
    color: "rgba(0, 0, 0, 0.38)",
    fontSize: 14,
  };
  const infoStyle = {
    color: "rgba(0, 0, 0, 0.87);",
    fontSize: 16,
  };

  return (
    <Box>
      <Grid container spacing={8}>
        <Grid xs={12} item>
          <Divider
            sx={{
              marginTop: "20px",
              color: "rgba(0, 0, 0, 0.38)",
              "&::before": {
                width: 0,
              },
            }}
            textAlign="left"
          >
            {t("sell.from")}
          </Divider>
        </Grid>
        <Grid xs={12} item>
          <Box sx={{ ...boxStyle }}>
            <Typography sx={{ ...headrStyle }}>
              {t("sell.storage-number")}
            </Typography>
            <Typography sx={{ ...infoStyle }}>
              {sellData.sellSource.fromStorage.msdyn_name}
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} item>
          <Divider
            sx={{
              marginTop: "20px",
              color: "rgba(0, 0, 0, 0.38)",
              "&::before": {
                width: 0,
              },
            }}
            textAlign="left"
          >
            {t("sell.to")}
          </Divider>
        </Grid>
        <Grid xs={12} md={4} item>
          <Box sx={{ ...boxStyle }}>
            <Typography sx={{ ...headrStyle }}>
              {t("bank-account.owner")}
            </Typography>
            <Typography sx={{ ...infoStyle }}>
              {sellData.storageBankAccount.bankAccountInfo.arades_kontoinhaber}
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} md={4} item>
          <Box sx={{ ...boxStyle }}>
            <Typography sx={{ ...headrStyle }}>
              {" "}
              {t("bank-account.bank")}
            </Typography>
            <Typography sx={{ ...infoStyle }}>
              {sellData.storageBankAccount.bankAccountInfo.arades_bank}
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} md={4} item>
          <Box sx={{ ...boxStyle }}>
            <Typography sx={{ ...headrStyle }}>IBAN</Typography>
            <Typography sx={{ ...infoStyle }}>
              {sellData.storageBankAccount.bankAccountInfo.arades_iban}
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} md={4} item>
          <Box sx={{ ...boxStyle }}>
            <Typography sx={{ ...headrStyle }}>BIC</Typography>
            <Typography sx={{ ...infoStyle }}>
              {sellData.storageBankAccount.bankAccountInfo.arades_bic}
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} md={4} item>
          <Box sx={{ ...boxStyle }}>
            <Typography sx={{ ...headrStyle }}>
              {t("bank-account.account-number")}
            </Typography>
            <Typography sx={{ ...infoStyle }}>
              {sellData.storageBankAccount.bankAccountInfo.arades_kontonummer}
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} md={4} item>
          <Box sx={{ ...boxStyle }}>
            <Typography sx={{ ...headrStyle }}>
              {t("bank-account.bank-code")}
            </Typography>
            <Typography sx={{ ...infoStyle }}>
              {sellData.storageBankAccount.bankAccountInfo.arades_bankleitzahl}
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} item>
          <Divider
            sx={{
              marginTop: "20px",
              color: "rgba(0, 0, 0, 0.38)",
              "&::before": {
                width: 0,
              },
            }}
            textAlign="left"
          >
            {t("sell.calculations")}
          </Divider>
        </Grid>
        <Grid xs={12} md={4} item>
          <Box sx={{ ...boxStyle }}>
            <Typography sx={{ ...headrStyle }}>
              {t("sell.total-amount")}
            </Typography>
            <Typography sx={{ ...infoStyle }}>
              {localeFormat(sellData.enterAmount.amountPerGram * 1, { minimumFractionDigits: 4 })} &nbsp;{" "}
              {t("sell.gram")}
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} md={4} item>
          <Box sx={{ ...boxStyle }}>
            <Typography sx={{ ...headrStyle }}>
              {t("sell.unit-price")}
            </Typography>
            <Typography sx={{ ...infoStyle }}>{localeFormat(unitPrice, { minimumFractionDigits: 2, currency: "EUR" })}</Typography>
          </Box>
        </Grid>
        <Grid xs={12} md={4} item>
          <Box sx={{ ...boxStyle }}>
            <Typography sx={{ ...headrStyle }}>
              {t("sell.total-price")}
            </Typography>
            <Typography sx={{ ...infoStyle }}>
              {localeFormat(sellData.enterAmount.amountPerGram * unitPrice, { minimumFractionDigits: 2, currency: "EUR" })}
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} md={4} item>
          <Box sx={{ ...boxStyle }}>
            <Typography sx={{ ...headrStyle }}>
              {t("sell.standard-fee")}
            </Typography>
            <Typography
              sx={{
                ...infoStyle,
                textDecoration: customerLoyaltyInfo.hasLoyalty
                  ? "line-through"
                  : "initial",
              }}
            >
              {ratiorData.sellFee + " %"}
            </Typography>
          </Box>
        </Grid>
        {customerLoyaltyInfo.hasLoyalty && (
          <Grid xs={12} md={4} item>
            <Box sx={{ ...boxStyle }}>
              <Typography sx={{ ...headrStyle }}>Ihre Gebühr </Typography>
              <Typography sx={{ ...infoStyle }}>
                {customerLoyaltyInfo.feePercent_formatted +
                  ` % (${t("sell.loyalty-month")})`}
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid xs={12} md={4} item>
          <Box sx={{ ...boxStyle }}>
            <Typography sx={{ ...headrStyle }}>
              {t("sell.fee-amount")}
            </Typography>
            <Typography sx={{ ...infoStyle }}>{localeFormat(fee, { minimumFractionDigits: 2, currency: "EUR" })}</Typography>
          </Box>
        </Grid>
        <Grid xs={12} md={4} item>
          <Box sx={{ ...boxStyle }}>
            <Typography sx={{ ...headrStyle }}>
              {t("sell.remaining")}
            </Typography>
            <Typography sx={{ ...infoStyle }}>
              {localeFormat(sellData.enterAmount.amountPerGram * unitPrice - fee, { minimumFractionDigits: 2, currency: "EUR" })}
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} sx={{ marginTop: "20px" }} item>
          <Typography sx={{ ...infoStyle }}>
            {t("sell.summary-description")}
          </Typography>
        </Grid>
      </Grid>
      <FormStepperStructure.Footer>
        <Button
          onClick={onSubmit}
          fullWidth
          sx={{ backgroundColor: "info.main", maxWidth: "192px" }}
          variant="contained"
        >
          {t("buttons.next")}
        </Button>
      </FormStepperStructure.Footer>
    </Box>
  );
}

export default SummaryStep;
