import React, { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import Input from "../common/inputs/input";
import { FormStepperStructure, LoadingOverlay } from "../common";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import MdArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  sellGlobalState,
  sellStorageBankAccount,
  setEnterAmount,
  sellEnterAmount,
  sellFromStorage,
  resetActionStatus,
  resetAll,
} from "../../redux/slices/sell/sell.slice";
import {
  GermanFloatToFloat,
  localeFormat,
} from "../../core/utils/convert";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { URL } from "../../core/constants";
import { getStorageAmountAction } from "../../redux/slices/sell";

function EnterAmountStep({ handleCurrentStep, currentStep }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const detailStorageId = useParams().storageid;

  const sellGlobalData = useSelector(sellGlobalState);
  const amountFields = useSelector(sellEnterAmount);
  const fromStorage = useSelector(sellFromStorage);
  const storagebankAccount = useSelector(sellStorageBankAccount);
  const [loading, setLoading] = useState(false);

  const requiredMsg = t("validation.required");

  const submitForm = (data) => {
    let enteredAmount = GermanFloatToFloat(data.amount);
    if (enteredAmount > fromStorage.arades_verfuegbare_menge) {
      enqueueSnackbar(t("validation.above-max"), {
        variant: "error",
        autoHideDuration: 20000,
      });
      return;
    }
    dispatch(
      setEnterAmount({
        amount: data.amount,
        amountPerGram: enteredAmount,
      })
    );
    handleCurrentStep("next");
  };
  const handleCancel = () => {
    dispatch(resetAll());
    navigate(`${URL.STORAGE}/${detailStorageId}`);
  };
  const onBack = () => {
    if (storagebankAccount.selectedBankAccount != "other") {
      handleCurrentStep(currentStep - 2);
    } else {
      handleCurrentStep("prev");
    }
  };

  useEffect(() => {
    if (sellGlobalData.actionStatus) {
      setValue(
        "amount",
        localeFormat(amountFields.maxAmountPerGram, { minimumFractionDigits: 4 })
      );
      dispatch(resetActionStatus());
    }
  }, [sellGlobalData.actionStatus]);

  useEffect(() => {
    setLoading(sellGlobalData.loading);
  }, [sellGlobalData.loading]);

  const schema = yup.object().shape({
    amount: yup
      .string()
      .test(
        "is-decimal",
        t("validation.none-zero-decimal"),
        (value) =>
          (value + "").match(/^-?\d{1,3}(?:\.\d{3})*(?:,\d+)?$/) ||
          (value + "").match(/^(?:[1-9]\d*|0(?!(?:\.0+)?$))?(?:\.\d+)?$/)
      )
      .required(requiredMsg)
      .notOneOf([0, "0", "0,00000"], t("validation.none-zero-decimal"))
      .nullable(),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      ...amountFields,
      agreed: false,
    },
    resolver: yupResolver(schema),
  });
  return (
    <LoadingOverlay show={loading}>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <IconButton
            sx={{ fontSize: "15px", color: "#000" }}
            aria-label="back"
            onClick={() => onBack()}
          >
            <MdArrowBackIosNew />
          </IconButton>
          <Typography sx={{ fontWeight: 500 }} variant="h5" component="h5">
            {t("sell.enter-amount")}
          </Typography>

          <FormStepperStructure.StepRightButton
            size="small"
            onClick={handleCancel}
            variant="outlined"
            startIcon={<CloseIcon />}
          >
            {t("buttons.cancel")}
          </FormStepperStructure.StepRightButton>
        </Box>
        <Box
          sx={{
            margin: "0 auto",
            minHeight: "calc(100vh - 250px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Box sx={{ minWidth: "50%", margin: "0 auto" }}>
            <Grid spacing={1} container direction="row">
              <Grid xs={12} lg={8} item>
                <Input
                  control={control}
                  error={Boolean(errors.amount)}
                  helperText={
                    errors.amount?.message ||
                    `${t("sell.max-available-1")} ${localeFormat(fromStorage.arades_verfuegbare_menge, { minimumFractionDigits: 4 })} (g)${t("sell.max-available-2")}`
                  }
                  name="amount"
                  label={`${t("sell.enter-amount")} (g)`}
                />
              </Grid>
              <Grid xs={12} lg={1} item>
                <Button
                  onClick={() =>
                    dispatch(
                      getStorageAmountAction(fromStorage.msdyn_warehouseid)
                    )
                  }
                  sx={{
                    maxWidth: "192px",
                    height: 54,
                    marginLeft: 5,
                  }}
                  variant="outlined"
                >
                  {t("sell.max")}
                </Button>
              </Grid>
            </Grid>
          </Box>

          <FormStepperStructure.Footer>
            <Button
              onClick={handleSubmit(submitForm)}
              fullWidth
              sx={{ backgroundColor: "info.main", maxWidth: "192px" }}
              variant="contained"
            >
              {t("buttons.next")}
            </Button>
          </FormStepperStructure.Footer>
        </Box>
      </Box>
    </LoadingOverlay>
  );
}

export default EnterAmountStep;
