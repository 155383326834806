import React, { useState, useEffect } from "react";
import { useApi } from "../../../core/hooks/useApi";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { useSnackbar } from "notistack";
import PhoneInput from "../../common/inputs/phoneInput";
import Datepicker from "../../common/inputs/datepicker";
import Button from "../../common/buttons/button";
import { useNavigate } from "react-router-dom";
import back from "../../../assets/images/back.svg";
import "./registration.scss";
import ConfirmModal from "../../common/popups/confirmModal";
import { longDateTo10 } from "../../../core/utils/convert";
import { URL, ERROR_CONTACT_US } from "../../../core/constants";
import { Box } from "@mui/material";
import {
  CONTACT_BIRTHDATE,
  TOKEN_NAME,
  MOBILE_VERIFIED,
  MOBILE_NUMBER,
} from "../../../core/apiconfig/constantApi";
import { useRegisterState } from "../../../context/register/RegisterState";
import { resetCache } from "../../../core/utils/cachManager";
import { calculateAge } from "../../../core/utils/commonfuncs";
import eighteen from "../../../assets/images/eighteen.svg";
import LoadingOverlay from "../../common/LoadingOverlay/LoadingOverlay";
import { useTranslation } from "react-i18next";
import { Verification } from "../../common";
import { useIsScreenSizeDesktop } from "../../../core/utils/responsive";

const MobileVerification = () => {
  const { post } = useApi();
  const { t } = useTranslation();
  const isDesktop = useIsScreenSizeDesktop();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [activationCode, setActivationCode] = useState();
  const [loading, setLoading] = useState(false);
  const [showMobile, setShowMobile] = useState(true);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { personalInfo, personalInfoAction } = useRegisterState();
  const [mobilephone, setMobilephone] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [token, setToken] = useState();

  useEffect(() => {
    if (localStorage.getItem(CONTACT_BIRTHDATE))
      setBirthDate(localStorage.getItem(CONTACT_BIRTHDATE));
  }, []);

  React.useEffect(() => {
    if (personalInfo && personalInfo.mobileNumber)
      setMobilephone(personalInfo.mobileNumber);
  }, []);

  const handleChange = (event) => {
    const mn = event.target.value;
    personalInfoAction({ ...personalInfo, ["mobileNumber"]: mn });
    setMobilephone(mn);
  };

  const smsSent = async () => {
    let smsObj = {
      birthdate: longDateTo10(birthDate),
      mobileNumber: mobilephone,
    };

    setLoading(true);
    const smsResult = await post(`${BASE_URL}verify/sms/send`, smsObj);
    setLoading(false);
    if (smsResult && smsResult.status != 200 && smsResult.status != 201) {
      let error = "";
      if (smsResult.data && smsResult.data.length > 0)
        error = smsResult.data[0].msg;
      else error = smsResult.statusText;
      enqueueSnackbar(error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      return false;
    } else if (
      smsResult &&
      (smsResult.status == 200 || smsResult.status == 201)
    )
      return true;
    else return false;
  };
  const sendCode = async () => {
    let age = calculateAge(birthDate);
    if (age < 18) setConfirmOpen(true);
    else {
      setActivationCode("");
      let success = await smsSent();
      if (success) {
        setShowMobile(false);
      }
    }
  };

  const codeVerified = async () => {
    let smsObj = {
      code: activationCode,
    };

    let smsResult = await post(`${BASE_URL}verify/sms`, smsObj);
    if (
      smsResult &&
      smsResult.status != 200 &&
      smsResult.status != 201 &&
      smsResult.data &&
      smsResult.data.length > 0
    ) {
      enqueueSnackbar(smsResult.data[0].msg, {
        variant: "error",
        autoHideDuration: 20000,
      });
      return false;
    } else if (
      smsResult &&
      (smsResult.status == 200 || smsResult.status == 201)
    ) {
      localStorage.setItem(MOBILE_VERIFIED, "true");
      localStorage.setItem(MOBILE_NUMBER, mobilephone);
      localStorage.setItem(TOKEN_NAME, smsResult.data.token);
      setToken(smsResult.data.token);
      return true;
    } else return false;
  };
  const handleSubmit = async () => {
    setLoading(true);
    let success = codeVerified();
    setLoading(false);
    if (success) navigate(URL.ADDRESSVERIFICATION);
  };

  const formIsValid = () => {
    return mobilephone.length >= 12 && birthDate;
  };

  return (
    <LoadingOverlay show={loading}>
      <ConfirmModal
        open={confirmOpen}
        title={t("components.registration-verification.underage-dialog.title")}
        imageSrc={eighteen}
        showCloseButton={true}
        message={t("components.registration-verification.underage-dialog.message")}
        okTitle={t("layout.logout")}
        onOk={() => {
          resetCache();
          navigate(URL.LOGIN);
          window.location.reload();
        }}
        onClose={() => setConfirmOpen(false)}
      ></ConfirmModal>
      <div className="register-title">{ t("components.registration-verification.title") }</div>
      <div className="step">{ t("components.registration-verification.subtitle") }</div>
      <div className="register-box-wrapper">
        <div className="flex-column register-box">
          <div className="d-flex flex-column">
            {showMobile && (
              <>
                <div className="col-12 form-item padding-50">
                  <span className="span-2fa">
                    {t("components.registration-verification.legitimation-description")}
                  </span>
                </div>
                <div className="col-12 form-item padding-20">
                  <Datepicker
                    maxDate={Date.now()}
                    name={"birthDate"}
                    value={birthDate}
                    onChange={(e) => {
                      setBirthDate(e.target.value);
                    }}
                    label={t("personal-info.birth-date") + "*"}
                    required={false}
                  />
                </div>

                <div className="col-12 form-item padding-20">
                  <PhoneInput
                    country="de"
                    name={"mobilephone"}
                    value={mobilephone}
                    onChange={handleChange}
                    onEnter={() => { if (formIsValid()) { sendCode(); } }}
                    label={t("personal-info.mobile") + "*"}
                    className="phone-input-small"
                  />
                </div>
              </>
            )}

            {!showMobile && (
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Verification
                  number={mobilephone}
                  resendCode={sendCode}
                  activationCode={activationCode}
                  setActivationCode={setActivationCode}
                  verified={false}
                  handleNext={handleSubmit}
                  showNext={false}
                />
              </Box>
            )}
          </div>
        </div>
        <div className="div-btn-register">
          {showMobile && (
            <div
              className="div-btn-register"
              style={{ paddingRight: isDesktop ? 128 : 0, paddingTop: 80 }}
            >
              <div
                className="link-btn"
                onClick={() => {
                  resetCache();
                  navigate(URL.LOGIN);
                }}
              >
                <span>
                  <img src={back} className="imgback"></img>{" "}
                  { t("components.registration-verification.back-to-sign-in") }
                </span>
              </div>
              <Button
                disabled={!formIsValid()}
                className="btn-action"
                onClick={sendCode}
              >
                { t("components.registration-verification.send-code") }
              </Button>
            </div>
          )}

          {!showMobile && (
            <div
              className="div-btn-register"
              style={{ paddingRight: isDesktop ? 128 : 0, paddingTop: 25 }}
            >
              <div
                className="link-btn"
                onClick={() => { setShowMobile(true); }}
              >
                <span>
                  <img src={back} className="imgback"></img>{" "}
                  { t("components.registration-verification.edit-number") }
                </span>
              </div>
              <Button
                disabled={!mobilephone || activationCode.length < 4}
                className={`btn-action ${
                  !mobilephone || activationCode.length < 4
                    ? "disabled"
                    : "enabled"
                }`}
                onClick={handleSubmit}
              >
                { t("components.registration-verification.continue") }
              </Button>
            </div>
          )}
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default MobileVerification;
