import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMetalPriceFunc } from "../../sharedFunctions";
import { localeFormat } from "../../../core/utils/convert";
import { METAL_CHARS } from "../../../core/constants";

const getGoldSilverRatiosAction = createAsyncThunk(
  "vermittlerDashboard/getGoldSilverRatios",
  async (dataParams, { getState }) => {
    let prices = { gold: 0, silver: 0 };

    let priceResult = {};

    priceResult = await getMetalPriceFunc(
      { productName: METAL_CHARS.GOLD },
      { getState }
    );
    prices.gold = priceResult.amount;

    priceResult = await getMetalPriceFunc(
      { productName: METAL_CHARS.SILVER },
      { getState }
    );
    prices.silver = priceResult.amount;

    return [
      {
        from: "Gold",
        to: "Silber",
        result: localeFormat(prices.silver / prices.gold, { minimumFractionDigits: 4 }),
        percentage: "-0.3%",
      },
      {
        from: "Silber",
        to: "Gold",
        result: localeFormat(prices.gold / prices.silver, { minimumFractionDigits: 4 }),
        percentage: "+0.3%",
      },
    ];
  }
);

export default getGoldSilverRatiosAction;
