import React from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import MaterialUiTheme from "./styles/theme/materialUi-theme";
import { ToastContainer } from "react-toastify";
import SendMessage from "./components/inbox/sendMessage";

import AppStateProvider from "./context";
import AppRoutes from "./AppRoutes";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "react-toastify/dist/ReactToastify.css";
import "./styles/styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-phone-input-2/lib/style.css";
import "react-loadingmask/dist/react-loadingmask.css";
import { DateLocalization } from "./components/common";
import NotiStackProivder from "./core/utils/notistackProivder";
import { ScrollUpOnNavigate } from "./ScrollUpOnNavigate";

function App() {
  return (
    <Provider store={store}>
      <div id="MaterialUiTheme">
        <MaterialUiTheme>
          <div id="NotiStackProivder">
            <NotiStackProivder>
              <ToastContainer />
              <div id="DateLocalization">
                <DateLocalization>
                  <div id="BrowserRouter">
                    <BrowserRouter>
                      <ScrollUpOnNavigate />
                      <div id="AppStateProvider">
                        <AppStateProvider>
                          <div id="AppRoutes">
                            <AppRoutes />
                          </div>
                          <SendMessage />
                        </AppStateProvider>
                      </div>
                    </BrowserRouter>
                  </div>
                </DateLocalization>
              </div>
            </NotiStackProivder>
          </div>
        </MaterialUiTheme>
      </div>
    </Provider>
  );
}

export default App;
