import axios from "axios";
import { BASE_URL } from "../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../core/apiconfig/constantApi";
import { localeFormat } from "../../core/utils/convert";

const getMetalPriceFunc = async (filter, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };


  const result = await axios.get(`${BASE_URL}data/product-prices/number/${filter.productName}/`, config);
  if (result?.data?.status != 200)
    throw Error("Lager konnten nicht geladen werden"); //("Storages not fetched");

  let price = 0;
  if (result?.data?.data?.value?.length > 0)
    price = result.data.data.value[0].arades_preis;

  if (!price) throw Error("Kein Preis gefunden"); //("No Price Found");

  return {
    product: filter.productName,
    amount: price,
    formattedAmount: localeFormat(price, { minimumFractionDigits: 2, currency: "EUR" }),
  };
};

export default getMetalPriceFunc;
