import { Box, Typography, Grid, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  InputSupportCopy,
  FormStepperStructure,
  LoadingOverlay,
} from "../../../common";
import {
  StorageCreatedStep,
  storageFullData,
  resetActionStatus,
  resetError,
} from "../../../../redux/slices/storage/storage.slice";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { URL, ERROR_CONTACT_US } from "../../../../core/constants";
import { useTranslation } from "react-i18next";

function StorageCreated({ handleCurrentStep }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const storageAllData = useSelector(storageFullData);
  const createdNumbers = useSelector(StorageCreatedStep);
  const [loadingCreate, setLoadingCreate] = useState(true);

  const [values, setValues] = useState({
    goldStorage: createdNumbers?.storage1Number,
    silverStorage: createdNumbers?.storage2Number,
    platiniumStorage: createdNumbers?.storage3Number,
    palladiumStorage: createdNumbers?.storage4Number,
  });
  useEffect(() => {
    if (storageAllData?.globalData?.actionStatus && !storageAllData.error) {
      setLoadingCreate(false);
      dispatch(resetActionStatus);
    } else if (storageAllData.error != null) {
      setLoadingCreate(false);
      enqueueSnackbar(storageAllData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [
    storageAllData?.loading,
    storageAllData?.globalData?.actionStatus,
    storageAllData.error,
  ]);
  const onSubmit = () => {
    //if (signContractData.signDone) handleCurrentStep("next");
    //else
    navigate(URL.STORAGE);
  };

  const copyNumbers = () => {
    let numberResult = "";
    numberResult += "Gold:" + values.goldStorage + ",";
    numberResult += "Silber:" + values.silverStorage + ",";
    numberResult += "Platin:" + values.platiniumStorage + ",";
    numberResult += "Palladium:" + values.palladiumStorage;
    navigator.clipboard.writeText(numberResult);
  };

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          maxWidth: "608px",
          margin: "0 auto",
        }}
      >
        <LoadingOverlay show={loadingCreate}>
          <Typography
            variant="h5"
            mt="32px"
            mb="13px"
            sx={{
              fontSize: "24px",
              fontWeight: "400",
            }}
          >
            Herzlichen Glückwunsch 🎉
          </Typography>
          <Typography
            variant="subtitle2"
            mb="13px"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            Ihre vier Hauptlagerplätze wurden für Sie reserviert.
          </Typography>
          <Typography
            variant="subtitle2"
            mb="13px"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            {storageFullData.wait14Days
              ? "Sie haben beim Ausfüllen Ihrer Vertragsunterlagen ausdrücklich den Beginn der Tätigkeit der Ratior AG erst nach Ablauf Ihrer Widerrufsfrist von 14 Tagen für Sie gewünscht. Ihre Lagerplätze werden nach Unterzeichnung und Annahme durch Ratior und erst nach Ablauf Ihrer Widerrufsfrist von 14 Tagen für Sie nutzbar."
              : "Sie haben beim Ausfüllen Ihrer Vertragsunterlagen ausdrücklich den sofortigen Beginn der Tätigkeit der Ratior AG für Sie gewünscht. Ihre Lagerplätze sind sofort nach Unterzeichnung und Annahme durch Ratior für Sie nutzbar."}
          </Typography>

          <Typography mt="32px" mb="20px">
            Ihre Lagerplatznummern sind:
            {/* Your storages number is: */}
          </Typography>

          <Grid
            alignItems="center"
            spacing={8}
            container
            sx={{
              "& input.Mui-disabled": {
                WebkitTextFillColor: "#000",
              },
            }}
          >
            <Grid sm={3} item>
              Gold :
            </Grid>
            <Grid sm={9} item>
              <InputSupportCopy
                disabled
                sx={{ bgcolor: "rgba(0, 0, 0, 0.04)" }}
                value={values.goldStorage}
              />
            </Grid>

            <Grid sm={3} item>
              Silber :
            </Grid>
            <Grid sm={9} item>
              <InputSupportCopy
                disabled
                value={values.silverStorage}
                sx={{ bgcolor: "rgba(0, 0, 0, 0.04)" }}
              />
            </Grid>

            <Grid sm={3} item>
              Platin :
            </Grid>
            <Grid sm={9} item>
              <InputSupportCopy
                disabled
                sx={{ bgcolor: "rgba(0, 0, 0, 0.04)" }}
                value={values.platiniumStorage}
              />
            </Grid>

            <Grid sm={3} item>
              Palladium:
            </Grid>
            <Grid sm={9} item>
              <InputSupportCopy
                disabled
                sx={{ bgcolor: "rgba(0, 0, 0, 0.04)" }}
                value={values.palladiumStorage}
              />
            </Grid>
          </Grid>
        </LoadingOverlay>
        <FormStepperStructure.Footer>
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "30px",
              width: 1,
            }}
          > */}
          {/* <Button
              onClick={copyNumbers}
              fullWidth
              sx={{ maxWidth: "192px" }}
              variant="outlined"
            >
              Copy all
            </Button> */}
          <Button
            onClick={onSubmit}
            fullWidth
            sx={{ bgcolor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            Zu den Lagerplätzen
          </Button>
          {/* </Box> */}
        </FormStepperStructure.Footer>
      </Box>
    </Box>
  );
}

export default StorageCreated;
