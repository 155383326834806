import i18next from "i18next";

export const zeroPad = (num, places) => String(num).padStart(places, "0");

export const dateString = (datestr) => {
  if (!datestr) return datestr;
  let idate = new Date(datestr);

  if (localStorage.getItem("i18nextLng") == "de")
    return idate.toLocaleDateString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  else
    return idate.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
};

//we need it to make backend date format or to compare dates in us order
export const longDateTo10 = (idate) => {
  if (!idate) return null;
  else {
    idate = new Date(idate);
    let month = idate.getMonth() + 1;
    let day = idate.getDate();
    return (
      idate.getFullYear() + "-" + zeroPad(month, 2) + "-" + zeroPad(day, 2)
    );
  }
};

export const dateTimeString = (m) => {
  if (!m) return null;
  m = new Date(m);
  return (
    dateString(m) +
    " " +
    ("0" + (m.getUTCHours() + 1)).slice(-2) +
    ":" +
    ("0" + (m.getUTCMinutes() + 1)).slice(-2) +
    ":" +
    ("0" + m.getUTCSeconds()).slice(-2)
  );
};

export const timeString = (m) => {
  if (!m) return null;
  let idate = new Date(m);
  return (
    ("0" + (idate.getUTCHours() + 1)).slice(-2) +
    ":" +
    ("0" + (idate.getUTCMinutes() + 1)).slice(-2)
  );
};

export function GermanFloatToFloat(amount) {
  if (!amount) return amount;
  return parseFloat(amount.replace(/\./g, "").replace(",", "."));
}

// Better version of GermanFloat that we will slowly migrate towards.
export function localeFormat(number, opts = { significantDigits: undefined, minimumFractionDigits: undefined, currency: undefined }) { // number -> Number to format, significantDigits -> Max number of significant digits to display (but will never cut from the integer part). currency -> Format with currency, e.g. EUR -> "€" and don't allow only 1 decimal place because it's weird.
  const locale = i18next.t("general.locale-for-number-formatting"); // Locale from current language e.g. "de-DE" or "en-US"
  if (locale.includes("general.")) { console.error("Locale not found in i18next, using default locale 'German'"); locale = "de-DE"; }
  // Options
  opts.significantDigits = opts.significantDigits ?? 0; opts.minimumFractionDigits = opts.minimumFractionDigits ?? 0; opts.currency = opts.currency ?? undefined; // Defaults for options
  // Exclude strings that cannot be converted to numbers and null / undefined
  if (number == null) { return ""; } // Return empty string if null or undefined to never show "undefined" in the UI
  if (Object.is(number, NaN)) { return ""; } // Return empty string if NaN to never show "NaN" in the UI
  if (isNaN(parseFloat(number))) { return number; } else { number = parseFloat(number); } // Return if not parsable as a number
  if (typeof number !== "number" || number === null || number === undefined) { return number; } // Return if not a number
  // Calculate number of significant digits
  let decimalPlaces = 0; // Number of decimal places to show e.g. 2 -> 2.00, 3 -> 2.000
  if (opts.significantDigits == null) { decimalPlaces = 0; } // No decimal places by default if no significant digits are set
  else if (number >= Math.pow(10, opts.significantDigits - 1)) { decimalPlaces = 0; } // No decimal places if number is large enough so that decimal digits does not matter
  else { decimalPlaces = opts.significantDigits - Math.floor(Math.log10(Math.abs(number) || 1)) - 1; } // Calculate number of decimal places to show e.g. 2 -> 2.00, 3 -> 2.000. "Math.abs(number) || 1" -> Prevent bad log10 results
  if (opts.minimumFractionDigits != null && decimalPlaces < opts.minimumFractionDigits) { decimalPlaces = opts.minimumFractionDigits; } // Ensure minimum number of decimal places
  if (opts.currency && decimalPlaces === 1) { decimalPlaces = 2; } // Don't allow only 1 decimal place for currency because it's weird e.g. 944.4 € -> 944.40 € (still allow more though e.g. for precise metal prices)
  // Format
  return new Intl.NumberFormat(locale, { // Format for locale
    minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces, // Set number of decimal places
    style: opts.currency ? "currency" : "decimal",
    currency: opts.currency
  }).format(number);
}
window.localeFormat = localeFormat;

export function MobileNumberHide(mobileNumber) {
  let hidedMobileNumber = mobileNumber.slice(0, 5);
  hidedMobileNumber += "******";
  hidedMobileNumber += mobileNumber.slice(11, mobileNumber.length);
  return hidedMobileNumber;
}
