import React, { useEffect, useState } from "react";
import { Button, Grid, Box, Divider, Typography } from "@mui/material";
import dontEnter from "../../../../assets/images/dontEnter.svg";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  faInfoCircle,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getPersonalDataAction,
  getCompanyDataAction,
  getStorageListAction,
  getActionCodeCustomerDataAction,
  getSecurityOptionsAction,
} from "../../../../redux/slices/storage";
import {
  storageFullData,
  storagePersonalInfo,
  storageCompanyInfo,
  StorageTypeStep,
  setStorageTypeStep,
  resetActionStatus,
} from "../../../../redux/slices/storage/storage.slice";

import { FormStepperStructure, RadioListIcon } from "../../../common";
import {
  WORKSPACE_TYPE,
  WORKSPACE_ID,
  WORKSPACE_NAME,
} from "../../../../core/apiconfig/constantApi";
import { longDateTo10 } from "../../../../core/utils/convert";
import {
  URL,
  WORKSPACETYPE,
  ACTION_CODE_TYPE,
} from "../../../../core/constants";
import ConfirmModal from "../../../common/popups/confirmModal";
import LoadingOverlay from "../../../common/LoadingOverlay/LoadingOverlay";

function PersonalInfoStep({ handleCurrentStep, currentStep }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentStorageType = useSelector(StorageTypeStep);
  const [storageType, setStorageType] = useState(
    currentStorageType.isForPartner ? "shared" : "myself"
  );
  const [openStorageTypeModal, setOpenStorageTypeModal] = useState(
    localStorage.getItem(WORKSPACE_TYPE) === WORKSPACETYPE.CONTACT
  );

  const [loading, setLoading] = useState(false);
  const storageFullInfo = useSelector(storageFullData);
  const personalInfo = useSelector(storagePersonalInfo);
  //const personalInfoStep = useSelector(storagePersonalInfoStep);
  const companyInfo = useSelector(storageCompanyInfo);
  const [confirmWorkspaceTypeOpen, setConfirmWorkspaceTypeOpen] = useState(
    localStorage.getItem(WORKSPACE_TYPE) != WORKSPACETYPE.CONTACT
  );
  //const personalInfoStep = useSelector(storagePersonalInfoStep);//check e confirm ro bardashtam
  //const [confirmPendingOpen, setConfirmPendingOpen] = useState(false);
  const [confirmExpireOpen, setConfirmExpireOpen] = useState(false);

  const workspaceType = localStorage.getItem(WORKSPACE_TYPE);
  const workspaceName = localStorage.getItem(WORKSPACE_NAME);
  const isContact =
    workspaceType === WORKSPACETYPE.CONTACT ||
    workspaceType === WORKSPACETYPE.UNDER_AGE;
  const workspaceId = localStorage.getItem(WORKSPACE_ID);

  const radioList = [
    {
      icon: <FontAwesomeIcon icon={faUser} />,
      label: "Nur für mich",
      value: "myself",
    },
    {
      icon: <FontAwesomeIcon icon={faUsers} />,
      label: "Gemeinsam mit einer zweiten Person",
      value: "shared",
    },
  ];

  useEffect(() => {
    setLoading(true);
    setDefaultStorageType();

    if (isContact) dispatch(getPersonalDataAction({ id: workspaceId }));
    else dispatch(getCompanyDataAction({ id: workspaceId }));

    dispatch(getSecurityOptionsAction());
    dispatch(
      getActionCodeCustomerDataAction({
        actionCodeType: ACTION_CODE_TYPE.STORAGE_FEE,
        justActive: true,
      })
    );
    dispatch(getStorageListAction(localStorage.getItem(WORKSPACE_ID))); // for detect pending storages for add
  }, []);

  const setDefaultStorageType = () => {
    const storageTypes = {
      isForMyself:
        localStorage.getItem(WORKSPACE_TYPE) == WORKSPACETYPE.CONTACT,
      isForCompany:
        localStorage.getItem(WORKSPACE_TYPE) != WORKSPACETYPE.CONTACT &&
        localStorage.getItem(WORKSPACE_TYPE) != WORKSPACETYPE.UNDER_AGE,
      isForUnderAge:
        localStorage.getItem(WORKSPACE_TYPE) === WORKSPACETYPE.UNDER_AGE,
      isForPartner: false,
    };

    dispatch(setStorageTypeStep(storageTypes));
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...personalInfo,
    },
  });

  const onSubmit = (data) => {
    const documentsExpired = longDateTo10(new Date()) > data.arades_ablaufdatum_ausweisdokument;
    if (documentsExpired) { setConfirmExpireOpen(true); }
    else {
      if (storageType === "shared") handleCurrentStep(currentStep + 2);
      else handleCurrentStep("next");
    }
  };

  const onError = () => {
    //error
  };

  const onSelectStorageTypeConfirm = () => {
    const storageTypes = {
      isForMyself: false,
      isForCompany: false,
      isForUnderAge: false,
      isForPartner: false,
    };
    if (storageType === "shared") {
      storageTypes.isForPartner = true;
      dispatch(setStorageTypeStep(storageTypes));
    } else if (localStorage.getItem(WORKSPACE_TYPE) === WORKSPACETYPE.CONTACT) {
      storageTypes.isForMyself = true;
    }
    dispatch(setStorageTypeStep(storageTypes));
    setConfirmWorkspaceTypeOpen(true);

    setOpenStorageTypeModal(false);
  };

  useEffect(() => {
    if (
      (storageFullInfo.globalData.actionStatus &&
        storageFullInfo.globalData.actionType === "getPersonalData") ||
      storageFullInfo.globalData.actionType === "getCompanyData"
    ) {
      setLoading(false);
      dispatch(resetActionStatus());
    }
  }, [storageFullInfo.globalData.actionStatus]);

  const boxStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  };
  const headrStyle = {
    color: "rgba(0, 0, 0, 0.38)",
    fontSize: 14,
  };
  const infoStyle = {
    color: "rgba(0, 0, 0, 0.87);",
    fontSize: 16,
  };

  return (
    <LoadingOverlay show={loading}>
      {/* <ConfirmModal
        open={
          !personalInfoStep.confirmDone && confirmWorkspaceTypeOpen === false
        }
        title={"Data Policy Agreement And Verification"}
        showCloseButton={false}
        message={
          <div>
            Valve respects the privacy of its online visitors and customers of
            its products and services and complies with applicable laws for the
            protection of your privacy, including, without limitation, the
            California Consumer Privacy Act ("CCPA"), the European Union General
            Data Protection Regulation ("GDPR"), and the Swiss and EU Privacy
            Shield Frameworks. 1. Definitions Wherever we talk about Personal
            Data below
          </div>
        }
        size={"lg"}
        noImage={true}
        acceptTitle={"AGREE"}
        onAccept={() => {
          dispatch(setPersonalInfoStep({ confirmDone: true }));
          if (storageFullInfo.hasAddedStorages) setConfirmPendingOpen(true);
        }}
        rejectTitle={"BACK TO STORAGES"}
        onReject={() => {
          navigate(URL.STORAGE);
        }}
      ></ConfirmModal> */}

      <ConfirmModal
        noImage={true}
        open={openStorageTypeModal}
        showCloseButton={false}
        title="Eröffnung der Lagerplätze"
        size="md"
        message={
          <Box
            sm={12}
            sx={{
              margin: "0 auto",
              width: "100%",
              "& span": {
                marginRight: "0 !important",
              },
            }}
          >
            <Typography
              variant="subtitle2"
              mb="13px"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Möchten sie Ihre Lagerplätze für sich alleine einrichten, oder
              gemeinsam mit einer zweiten Person?
            </Typography>

            <RadioListIcon
              currentValue={storageType}
              handleSetCurrentValue={(value) => {
                setStorageType(value);
              }}
              data={radioList}
            />
            {storageType === "shared" && (
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#FF8F00",
                }}
              >
                Wichtiger Hinweis: Damit Sie Gemeinschaftslagerplätze eröffnen
                können, ist es notwendig, dass auch die zweite Person ihre
                Registrierung bei Ratior abgeschlossen und ihre Identifikation
                durchgeführt hat.
              </Typography>
            )}
          </Box>
        }
        okTitle="Weiter"
        onOk={onSelectStorageTypeConfirm}
      ></ConfirmModal>

      <ConfirmModal
        open={
          confirmWorkspaceTypeOpen //&& personalInfoStep.confirmDone === false
        }
        showCloseButton={false}
        size="md"
        message={
          <div>
            Sie eröffnen jetzt vier Lagerplätze:<br></br>
            <ul>
              <li>Gold</li>
              <li>Silber</li>
              <li>Platin</li>
              <li>Palladium</li>
            </ul>
            auf den Namen "{workspaceName}" (im Folgenden zur Vereinfachung
            "Kunde" genannt).
          </div>
        }
        noImage={true}
        okTitle={"Weiter"}
        onOk={() => {
          setConfirmWorkspaceTypeOpen(false);
          //dispatch(setPersonalInfoStep({ confirmDone: true }));
        }}
      ></ConfirmModal>
      {/* <ConfirmModal
        open={confirmPendingOpen}
        title={"You already have adedd storages"}
        showCloseButton={false}
        message={
          <div>
            You can add storage only once but can have as many substorages as
            needed.
          </div>
        }
        imageSrc={dontEnter}
        okTitle={"GO TO STORAGE LIST"}
        onOk={() => {
          navigate(URL.STORAGE);
        }}
      ></ConfirmModal> */}
      <ConfirmModal
        open={confirmExpireOpen}
        title="Abgelaufene Dokumente" //{"Expired documents!"}
        showCloseButton={false}
        message={
          <div>
            Ihre Identifikations Dokumente sind abgelaufen. Bitte gehen sie auf
            ihr Profil und laden sie ein neues hoch.
            {/* Your identification documents are expired. please go to your profile
            and upload new ones. */}
          </div>
        }
        imageSrc={dontEnter}
        okTitle="Profil aufrufen" //{"GO TO PROFILE"}
        onOk={() => {
          navigate(URL.PROFILE);
        }}
      ></ConfirmModal>
      <Box>
        <Grid container spacing={8}>
          <Grid xs={12} item>
            <Typography
              sx={{
                fontWeight: 500,
                color: "#FF8F00",
                fontSize: 14,
              }}
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                style={{ fontSize: 16, marginRight: 5 }}
              />
              {isContact
                ? "Bitte überprüfen Sie, ob Ihre Daten korrekt sind und passen Sie diese gegebenenfalls noch an, bevor Sie mit der Eröffnung der Lagerplätze beginnen:"
                : "Bitte überprüfen Sie, ob die Daten Ihrer Firma korrekt sind und passen Sie diese gegebenenfalls noch an, bevor Sie mit der Eröffnung der Lagerplätze beginnen"}
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Divider
              sx={{
                marginTop: "20px",
                color: "rgba(0, 0, 0, 0.38)",
                "&::before": {
                  width: 0,
                },
              }}
              textAlign="left"
            >
              {isContact ? "Persönliche Daten" : "Firmendaten"}
            </Divider>
          </Grid>
          {!isContact && (
            <>
              <Grid xs={12} md={4} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>Name der Firma</Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {companyInfo.name}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} md={4} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>
                    Firmensitz: Straße Hausnummer
                  </Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {companyInfo.houseNo}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} md={4} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>PLZ</Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {isContact
                      ? personalInfo.postalCode
                      : companyInfo.postalCode}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} md={4} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>Ort</Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {isContact ? personalInfo.city : companyInfo.city}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} md={4} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>Telefone</Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {isContact ? personalInfo.phone : companyInfo.phone}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} md={4} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>Mobil</Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {isContact ? personalInfo.mobile : companyInfo.mobile}
                  </Typography>
                </Box>
              </Grid>

              <Grid xs={12} md={4} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>E-Mail</Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {isContact ? personalInfo.email : companyInfo.email}
                  </Typography>
                </Box>
              </Grid>
            </>
          )}{" "}
          {isContact && (
            <>
              <Grid xs={12} md={4} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>Name</Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {personalInfo.firstname &&
                      personalInfo.firstname + " " + personalInfo.lastname}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} md={4} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>{"Titel"}</Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {personalInfo.title}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} md={4} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>{"Ratior Key"}</Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {personalInfo.ratiorKey}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} md={4} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>E-Mail</Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {personalInfo.email}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} md={4} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>Mobiltelefon</Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {personalInfo.mobile}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} md={4} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>Telefon privat</Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {personalInfo.phone}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} md={4} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>
                    {"Geburtsdatum"}
                  </Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {personalInfo.birthdate}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} item>
                <Divider
                  sx={{
                    marginTop: "20px",
                    color: "rgba(0, 0, 0, 0.38)",
                    "&::before": {
                      width: 0,
                    },
                  }}
                  textAlign="left"
                >
                  Adresse
                </Divider>
              </Grid>
              <Grid xs={12} md={4} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>Land</Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {personalInfo.country}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} md={4} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>
                    Straße und Hausnummer
                  </Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {personalInfo.houseNo}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} md={4} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>Postleitzahl</Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {personalInfo.postalCode}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} md={4} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>Stadt</Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {personalInfo.city}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} md={4} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>Adresszusatz</Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {personalInfo.addressLine2}
                  </Typography>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
        <FormStepperStructure.Footer>
          <Button
            disabled={Boolean(Object.keys(errors).length)}
            onClick={() => navigate(URL.PROFILE)}
            fullWidth
            sx={{
              borderColor: "info.main",
              color: "info.main",
              marginRight: 10,
              maxWidth: "192px",
            }}
            variant="outlined"
          >
            Daten ändern
          </Button>
          <Button
            disabled={Boolean(Object.keys(errors).length)}
            onClick={handleSubmit(onSubmit, onError)}
            fullWidth
            sx={{ bgcolor: "info.main", maxWidth: "250px" }}
            variant="contained"
          >
            Daten sind korrekt
          </Button>
        </FormStepperStructure.Footer>
      </Box>
    </LoadingOverlay>
  );
}

export default PersonalInfoStep;
