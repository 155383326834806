import { Box, Grid, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import Select from "../common/inputs/select";
import { FormStepperStructure, ConfirmModal, RadioListIcon } from "../common";
import { URL } from "../../core/constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useIsScreenSizeDesktop } from "../../core/utils/responsive";

function LegalGuardianOrNotStep({ handleCurrentStep }) {
  const { t } = useTranslation();
  const isDesktop = useIsScreenSizeDesktop();
  const navigate = useNavigate();

  const [confirmOrNot, setConfirmOrNot] = useState(false);
  const [isLegalGaurdian, setIsLegalGaurdian] = useState("yes");

  const submitForm = (data) => {
    if (isLegalGaurdian == "no") setConfirmOrNot(true);
    else handleCurrentStep("next");
  };

  return (
    <Box>
      <ConfirmModal
        noImage={true}
        open={confirmOrNot}
        showCloseButton={false}
        title={t("legal-guardian-or-not.are-you-sure")}
        size="lg"
        message={
          <Box
            sm={12}
            sx={{
              margin: "0 auto",
              width: "100%",
              "& span": {
                marginRight: "0 !important",
              },
            }}
          >
            <Typography
              variant="subtitle2"
              mb="13px"
              sx={{
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              {t("legal-guardian-or-not.not-legal-guardian-warning")}
            </Typography>

            <RadioListIcon
              currentValue={isLegalGaurdian}
              handleSetCurrentValue={(value) => {
                setIsLegalGaurdian(value);
              }}
              data={[
                {
                  value: "no",
                  label: t("legal-guardian-or-not.sub-account-for-minor"),
                },
                {
                  value: "yes",
                  label: t("legal-guardian-or-not.continue-as-legal-guardian"),
                },
              ]}
            />
          </Box>
        }
        okTitle={t("legal-guardian-or-not.continue")}
        onOk={() => {
          if (isLegalGaurdian == "yes") {
            setConfirmOrNot(false);
            handleCurrentStep("next");
          } else navigate(URL.STORAGE);
        }}
      ></ConfirmModal>
      <Box
        sm={12}
        sx={{
          margin: "0 auto",
          width: "100%",
          borderRadius: "8px",
          backgroundColor: "#E3F2FD",
          padding: 10,
          marginBottom: 10,
        }}
      >
        <Typography
          mb="13px"
          as="div"
          sx={{
            fontSize: "16px",
          }}
        >
          {t("legal-guardian-or-not.only-legal-guardian-can-sign")}
        </Typography>
      </Box>
      <Typography
        variant="subtitle2"
        mb="50px"
        sx={{
          fontSize: "16px",
          fontWeight: "400",
        }}
      >
        {t("legal-guardian-or-not.please-specify-legal-guardian-status")}
      </Typography>

      <Box sx={{ maxWidth: "500px", margin: "0 auto" }}>
        <Grid spacing={8} container direction="column">
          <Grid xs={12} lg={8} item sx={{ maxWidth: "100% !important" }}>
            <Select
              label={t("legal-guardian-or-not.create-ratio-section")}
              value={isLegalGaurdian}
              options={[
                {
                  key: "yes",
                  value: isDesktop
                    ? t("legal-guardian-or-not.legal-guardian-desktop")
                    : t("legal-guardian-or-not.legal-guardian"),
                },
                {
                  key: "no",
                  value: isDesktop
                    ? t("legal-guardian-or-not.not-legal-guardian-desktop")
                    : t("legal-guardian-or-not.not-legal-guardian"),
                },
              ]}
              onChange={(e) => setIsLegalGaurdian(e.target.value)}
            />
          </Grid>
        </Grid>
      </Box>
      <FormStepperStructure.Footer>
        <Button
          onClick={submitForm}
          fullWidth
          sx={{
            backgroundColor: "info.main",
            maxWidth: "192px",
            marginTop: "30px",
          }}
          variant="contained"
        >
          {t("buttons.next")}
        </Button>
      </FormStepperStructure.Footer>
    </Box>
  );
}

export default LegalGuardianOrNotStep;
