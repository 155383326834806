import React, { useState, useEffect } from "react";
import DataTable from "../common/grids";
import {
  Datepicker,
  Input,
  Select,
  FilterArrow,
  LoadingOverlay,
} from "../common";
import { METAL_CHARS, ERROR_CONTACT_US } from "../../core/constants";
import { MetalPart } from "../../core/utils/business";
import { dateString, localeFormat } from "../../core/utils/convert";
import { Box, Typography, Grid, Button } from "@mui/material";
import { WORKSPACE_ID } from "../../core/apiconfig/constantApi";
import { useDispatch, useSelector } from "react-redux";
import { resetAll } from "../../redux/slices/storage/storage.slice";
import { getCustomerDepositListAction } from "../../redux/slices/vermittler";
import { useSnackbar } from "notistack";
import {
  customerDeposits,
  globalData,
  resetError,
  resetActionStatus,
} from "../../redux/slices/vermittler/vermittlerLists.slice";
import { useTranslation } from "react-i18next";
import { exportAndDownloadExcel } from "../../core/utils/tableExport";
import { useIsScreenSizeDesktop } from "../../core/utils/responsive";

const CustomerDepositList = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const isDesktop = useIsScreenSizeDesktop();

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [exportButtonDisabled, setExportButtonDisabled] = useState(false);
  const [sumValues, setSumValues] = useState();
  const [sumInvestedValues, setSumInvestedValues] = useState();
  const [filteredList, setFilteredList] = useState([]);
  const [filter, setFilter] = useState({
    storageNumber: "",
    metalType: "0",
    fromDate: null,
    toDate: null,
  });

  const depositList = useSelector(customerDeposits);
  const vermittlerListsGlobalData = useSelector(globalData);

  useEffect(() => {
    dispatch(resetAll());
    dispatch(
      getCustomerDepositListAction({
        vermittlerId: localStorage.getItem(WORKSPACE_ID),
      })
    );
  }, []);

  const onFilterChange = (event) => {
    const newFilter = { ...filter, [event.target.name]: event.target.value };
    setFilter(newFilter);

    const filteredData = depositList.filter((x) => {
      if (newFilter.storageNumber) {
        const storageNumberMatch = x.storageNumber
          ?.toLowerCase()
          .indexOf(newFilter.storageNumber.toLowerCase()) >= 0;
        if (!storageNumberMatch) { return false; }
      }
      if (newFilter.metalType !== "0") {
        const metalTypeMatches =
          (newFilter.metalType === "1" && MetalPart(x.storageNumber) === METAL_CHARS.GOLD) ||
          (newFilter.metalType === "2" && MetalPart(x.storageNumber) === METAL_CHARS.SILVER) ||
          (newFilter.metalType === "3" && MetalPart(x.storageNumber) === METAL_CHARS.PLATIN) ||
          (newFilter.metalType === "4" && MetalPart(x.storageNumber) === METAL_CHARS.PALLADIUM);
        if (!metalTypeMatches) { return false; }
      }
      if (newFilter.fromDate) {
        const fromDateMatch = new Date(x.arades_ueberweisungsdatum) >= new Date(newFilter.fromDate);
        if (!fromDateMatch) { return false; }
      }
      if (newFilter.toDate) {
        const toDateMatch = new Date(x.arades_ueberweisungsdatum) <= new Date(newFilter.toDate);
        if (!toDateMatch) { return false; }
      }
      return true;
    });

    setFilteredList(filteredData);
    calcSums(filteredData);
  };

  const calcSums = (data) => {
    let sum = 0; // Sum of transferred amounts
    let sumInvested = 0; // Sum of invested amounts (with fees deducted)
    data.forEach((item) => {
      sum         += item.arades_betrag ?? 0; // Default 0 -> Some values are undefined for some reason
      sumInvested += item.arades_betrag_invested ?? 0; // Default 0 -> Some values are undefined for some reason
    });
    setSumValues(localeFormat(sum, { minimumFractionDigits: 2, currency: "EUR" }));
    setSumInvestedValues(localeFormat(sumInvested, { minimumFractionDigits: 2, currency: "EUR" }));
  };

  useEffect(() => {
    if (vermittlerListsGlobalData.actionStatus && vermittlerListsGlobalData.actionType === "getCustomerDeposits") {
      dispatch(resetActionStatus());
      setFilteredList(depositList);
      calcSums(depositList);
    }
  }, [vermittlerListsGlobalData.actionStatus, vermittlerListsGlobalData.actionType, depositList]);

  useEffect(() => {
    if (vermittlerListsGlobalData.error != null) {
      enqueueSnackbar(vermittlerListsGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [vermittlerListsGlobalData.error, enqueueSnackbar, dispatch]);

  useEffect(() => {
    setLoading(vermittlerListsGlobalData.loading);
  }, [vermittlerListsGlobalData.loading]);

  return (
    <div className="container-card">
      <Box
        sx={{
          display: "flex",
          flexDirection: isDesktop ? "row" : "column",
          height: isDesktop ? "64px" : undefined,
          marginBottom: "20px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontWeight: 500 }} variant="h5" component="h5" style={{ width: !isDesktop ? "100%" : undefined }}>
          {t("customer-deposit-list.title")}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={(evt) => {
                setExportButtonDisabled(true); // Disable button to prevent downloading twice and to give click feedback
                // exportAndDownloadCsv("ratior-kunden-einzahlungen.csv", filteredList, filteredListColumns);
                exportAndDownloadExcel("ratior-kunden-einzahlungen.xlsx", filteredList, [
                  { column: t("customer-deposit-list.customer-name"),   type: String, width: 25, value: row => row.customerName },
                  { column: t("customer-deposit-list.storage"),         type: String, width: 17, value: row => row.storageNumber },
                  { column: t("customer-deposit-list.buy-date"),        type: Date,   width: 17, value: row => new Date(row.arades_ueberweisungsdatum), format: "DD.MM.YYYY" },
                  { column: t("customer-deposit-list.unit-price"),      type: Number, width: 17, value: row => row.unit_price,             format: '0.##0,00" €"' },
                  { column: t("customer-deposit-list.amount"),          type: Number, width: 17, value: row => row.arades_betrag,          format: '0.##0,00" €"' },
                  { column: t("customer-deposit-list.amount-invested"), type: Number, width: 17, value: row => row.arades_betrag_invested, format: '0.##0,00" €"' },
                ]);
              }}
              variant="contained"
              sx={{ mr: 6 }}
              disabled={exportButtonDisabled}
            >
              {t("buttons.export-csv")}
            </Button>
            <FilterArrow show={show} setShow={setShow}></FilterArrow>
          </Box>
      </Box>
      {show && (
        <Box mb={8}>
          <Grid container spacing={8}>
            <Grid xs={12} lg={3} item>
              <Input
                name="storageNumber"
                label={t("customer-deposit-list.storage")}
                value={filter.storageNumber}
                onChange={onFilterChange}
              />
            </Grid>
            <Grid xs={12} lg={3} item>
              <Select
                name="metalType"
                label={t("customer-deposit-list.metal-type")}
                value={filter.metalType}
                onChange={onFilterChange}
                options={[
                  { key: "0", value: t("customer-deposit-list.select") },
                  { key: "1", value: t("metals.AU") },
                  { key: "2", value: t("metals.AG") },
                  { key: "3", value: t("metals.PT") },
                  { key: "4", value: t("metals.PL") },
                ]}
              />
            </Grid>
            <Grid xs={12} lg={3} item>
              <Datepicker
                name="fromDate"
                value={filter.fromDate}
                label={t("customer-deposit-list.from")}
                onChange={onFilterChange}
              />
            </Grid>
            <Grid xs={12} lg={3} item>
              <Datepicker
                name="toDate"
                value={filter.toDate}
                label={t("customer-deposit-list.to")}
                onChange={onFilterChange}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <LoadingOverlay show={loading}>
        <Box>
          <Grid container>
            <Grid xs={12} lg={12} style={{ paddingTop: 0 }} item>
              <Typography sx={{ marginRight: "20px", display: "flex", justifyContent: "end" }}>
                {t("customer-deposit-list.amount-total")}: {" " + (sumValues ?? "…")}
              </Typography>
              <Typography sx={{ marginRight: "20px", display: "flex", justifyContent: "end" }}>
                {t("customer-deposit-list.amount-invested-total")}: {" " + (sumInvestedValues ?? "…")}
              </Typography>
            </Grid>
          </Grid>
          <DataTable
            hideTitle={true}
            entityName={"msdyn_warehouse"}
            canAdd={false}
            canEdit={false}
            showPageSizeSelector={true}
            data={filteredList}
            columns={[
              {
                name: "customerName",
                caption: t("customer-deposit-list.customer-name"),
              },
              {
                name: "storageNumber",
                caption: t("customer-deposit-list.storage"),
              },
              {
                name: "arades_ueberweisungsdatum",
                caption: t("customer-deposit-list.buy-date"),
                dataFormat: "date", // For correct sorting
                format: ({ value }) => dateString(value),
              },
              {
                name: "unit_price",
                caption: t("customer-deposit-list.unit-price"),
                alignment: "right",
                dataFormat: "number", // For correct sorting
                format: ({ value }) => localeFormat(value, { minimumFractionDigits: 4, currency: "EUR" }), // Show in € with 4 digits because that makes sense for a silver price
              },
              {
                name: "arades_betrag",
                caption: t("customer-deposit-list.amount"),
                alignment: "right",
                dataFormat: "number", // For correct sorting
                format: ({ value }) => localeFormat(value, { minimumFractionDigits: 2, currency: "EUR" }),
              },
              {
                name: "arades_betrag_invested",
                caption: t("customer-deposit-list.amount-invested"),
                alignment: "right",
                dataFormat: "number", // For correct sorting
                format: ({ value }) => localeFormat(value, { minimumFractionDigits: 2, currency: "EUR" }),
              },
            ]}
          ></DataTable>
        </Box>
      </LoadingOverlay>
    </div>
  );
};
export default CustomerDepositList;
