import React, { useEffect, useState } from "react";
import { localeFormat } from "../../../core/utils/convert";
import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import {
  FormStepperStructure,
  DiscountAmount,
} from "../../common";
import { globalsRatiorData } from "../../../redux/slices/globals/globals.slice";
import {
  storageGlobalData,
  setStorageFeeStepStep,
} from "../../../redux/slices/storage/storage.slice";
import { useSelector, useDispatch } from "react-redux";
import { WORKSPACETYPE } from "../../../core/constants";
import { WORKSPACE_TYPE } from "../../../core/apiconfig/constantApi";

const StorageFeeStep = ({ handleCurrentStep }) => {
  const dispatch = useDispatch();

  const [fee, setFee] = useState({
    fee: 0,
    discountAmount: 0,
    discountPercentage: 0,
  });

  const globalData = useSelector(storageGlobalData);
  const ratiorData = useSelector(globalsRatiorData);

  useEffect(() => {
    applyDiscount();
  }, []);

  const applyDiscount = () => {
    let zeroFee =
      localStorage.getItem(WORKSPACE_TYPE) == WORKSPACETYPE.UNDER_AGE ||
      localStorage.getItem(WORKSPACE_TYPE) ==
        WORKSPACETYPE.SELLER_ACCOUNT_VERIFIED;
    const discountPercent = zeroFee
      ? 0
      : globalData.actionCodeData.arades_prozent;

    let ifee = zeroFee ? 0 : ratiorData.staticFee;
    let idiscountAmount =
      ratiorData.staticFee - (discountPercent * ratiorData.staticFee) / 100;

    setFee({
      fee: localeFormat(ifee, { minimumFractionDigits: 2, currency: "EUR" }),
      discountAmount: localeFormat(idiscountAmount, { minimumFractionDigits: 2, currency: "EUR" }),
      discountPercentage: discountPercent,
    });

    dispatch(
      setStorageFeeStepStep({
        fee: ifee,
        discountedFee: idiscountAmount,
      })
    );
  };

  const onSubmit = () => {
    handleCurrentStep("next");
  };

  return (
    <Box
      sx={{
        maxWidth: "816px",
        margin: "0 auto",
        marginTop: "32px",
      }}
    >
      <Typography
        variant="subtitle2"
        sx={{
          fontSize: "16px",
          fontWeight: "400",
        }}
      >
        Einmalige Einrichtungsgebühr
      </Typography>

      <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.57)" }}>
        Die Gebühr für die Einrichtung Ihrer vier Hauptlagerplätze für die
        Edelmetalle Silber, Gold, Platin und Palladium beträgt einmalig
      </Typography>
      <Box mt="20px">
        {/* <Typography variant="caption" sx={{ color: "rgba(0, 0, 0, 0.57)" }}>
          Amount
        </Typography> */}

        <DiscountAmount
          price={fee.fee}
          variantBeforeDiscount="h3"
          variantAfterDiscount="h6"
          discount={fee.discountAmount}
          discountPercentage={fee.discountPercentage}
          variantDiscount="h3"
        />
      </Box>

      <FormStepperStructure.Footer>
        <Button
          onClick={onSubmit}
          fullWidth
          sx={{ bgcolor: "info.main", maxWidth: "192px" }}
          variant="contained"
        >
          Weiter
        </Button>
      </FormStepperStructure.Footer>
    </Box>
  );
};

export default StorageFeeStep;
