import React, { useState } from "react";
import { FormStepper } from "../common";

import StorageBankAccountStep from "./storageBankAccount.step";
import WorkspaceBankAccountStep from "./workspaceBankAccount.step";
import EnterAmountStep from "./enterAmount.step";
import EnterDescriptionStep from "./enterDescription.step";
import SummaryStep from "./summary.step";
import VerificationStep from "./verification.step";
import VerificationPartnerStep from "./VerificationPartner.step";
import SelectSourceStorageStep from "./selectSourceStorage.step";
import { useDispatch } from "react-redux";
import { resetAll } from "../../redux/slices/sell/sell.slice";
import { useNavigate, useParams } from "react-router-dom";
import { URL, WORKSPACETYPE } from "../../core/constants";
import { WORKSPACE_TYPE } from "../../core/apiconfig/constantApi";
import { useTranslation } from "react-i18next";

function SellStepManager() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const detailStorageId = useParams().storageid;
  const parentstorageid = useParams().parentstorageid;

  const [currentStep, setCurrentStep] = useState(1);
  const stepsCount = 8;

  const HandleCurrentStep = (step) => {
    window.scrollTo(0, 0); // Scroll up to the top because it is confusing on mobile if the scroll position stays
    if (isNaN(parseInt(step))) {
      if (step === "next" && currentStep < stepsCount) {
        setCurrentStep((prev) => prev + 1);
      } else if (step === "next2" && currentStep + 1 < stepsCount) {
        setCurrentStep((prev) => prev + 2);
      } else if (step === "prev" && currentStep > 1) {
        setCurrentStep((prev) => prev - 1);
      }
    } else {
      if (currentStep <= stepsCount && currentStep >= 1) {
        setCurrentStep(step);
      }
    }
  };

  const handleCancel = () => {
    dispatch(resetAll());
    navigate(
      `${parentstorageid ? URL.SUBSTORAGE : URL.STORAGE}/${
        parentstorageid ? parentstorageid + "/" : ""
      }${detailStorageId}`
    );
  };

  return (
    <div className="container-card">
      <FormStepper
        currentStep={currentStep}
        setCurrentStep={HandleCurrentStep}
        loading={false}
        noHeader={[3, 4]}
        noBackButton={[3, 4, 7, 8]}
        noFooter={[1, 2, 3, 4, 5, 6, 7, 8]}
        hasCancel={[1, 2, 4, 5, 6, 7, 8]}
        onCancel={handleCancel}
        headerTitles={[
          t("sell.select-source"),
          t("sell.bank-account"),
          t("sell.bank-account"),
          t("sell.enter-amount"),
          t("sell.description"),
          t("sell.summary"),
          t("sell.verification"),
          `${
            localStorage.getItem(WORKSPACE_TYPE) == WORKSPACETYPE.UNDER_AGE
              ? t("sell.other-guardian-acceptance")
              : t("sell.partner-acceptance")
          }`,
        ]}
      >
        {/* Step 1 */}
        <SelectSourceStorageStep
          currentStep={currentStep}
          handleCurrentStep={HandleCurrentStep}
        />

        {/* Step 2 */}
        <StorageBankAccountStep handleCurrentStep={HandleCurrentStep} />

        {/* Step 3 */}
        <WorkspaceBankAccountStep
          currentStep={currentStep}
          handleCurrentStep={HandleCurrentStep}
        />

        {/* Step 4 */}
        <EnterAmountStep
          currentStep={currentStep}
          handleCurrentStep={HandleCurrentStep}
        />

        {/* Step 5 */}
        <EnterDescriptionStep handleCurrentStep={HandleCurrentStep} />

        {/* Step 6 */}
        <SummaryStep handleCurrentStep={HandleCurrentStep} />

        {/* Step 7 */}
        <VerificationStep handleCurrentStep={HandleCurrentStep} />

        {/* Step 8- Verification partner or other guardian */}
        <VerificationPartnerStep handleCurrentStep={HandleCurrentStep} />
      </FormStepper>
    </div>
  );
}

export default SellStepManager;
