import React from "react";
import { localeFormat } from "../../../core/utils/convert";
import { Pie } from "react-chartjs-2";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  tableCellClasses,
} from "@mui/material";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useTranslation } from "react-i18next";
import { useIsScreenSizeDesktop } from "../../../core/utils/responsive";

// Register the datalabels plugin
Chart.register(ChartDataLabels);

// Display a chart for aggregated precious metal stock
// Example for prices parameter:
// {
//   "AU": {
//     "abbreviation": "AU",
//     "name": "Gold 999/1000",
//     "amount": 18,
//     "price": 1090.9044000000001
//   },
//   "AG": ...
//   "PT": ...
//   "PL": ...
// }
function PreciousMetalsChart({ prices }) {
  const { t } = useTranslation();
  const isDesktop = useIsScreenSizeDesktop();
  const metals = ["AU", "AG", "PT", "PL"];

  // Prepare data for the Pie chart
  const totalMetalPricesChartData = {
    labels: metals.map((metal) => metal), // Use metal abbreviations as labels
    datasets: [
      {
        label: "",
        data: metals.map((metal) => prices?.[metal]?.price ?? 0), // Map the prices to the dataset, defaulting to 0 if not available
        backgroundColor: [
          "rgba(238, 204, 36, 1)", // Gold color
          "rgba(204, 204, 204, 1)", // Silver color
          "rgba(227, 242, 253, 1)", // Platinum color
          "rgba(252, 217, 185, 1)", // Palladium color
        ],
        borderColor: ["rgba(255, 255, 255, 1)"], borderWidth: 1,
      },
    ],
  };

  // Configure options for the Pie chart
  const totalMetalPricesChartOptions = {
    responsive: true, // Make chart responsive
    plugins: {
      legend: {
        display: false, // Hide the legend to remove labels from the chart
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const metal = context.label;
            return ` ${metal}: ${localeFormat(context.parsed, { minimumFractionDigits: 2, currency: "EUR" })}`; // Display formatted value in tooltip
          },
        },
      },
      datalabels: {
        formatter: function (value, context) {
          if (!Object.keys(prices).length) { return ""; } // No labels while loading because it looks better
          let percentage = value / context.chart.data.datasets[0]?.data.reduce((a, b) => a + b, 0) * 100; // Calculate percentage to hide label if the slice is too thin
          if (isNaN(percentage)) { return ""; } // Hide label if no data is loaded yet because it looks bad
          if (percentage < 5) { return ""; } // Hide label if the slice is too thin because it looks bad
          return context.chart.data.labels[context.dataIndex]; // Display metal abbreviation on the pie slice
        },
        // color: "white", // Set text color to white for better contrast
        color: function (context) {
          // Use the darkened version of the background color for the label color
          const label = context.chart.data.labels[context.dataIndex];
          if (label === "AU") return "#6F5E06";
          if (label === "AG") return "#545454";
          if (label === "PT") return "#08578F";
          if (label === "PL") return "#6A3402";
          return "black";
        },
        font: {
          weight: "bold",
          size: 14,
        },
      },
    },
  };

  return (
    <Grid container spacing={2} alignItems="center" wrap="wrap">
      {/* Container for the pie chart, responsive based on screen size */}
      {metals.reduce((value, metal) => value + (prices?.[metal]?.price ?? 0), 0) > 0 && // Only show the chart if there is anything to show, to avoid an empty space
        <Grid item xs={12} md={6} style={{ maxWidth: "200px" }}>
          <Pie
            data={totalMetalPricesChartData}
            options={totalMetalPricesChartOptions}
          />
        </Grid>
      }
      {/* Table container for metal details, appears alongside chart on larger screens */}
      <Grid item xs={12} md={6} style={{ minWidth: "fit-content" /* Make table break if there is not enough space */ }}>
        <TableContainer style={{
          border: "none",
          minWidth: "fit-content" // Prevent horizontal table scrollbar
        }}>
          <Table size="small" aria-label="precious metals table" 
            style={{ borderCollapse: "collapse" }} // Remove cell spacing
            sx={{ [`& .${tableCellClasses.root}`]: { border: "none" } }}>{/* Remove borders from table cells */}
            <TableBody>
              {metals.map((metal) => (
                <TableRow key={metal}>
                  <TableCell style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                    <b style={{ fontFamily:"sans-serif" }}>{metal}</b>{isDesktop && <>&nbsp;{ t(`metals.${metal}`) }</>} {/* Display first part of the metal name or placeholder if unavailable. Only show long name on desktop because there is not enough space on mobile. */}
                  </TableCell>
                  <TableCell style={{ paddingLeft: "4px", paddingRight: "4px" }} align="right">
                    {prices?.[metal]?.amount != null
                      ? localeFormat(prices[metal].amount, { minimumFractionDigits: 4 }) // Display formatted amount if available
                      : "…"}&nbsp;g {/* Placeholder if amount is not available */}
                  </TableCell>
                  <TableCell style={{ paddingLeft: "4px", paddingRight: "4px" }} align="right">
                    {prices?.[metal]?.price != null
                      ? localeFormat(prices[metal].price, { minimumFractionDigits: 2, currency: "EUR" }) // Display formatted amount if available
                      : "…"} {/* Placeholder if price is not available */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default PreciousMetalsChart;
