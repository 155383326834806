import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faChild } from "@fortawesome/free-solid-svg-icons";
import { Box, FormControl, FormLabel, Button } from "@mui/material";
import { RadioListIcon, FormStepperStructure } from "../common";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../common/popups/confirmModal";
import { URL } from "../../core/constants";
import {
  setWorkspaceType,
  selectWorkspaceTypeFields,
} from "../../redux/slices/underage/underage.slice";
import { useTranslation } from "react-i18next";

function SelectWorkspaceTypeStep({ handleCurrentStep }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { workSpaceType: workSpace } = useSelector(selectWorkspaceTypeFields);
  const [workSpaceType, setWorkSpaceType] = useState(workSpace);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const radioList = [
    {
      icon: <FontAwesomeIcon icon={faBuilding} />,
      label: t("underage.company"),
      value: "company",
    },
    {
      icon: <FontAwesomeIcon icon={faChild} />,
      label: t("underage.under-age-person"),
      value: "person",
    },
  ];

  const submitForm = () => {
    if (workSpaceType == "company") setConfirmOpen(true);
    else {
      dispatch(setWorkspaceType(workSpaceType));
      handleCurrentStep("next");
    }
  };
  return (
    <>
      <div style={{ width: 0 }}>
        <ConfirmModal
          open={confirmOpen}
          title={t("underage.confirm-you-need-permisson")}
          showCloseButton={false}
          size="md"
          message={
            <div>{t("underage.confirm-new-account-for-permission")}</div>
          }
          noImage={true}
          acceptTitle={t("underage.confirm-yes-continue")}
          rejectTitle={t("buttons.cancel")}
          onAccept={() => {
            setConfirmOpen(false);
            navigate(URL.REGISTERCOMPANY);
          }}
          onReject={() => {
            setConfirmOpen(false);
          }}
        ></ConfirmModal>
      </div>
      <Box
        sx={{
          margin: "0 auto",
          minHeight: "calc(100vh - 250px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "50%" } }}>
          <FormControl sx={{ minWidth: "100%" }}>
            <FormLabel sx={{ fontSize: "14px", mb: "20px" }} id="radio-group">
              {t("underage.create-workspace-for")}
            </FormLabel>
            <RadioListIcon
              currentValue={workSpaceType}
              handleSetCurrentValue={(value) => {
                setWorkSpaceType(value);
              }}
              data={radioList}
            />
          </FormControl>
        </Box>

        <FormStepperStructure.Footer>
          <Button
            onClick={submitForm}
            fullWidth
            sx={{ backgroundColor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            {t("buttons.next")}
          </Button>
        </FormStepperStructure.Footer>
      </Box>
    </>
  );
}

export default SelectWorkspaceTypeStep;
