import React, { useState, useEffect, Fragment } from "react";
import { Button, DataTable, LoadingOverlay, Ribbon } from "../common";
import {
  URL,
  CELL_RENDER_TYPE,
  STORAGE_STATUS,
  METAL_CHARS,
  ERROR_CONTACT_US,
} from "../../core/constants";
import { WORKSPACE_ID, WORKSPACE_TYPE } from "../../core/apiconfig/constantApi";
import { MetalPart } from "../../core/utils/business";
import { useDispatch, useSelector } from "react-redux";
import { resetAll } from "../../redux/slices/storage/storage.slice";
import { getCustomerStorageListAction } from "../../redux/slices/storageList";
import { useSnackbar } from "notistack";
import { Box, Tooltip } from "@mui/material";
import {
  storageDetailedList,
  globalData,
  resetError,
} from "../../redux/slices/storageList/storageList.slice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BASE_URL, BYPASS_ADOBE_SIGN } from "../../core/apiconfig/environment";
import { useApi } from "../../core/hooks/useApi";
import ChooseMainStorageDialog from "./addStorage/ChooseMainStorageDialog/ChooseMainStorageDialog";
import { localeFormat } from "../../core/utils/convert";
import { useIsScreenSizeDesktop } from "../../core/utils/responsive";

const StorageList = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isDesktop = useIsScreenSizeDesktop();
  const { post } = useApi();

  const [loading, setLoading] = useState(false);
  const [paymentInfoRequestSent, setPaymentInfoRequestSent] = useState(false);
  const [openChooseMainStorageDialog, setOpenChooseMainStorageDialog] = useState(false);

  const storageList = useSelector(storageDetailedList); // Data from #storageList
  const storageListGlobalData = useSelector(globalData);

  useEffect(() => {
    dispatch(resetAll());
    dispatch(
      getCustomerStorageListAction({
        workspaceId: localStorage.getItem(WORKSPACE_ID),
        workspaceType: localStorage.getItem(WORKSPACE_TYPE),
        fetchSubStorages: true,
        fetchPrices: true,
      })
    );
  }, []);

  useEffect(() => {
    if (storageListGlobalData.error != null) {
      enqueueSnackbar(storageListGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [storageListGlobalData.error]);

  useEffect(() => {
    setLoading(storageListGlobalData.loading);
  }, [storageListGlobalData.loading]);

  return (
    <>
      <LoadingOverlay show={loading}>
        {!BYPASS_ADOBE_SIGN &&
          storageList.map((item, index) => {
            if (
              item.statuscode == STORAGE_STATUS.PENDING_FOR_CONTRACT &&
              MetalPart(item.msdyn_name) == METAL_CHARS.GOLD
            )
              return (
                <Ribbon
                  key={index}
                  buttonText={t("buttons.sign-now")}
                  description={`${t(
                    "storage-list.sign-1"
                  )} "${item.msdyn_name.substring(0, 8)}" ${t(
                    "storage-list.sign-2"
                  )}`}
                  onClick={() => {
                    navigate(
                      URL.SIGN_STORAGE_CONTRACT_BASE +
                      "/" +
                      item.msdyn_warehouseid +
                      "/" +
                      item.arades_lagerplatz_typ +
                      "/" +
                      (item.arades_verzicht_auf_widerruf ? 0 : 1)
                    );
                  }}
                ></Ribbon>
              );
          })}

        {storageList.map((item, index) => {
          if (
            item.statuscode == STORAGE_STATUS.PENDING_FOR_PAYMENT &&
            MetalPart(item.msdyn_name) == METAL_CHARS.GOLD
          )
            return (<Fragment key={item.msdyn_warehouseid}>
              <Ribbon
                buttonText={t("buttons.payment-info")}
                description={`${t("storage-list.payment-1")} "${item.msdyn_name.substring(0, 7).replace('-', '‑')}" ${t("storage-list.payment-2")}` /* Replace hyphen with non-breaking hyphen to prevent line break in the middle of the storage number */}
                onClick={() => {
                  navigate(URL.STORAGE_PAYMENT_BASE + "/" + item.msdyn_name);
                }}
              ></Ribbon>
            </Fragment>);
        })}
        <div className="container-card">
          <div className={isDesktop ? "grid-row" : "grid-column"}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              <Button
                disabled={
                  !BYPASS_ADOBE_SIGN &&
                  storageList.filter((item) => (item.statuscode || "").toString() === STORAGE_STATUS.PENDING_FOR_CONTRACT).length !== 0
                }
                className="primary"
                onClick={() => {
                  navigate(URL.ADDSTORAGE);
                }}
              >
                <span className="add-span">
                  <span style={{ fontSize: 18 }}>{"+"}</span>&nbsp;&nbsp;
                  {t("buttons.create-storage")}
                </span>
              </Button>
              <Tooltip title={storageList.length === 0 ? t("storage-list.create-sub-storage.disabled-reason-description") : undefined}>
                <span>
                  <Button
                    disabled={
                      (!BYPASS_ADOBE_SIGN && storageList.filter((item) => (item.statuscode || "").toString() === STORAGE_STATUS.PENDING_FOR_CONTRACT).length !== 0) ||
                      storageList.length === 0
                    }
                    className="primary"
                    onClick={() => {
                      setOpenChooseMainStorageDialog(true);
                    }}
                  >
                    <span className="add-span">
                      <span style={{ fontSize: 18 }}>{"+"}</span>&nbsp;&nbsp;
                      {t("buttons.create-substorage")}
                    </span>
                  </Button>
                </span>
              </Tooltip>
            </Box>
            <Tooltip placement="top" title={storageList.length === 0 ? t("storage-list.pay-info.disabled-reason-description") : undefined}>
              <span>
                <Button
                  className="primary"
                  disabled={paymentInfoRequestSent || storageList.length === 0}
                  style={ isDesktop ? undefined : { height: "auto", textAlign: "left" } /* Allow button text to break into two lines on mobile */ }
                  onClick={async () => {
                    setPaymentInfoRequestSent(true); // Disable button to prevent the user from clicking it again
                    const data = { warehouses: [] };
                    for (let i = 0; i < storageList.length; i += 1) {
                      if ((storageList[i].msdyn_name).includes("-AU-00")){ // @paul I don't know the reason for this filter, but it was there before. I assume that this is meant to only create one Überweisungsmuster for every set of 4 storage spaces?
                        data.warehouses.push(storageList[i].msdyn_warehouseid)
                      }
                    }
                    await post(`${BASE_URL}powerplatform/paymentInfo`, data);
                  }}
                >
                  <span className="add-span">
                    {isDesktop ? <><span style={{ fontSize: 18 }}>{""}</span>&nbsp;&nbsp;</> : null}
                    {t("storage-list.pay-info.title")}
                  </span>
                </Button>
              </span>
            </Tooltip>
          </div>
          <Box>
            <DataTable
              title={t("storage-list.title")}
              entityName={"msdyn_warehouse"}
              canAdd={false}
              disableAdd={true}
              addPath={URL.ADDSTORAGE}
              canEdit={true}
              editButtonLeft={true}
              canExport={true}
              data={storageList}
              columns={[
                {
                  name: "metalName",
                  caption: isDesktop ? t("storage-list.metal-type") : t("storage-list.metal-type-short"),
                  cellRenderType: CELL_RENDER_TYPE.METAL_CELL,
                  width: isDesktop ? "120px" : "80px",
                },
                { name: "msdyn_name", caption: t("storage-list.number") },
                {
                  name: "arades_menge_formatted",
                  caption: t("storage-list.amount"),
                  width: isDesktop ? "180px" : "140px",
                  alignment: "right",
                },
                {
                  name: "totalPrice",
                  caption: t("storage-list.storage-value"),
                  width: "180px",
                  alignment: "right",
                  dataType: "number", // For correct sorting
                  format: ({ value }) => localeFormat(value, { minimumFractionDigits: 2, currency: "EUR" })
                },
                { name: "statusText", caption: t("storage-list.status") },
                {
                  name: "storageDescription",
                  caption: t("customer-storage-list.description"),
                  width: "320px", // Prevent too large cells if the customer entered a long description
                  wordWrapEnabled: true, // Wrap if description is too long instead of truncating with "..."
                },
              ]}
            ></DataTable>
          </Box>
        </div>
      </LoadingOverlay>
      <ChooseMainStorageDialog
        open={openChooseMainStorageDialog}
        onClose={(chosenMainStorageOptionId) => {
          setOpenChooseMainStorageDialog(false);
          if (chosenMainStorageOptionId) {
            // Open page to add substorage
            navigate(URL.SUBSTORAGEADDBASE + "/" + chosenMainStorageOptionId);
          }
        }}
        options={storageList}
      ></ChooseMainStorageDialog>
    </>
  );
};
export default StorageList;
