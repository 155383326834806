import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";
import { localeFormat, zeroPad } from "../../../core/utils/convert";
import { getRatiorDataFunc } from "../../slices/globals";

const getDownloadLink = (
  workspaceName,
  rowData,
  ratiorCompanyName,
  abrechnungId,
  isDetail
) => {
  let vermittlerName = workspaceName;
  //let vermittlerName = workspaceName.replaceAll(/(\.|"|@|ß|²|³|µ|`|´|°|=|&|\$|§|~|#|%|\*|:|<|>|\?|\/|\||  |{|}|!|\+|___|__|\^)/gm,"_");
  let idate = `(${rowData.year}-${rowData.month_formatted})`;
  let ratCompanyName = `(${ratiorCompanyName})`;
  let rowId = `(${abrechnungId})`;
  let fileName = `${rowData.year}-${zeroPad(
    rowData.month,
    2
  )}-${ratiorCompanyName.replaceAll(" ", "-")}-${
    isDetail ? "Details.pdf" : "Deckblatt.pdf"
  }`;
  let baseUrl = `${BASE_URL}download/static/arades_abrechnung/`;
  return `${baseUrl}${vermittlerName} ${idate} ${ratCompanyName} ${rowId}/${fileName}/$value`;
};

const getCommissionHeaderListFunc = async (
  { workspaceId, workspaceName },
  { getState }
) => {
  let ratiorCompanies = await getRatiorDataFunc({}, { getState });
  let ratiorAgId = ratiorCompanies.ratiorAgAccountId;
  let ratiorGmbhId = ratiorCompanies.ratiorGmbHAccountId;
  let ratiorGoldId = ratiorCompanies.ratiorGoldAccountId;
  let ratiorAgName = ratiorCompanies.ratiorAgAccountName;
  let ratiorGmbhName = ratiorCompanies.ratiorGmbHAccountName;
  let ratiorGoldName = ratiorCompanies.ratiorGoldAccountName;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  const result = await axios.get(`${BASE_URL}data/commissions/account/${workspaceId}/`, config);

  if (result.data.status != 200)
    throw Error("Commission Header List not fetched");

  let list = [];

  for (let i = 0; i < result?.data?.data?.value?.length; i++) {
    let item = result?.data?.data?.value[i];
    let foundRow = list.find(
      (x) => x.year == item.arades_jahr && x.month == item.arades_monat
    );
    if (!foundRow) {
      let tmp = {
        year: item.arades_jahr,
        month: item.arades_monat,
        month_formatted:
          item["arades_monat@OData.Community.Display.V1.FormattedValue"],
        ratiorAgAmount: 0,
        ratiorGmbhAmount: 0,
        ratiorGoldAmount: 0,
      };
      list.push(tmp);
      foundRow = list[list.length - 1];
    }

    if (item._arades_glaeubiger_value == ratiorAgId) {
      foundRow.ratiorAg_abrechnung_id = item.arades_abrechnungid;
      foundRow.ratiorAgAmount += item.arades_auszahlungsbetrag;
    }
    //item.arades_betrag_bronze + item.arades_betrag_palladium;
    if (item._arades_glaeubiger_value == ratiorGmbhId) {
      foundRow.ratiorGmbh_abrechnung_id = item.arades_abrechnungid;
      foundRow.ratiorGmbhAmount += item.arades_auszahlungsbetrag;
    }
    //item.arades_betrag_bronze + item.arades_betrag_palladium;
    if (item._arades_glaeubiger_value == ratiorGoldId) {
      foundRow.ratiorGold_abrechnung_id = item.arades_abrechnungid;
      foundRow.ratiorGoldAmount += item.arades_auszahlungsbetrag;
    }
    //item.arades_betrag_bronze + item.arades_betrag_palladium;

    foundRow.ratiorAg_non_detail_link = getDownloadLink(
      workspaceName,
      foundRow,
      ratiorAgName,
      foundRow.ratiorAg_abrechnung_id,
      false
    );
    foundRow.ratiorAg_detail_link = getDownloadLink(
      workspaceName,
      foundRow,
      ratiorAgName,
      foundRow.ratiorAg_abrechnung_id,
      true
    );
    foundRow.ratiorGmbh_non_detail_link = getDownloadLink(
      workspaceName,
      foundRow,
      ratiorGmbhName,
      foundRow.ratiorGmbh_abrechnung_id,
      false
    );
    foundRow.ratiorGmbh_detail_link = getDownloadLink(
      workspaceName,
      foundRow,
      ratiorGmbhName,
      foundRow.ratiorGmbh_abrechnung_id,
      true
    );
    foundRow.ratiorGold_non_detail_link = getDownloadLink(
      workspaceName,
      foundRow,
      ratiorGoldName,
      foundRow.ratiorGold_abrechnung_id,
      false
    );
    foundRow.ratiorGold_detail_link = getDownloadLink(
      workspaceName,
      foundRow,
      ratiorGoldName,
      foundRow.ratiorGold_abrechnung_id,
      true
    );
  }

  for (let i = 0; i < list.length; i++) {
    list[i].ratiorAgAmount_formatted = localeFormat(list[i].ratiorAgAmount, { minimumFractionDigits: 2, currency: "EUR" });
    list[i].ratiorGoldAmount_formatted = localeFormat(list[i].ratiorGoldAmount, { minimumFractionDigits: 2, currency: "EUR" });
    list[i].ratiorGmbhAmount_formatted = localeFormat(list[i].ratiorGmbhAmount, { minimumFractionDigits: 2, currency: "EUR" });
    list[i].total_formatted = localeFormat(
      list[i].ratiorAgAmount +
      list[i].ratiorGoldAmount +
      list[i].ratiorGmbhAmount,
      { minimumFractionDigits: 2, currency: "EUR" }
    );
  }

  //return list.sort((a, b) => (a?.month >= b?.month ? -1 : 1));
  return list.sort(function (a, b) {
    if (a?.year === b?.year) {
      // Month are onlyimportant, when year is the same
      return b?.month - a?.month;
    }
    return a?.year > b?.year ? -1 : 1;
  })
};
export default getCommissionHeaderListFunc;
