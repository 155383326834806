import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Divider, Typography } from "@mui/material";
import Input from "../../common/inputs/input";
import { FormStepperStructure, LoadingOverlay } from "../../common";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { MetalPart } from "../../../core/utils/business";
import {
  GermanFloatToFloat,
  localeFormat,
} from "../../../core/utils/convert";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  ERROR_CONTACT_US,
  METAL_CHARS,
  FEE_PAYER_TYPE,
} from "../../../core/constants";
import { useTranslation } from "react-i18next";
import {
  setEnterAmount,
  transferFullState,
  transferGlobalState,
  transferEnterAmountFields,
  transferFromStorage,
  setTransferFee,
  transferFee,
  transferSelectReceptorTypeFields,
  resetActionStatus,
  resetError,
} from "../../../redux/slices/transfer/transfer.slice";

import { getStorageAmountAction } from "../../../redux/slices/transfer";
import { globalsRatiorData } from "../../../redux/slices/globals/globals.slice";

function EnterAmount({ handleCurrentStep }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const amountFields = useSelector(transferEnterAmountFields);
  const fromStorage = useSelector(transferFromStorage);
  const transferFullData = useSelector(transferFullState);
  const transferFeeData = useSelector(transferFee);
  const transferGlobalData = useSelector(transferGlobalState);
  const receptor = useSelector(transferSelectReceptorTypeFields);
  const ratiorData = useSelector(globalsRatiorData);
  const [loading, setLoading] = useState(false);

  const requiredMsg = t("validation.required");

  const feeUnit = t("transfer.gram-ab");

  const metalName =
    MetalPart(fromStorage.msdyn_name) === METAL_CHARS.GOLD
      ? t("metals.AU")
      : MetalPart(fromStorage.msdyn_name) === METAL_CHARS.SILVER
      ? t("metals.AG")
      : MetalPart(fromStorage.msdyn_name) === METAL_CHARS.PLATIN
      ? t("metals.PT")
      : MetalPart(fromStorage.msdyn_name) === METAL_CHARS.PALLADIUM
      ? t("metals.PL")
      : "";

  useEffect(() => {
    applyDiscount(transferFullData.enterAmount.amountPerGram);
  }, []);

  const applyDiscount = (amountPerGram) => {
    const actionCodeFeePercent =
      transferFullData.globalData.actionCodeData.arades_prozent;
    const discountPercent = !transferFullData.globalData.actionCodeData
      .actionCodeId
      ? ratiorData.transferFee
      : actionCodeFeePercent;
    const normalFee = (ratiorData.transferFee * amountPerGram) / 100;
    const discountedFee = (discountPercent * amountPerGram) / 100;

    const zeroFee =
      fromStorage?.msdyn_name?.substring(0, 8) ==
        transferFullData?.selectStorage?.subStorageNumber?.substring(0, 8) ||
      (fromStorage?.msdyn_name !=
        transferFullData?.selectStorage?.storageNumber &&
        fromStorage?.msdyn_name?.substring(0, 8) ==
          transferFullData?.selectStorage?.storageNumber?.substring(0, 8));

    dispatch(
      setTransferFee({
        fee: zeroFee ? 0 : normalFee,
        discountedFee: zeroFee ? 0 : discountedFee,
        discountPercentage: zeroFee ? 0 : actionCodeFeePercent,
        zeroFee: zeroFee,
      })
    );
  };

  const setAmountData = (data) => {
    let enteredAmount = GermanFloatToFloat(data.amount);
    let senderFeeInOtherCase =
      receptor.receptorType == "otherPerson"
        ? transferFullData.selectWhoPaysFee.whoPays ==
          FEE_PAYER_TYPE.SELLER_PAYS
          ? transferFeeData.discountedFee
          : transferFullData.selectWhoPaysFee.whoPays ==
            FEE_PAYER_TYPE.BUYER_PAYS
          ? 0
          : transferFeeData.discountedFee / 2
        : 0;
    let totalAmount = enteredAmount * 1 + senderFeeInOtherCase * 1;
    if (totalAmount > fromStorage.arades_verfuegbare_menge) {
      enqueueSnackbar(t("validation.above-max"), {
        variant: "error",
        autoHideDuration: 20000,
      });
      return false;
    }

    dispatch(
      setEnterAmount({
        amount: data.amount,
        amountPerGram: enteredAmount,
      })
    );
    return true;
  };
  const submitForm = (data) => {
    if (setAmountData(data)) handleCurrentStep("next");
  };

  useEffect(() => {
    if (transferGlobalData.actionStatus) {
      setValue(
        "amount",
        localeFormat(amountFields.maxAmountPerGram, { minimumFractionDigits: 4 })
      );
      applyDiscount(
        GermanFloatToFloat(watch("amount") ?? 0)
      );
      dispatch(resetActionStatus());
    }
  }, [transferGlobalData.actionStatus]);

  useEffect(() => {
    setLoading(transferGlobalData.loading);
  }, [transferGlobalData.loading]);

  useEffect(() => {
    if (transferGlobalData.error != null) {
      enqueueSnackbar(transferGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [transferGlobalData.error]);

  const schema = yup.object().shape({
    amount: yup
      .string()
      .test(
        "is-decimal",
        t("validation.none-zero-decimal"),
        (value) =>
          (value + "").match(/^-?\d{1,3}(?:\.\d{3})*(?:,\d+)?$/) ||
          (value + "").match(/^(?:[1-9]\d*|0(?!(?:\.0+)?$))?(?:\.\d+)?$/)
      )
      .notOneOf([0, "0", "0,00000"], t("validation.none-zero-decimal"))
      .required(requiredMsg)
      .nullable(),
  });

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      ...amountFields,
      agreed: false,
    },
    resolver: yupResolver(schema),
  });

  const boxStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  };
  const headrStyle = {
    color: "rgba(0, 0, 0, 0.38)",
    fontSize: 14,
  };
  const infoStyle = {
    color: "rgba(0, 0, 0, 0.87);",
    fontSize: 16,
  };

  return (
    <LoadingOverlay show={loading}>
      <Box
        sx={{
          margin: "0 auto",
          minHeight: "calc(100vh - 250px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box sx={{ minWidth: "50%", margin: "0 auto" }}>
          {receptor.receptorType == "otherPerson" && (
            <Box sx={{ marginBottom: 10 }}>
              <Grid xs={12} item sx={{ marginBottom: 10 }}>
                <Typography sx={{ ...headrStyle, fontSize: 16 }}>
                  {t("transfer.to")}
                </Typography>
              </Grid>
              <Grid xs={12} item sx={{ marginBottom: 10 }}>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>
                    {t("transfer.storage-number")}
                  </Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {transferFullData.selectRecipient.storage.msdyn_name}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>
                    {" "}
                    {t("transfer.storage-owner")}
                  </Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {transferFullData.selectRecipient.contact &&
                      transferFullData.selectRecipient.contact.firstname +
                        " " +
                        transferFullData.selectRecipient.contact.lastname}
                  </Typography>
                </Box>
              </Grid>
            </Box>
          )}
          <Grid spacing={1} container direction="row">
            <Grid xs={12} lg={8} mb={5} item>
              {!transferFeeData.zeroFee && (
                <>
                  <Typography
                    sx={{
                      color: "rgba(0, 0, 0, 0.87)",
                      fontSize: 14,
                      textDecoration: transferFullData.globalData.actionCodeData
                        .actionCodeId
                        ? "line-through"
                        : "initial",
                    }}
                  >
                    {`${t("transfer.your-fee")} ${ratiorData.transferFee}% (${
                      transferFeeData.fee
                    } ${feeUnit} ${metalName}) ${t("transfer.as-fee")}`}
                  </Typography>

                  {transferFullData.globalData.actionCodeData.actionCodeId && (
                    <Typography
                      sx={{
                        color: "rgba(0, 0, 0, 0.87)",
                        fontSize: 14,
                      }}
                    >
                      {`${t("transfer.your-fee-only")}  ${
                        transferFeeData.discountPercentage
                      }% (${
                        transferFeeData.discountedFee
                      } ${feeUnit} ${metalName}) ${t("transfer.as-fee")}`}
                    </Typography>
                  )}
                </>
              )}
            </Grid>
            <Grid xs={12} lg={8} item>
              <Input
                control={control}
                onChange={(e) => {
                  applyDiscount(
                    GermanFloatToFloat(watch("amount") ?? 0)
                  );
                }}
                error={Boolean(errors.amount)}
                helperText={
                  errors.amount?.message ||
                  `${t("transfer.max-amount-message")} ${localeFormat(fromStorage.arades_verfuegbare_menge, { minimumFractionDigits: 4 })} (g)`
                }
                name="amount"
                label={`${t("transfer.enter-amount")} (g)`}
              />
            </Grid>
            <Grid xs={12} lg={1} item>
              <Button
                onClick={() => {
                  dispatch(
                    getStorageAmountAction(fromStorage.msdyn_warehouseid)
                  );
                }}
                sx={{
                  maxWidth: "192px",
                  height: 54,
                  marginLeft: 5,
                }}
                variant="outlined"
              >
                {t("transfer.max")}
              </Button>
            </Grid>
          </Grid>
        </Box>

        <FormStepperStructure.Footer>
          <Button
            onClick={handleSubmit(submitForm)}
            fullWidth
            sx={{ backgroundColor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            {t("buttons.next")}
          </Button>
        </FormStepperStructure.Footer>
      </Box>
    </LoadingOverlay>
  );
}

export default EnterAmount;
