import React from "react";
import NectRedirect from "../nect/nectRedirect";
import { Typography, Box, Button, Tooltip } from "@mui/material";
import { BASE_URL_UI } from "../../../core/apiconfig/environment";
import { useIsScreenSizeDesktop } from "../../../core/utils/responsive";
import "./ribbon.scss";

const Ribbon = ({
  description,
  buttonText,
  onClick,
  showNect,
  key,
  height,
  disabledReason = undefined,
  highlightButton = false,
}) => {
  const isDesktop = useIsScreenSizeDesktop();

  return (
    <Box
      key={key}
      sx={{
        backgroundColor: "#E3F2FD",
        height: height,
        display: "flex",
        flexDirection: isDesktop ? "row" : "column",
        justifyContent: "space-between",
        alignItems: isDesktop ? "center" : "flex-start",
        paddingLeft: isDesktop ? "72px" : "16px",
        paddingRight: isDesktop ? "72px" : "16px",
        paddingTop: "12px",
        paddingBottom: "12px",
        width: "100%",
      }}
    >
      <Typography
        as="div"
        sx={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.87)" }}
      >
        {description}
      </Typography>
      {buttonText && (
        <Tooltip title={disabledReason}>
          <span>
            <Button
              onClick={onClick}
              sx={{
                backgroundColor: "info.main",
                maxWidth: 450,
                maxHeight: "36px",
              }}
              disabled={!!disabledReason}
              className={highlightButton ? "highlight" : ""}
              variant="contained"
            >
              {buttonText}
            </Button>
          </span>
        </Tooltip>
      )}
      {showNect && (
        <>
          <NectRedirect
            style={{ marginTop: 13 }}
            title="Weiter zur Identifikation"
            redirectUrl={BASE_URL_UI + "?first-login=true"}
          ></NectRedirect>
        </>
      )}
    </Box>
  );
};

export default Ribbon;
