import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { FormStepperStructure, Select } from "../../../common";
import { useDispatch, useSelector } from "react-redux";
import {
  StorageSecurityStep,
  setStorageSecurityStep,
} from "../../../../redux/slices/storage/storage.slice";

function Security({ handleCurrentStep, currentStep }) {
  const dispatch = useDispatch();

  const securityInfo = useSelector(StorageSecurityStep);

  const [securityType, setSecurityType] = useState("both");
  const securityTypeOptions = [
    {
      key: "one",
      value:
        "Die Kunden wünschen jeweils eine Einzelverfügungsberechtigung („Oder“-Regelung)",
    },
    {
      key: "both",
      value:
        "Die Kunden wünschen eine gemeinschaftliche Verfügungsberechtigung („Und“-Regelung)",
    },
  ];

  useEffect(() => {
    setSecurityType(securityInfo.bothPartnerConfirm ? "both" : "one");
  }, []);

  const onSubmit = () => {
    let securityObj = { ...securityInfo };

    if (securityType == "both") securityObj.bothPartnerConfirm = true;
    else securityObj.eachPartnerConfirm = true;

    dispatch(setStorageSecurityStep(securityObj));

    handleCurrentStep("next");
  };
  return (
    <Box
      sx={{
        maxWidth: "816px",
        margin: "0 auto",
        marginTop: "32px",
      }}
    >
      <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.57)" }}>
        Bitte geben Sie an, ob jeder von Ihnen beiden einzeln über die
        Lagerplätze, Edelmetallkäufe und -verkäufe, sowie für alle übrigen
        Vertragsbelange verfügen darf, oder ob dies nur gemeinsam möglich ist.
      </Typography>

      <Box mt="20px" mb="30px">
        <Select
          name="securityType"
          value={securityType}
          onChange={(e) => setSecurityType(e.target.value)}
          label="Verfügungsberechtigung"
          options={securityTypeOptions}
        />
      </Box>
      <Box
        sm={12}
        mb="20px"
        sx={{
          width: "100%",
          borderRadius: "8px",
          backgroundColor: "#E3F2FD",
          padding: 10,
        }}
      >
        <Typography
          variant="subtitle2"
          mt="20px"
          mb="13px"
          sx={{
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          „Oder“-Regelung: Beide Kunden dürfen über ihre Edelmetallbestände ohne
          Mitwirkung des jeweils anderen Lagerplatzinhabers verfügen und
          zulasten des Lagerplatzes alle mit der Lagerplatzführung im
          Zusammenhang stehenden Vereinbarungen treffen und Erklärungen abgeben.
          Beide Kunden können die Einzelverfügungsberechtigung des anderen
          Depotinhabers jederzeit mit Wirkung für die Zukunft der Ratior AG
          gegenüber widerrufen. Über den Widerruf ist die Ratior AG unverzüglich
          schriftlich zu unterrichten. Anschließend können beide
          Lagerplatzinhaber nur noch gemeinsam über das Depot verfügen. Nach dem
          Tode eines Kunden bleiben die Befugnisse des anderen Kunden
          unverändert bestehen. Die Rechte des Verstorbenen werden durch dessen
          Erben gemeinschaftlich wahrgenommen. Das Recht zum Widerruf der
          Einzelverfügungsberechtigung steht jedoch jedem Erben allein zu.
          Widerruft ein Miterbe, bedarf jede Verfügung über die Lagerplätze
          seiner Mitwirkung. Widerrufen sämtliche Miterben die
          Einzelverfügungsberechtigung eines Lagerplatzinhabers, so können
          sämtliche Lagerplatzinhaber nur noch gemeinschaftlich mit sämtlichen
          Miterben über das Depot verfügen.
        </Typography>
      </Box>
      <Box
        sm={12}
        sx={{
          width: "100%",
          borderRadius: "8px",
          backgroundColor: "#E3F2FD",
          padding: 10,
        }}
      >
        <Typography
          variant="subtitle2"
          mb="13px"
          sx={{
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          „Und“-Regelung: Beide Kunden können über ihre Lagerplätze und ihre
          Edelmetallbestände nur gemeinschaftlich verfügen. Eine Änderung der
          Verfügungsberechtigung kann von den Lagerplatzinhabern nur
          gemeinschaftlich bestimmt werden. Nach dem Tode eines Kunden kann der
          andere Lagerplatzinhaber nur zusammen mit den Erben über das Depot
          verfügen oder dieses kündigen.
        </Typography>
      </Box>
      <FormStepperStructure.Footer>
        <Button
          onClick={onSubmit}
          fullWidth
          sx={{ bgcolor: "info.main", maxWidth: "192px" }}
          variant="contained"
        >
          Weiter
        </Button>
      </FormStepperStructure.Footer>
    </Box>
  );
}

export default Security;
