import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import MdArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import { URL, WORKSPACETYPE } from "../../../core/constants";
import StorageInfo from "./storageInfo";
import SubstorageList from "./substorageList";
import TransactionList from "./transactionList";
import {
  METAL_CHARS,
  STORAGE_UNDER_AGE_SECURITY,
} from "../../../core/constants";
import {
  CONTACT_ID,
  GUARDIAN1,
  GUARDIAN2,
  WORKSPACE_TYPE,
} from "../../../core/apiconfig/constantApi";
import { ConfirmModal } from "../../common";
import { useTranslation } from "react-i18next";
import { useIsScreenSizeDesktop } from "../../../core/utils/responsive";

function StorageDetail({ showSubstorageList }) {
  const id = useParams().id;
  const parentstorageid = useParams().parentstorageid;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const isDesktop = useIsScreenSizeDesktop();

  const [metal, setMetal] = useState();
  const [pending, setPending] = useState(true);
  const [storageDetail, setStorageDetail] = useState();
  const [openGaurdianAccessModal, setOpenGaurdianAccessModal] = useState(false);

  useEffect(() => {}, [pending]);

  const handleOperation = (path) => {
    let onlyGaurdian1 =
      storageDetail.arades_sicherheit ==
      STORAGE_UNDER_AGE_SECURITY.LEGAL_GUARDIAN_1;
    let onlyGaurdian2 =
      storageDetail.arades_sicherheit ==
      STORAGE_UNDER_AGE_SECURITY.LEGAL_GUARDIAN_2;
    let iAmGuardian1 =
      localStorage.getItem(CONTACT_ID) == localStorage.getItem(GUARDIAN1);
    let iAmGuardian2 =
      localStorage.getItem(CONTACT_ID) == localStorage.getItem(GUARDIAN2);

    if (localStorage.getItem(WORKSPACE_TYPE == WORKSPACETYPE.UNDER_AGE))
      if (
        (onlyGaurdian1 && !iAmGuardian1) ||
        (onlyGaurdian2 && !iAmGuardian2)
      ) {
        setOpenGaurdianAccessModal(true);
        return;
      }

    navigate(
      path + "/" + (parentstorageid ? parentstorageid + "/" : "") + id
    );
  };

  return (
    <div className="container-card">
      <ConfirmModal
        open={openGaurdianAccessModal}
        showCloseButton={false}
        title={t("storage-detail.guaridan-access-modal-title")}
        message={<div>{t("storage-detail.guaridan-access-modal-message")}</div>}
        noImage={true}
        okTitle={"OK"}
        onOk={() => {
          setOpenGaurdianAccessModal(false);
        }}
      ></ConfirmModal>
      <Box sx={{ maxWidth: "1536px" }}>
        {/* Header */}
        <Box
          sx={{
            marginBottom: "32px",
            display: "flex",
            flexDirection: isDesktop ? "row" : "column", // Buttons below headline on mobile
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              width: isDesktop ? "fit-content" : "100%",
            }}
          >
            <IconButton
              sx={{ fontSize: "15px", color: "#000" }}
              aria-label="back"
              onClick={() =>
                navigate(
                  parentstorageid
                    ? URL.STORAGE + "/" + parentstorageid
                    : URL.STORAGE
                )
              }
            >
              <MdArrowBackIosNew />
            </IconButton>
            <Typography sx={{ fontWeight: 500 }} variant="h5" component="h5">
              {t("storage-detail.title")}
            </Typography>
          </Box>
          <Box>
            {/* <Button
              disabled={pending}
              className="btn-center-text btn-theme-letter-space"
              sx={{
                fontWeight: 400,
                mx: 5,
                display:
                  metal == METAL_CHARS.GOLD || metal == METAL_CHARS.SILVER
                    ? "true"
                    : "none",
              }}
              variant="outlined"
              onClick={() => handleOperation(URL.EXCHANGEBASE)}
            >
              {t("storage-detail.exchange")}               
            </Button> */}
            <Button
              disabled={pending}
              className="btn-center-text btn-theme-letter-space"
              sx={{ fontWeight: 400, marginRight: 5 }}
              variant="outlined"
              onClick={() => handleOperation(URL.SELLBASE)}
            >
              {t("storage-detail.sell")}
            </Button>
            <Button
              disabled={pending}
              className={`btn-center-text btn-theme-letter-space ${
                !pending ? "btn-color-blue" : "btn-color-grey"
              }`}
              color="primary"
              sx={{ fontWeight: 400 }}
              variant="contained"
              onClick={() => handleOperation(URL.TRANSFERBASE)}
            >
              {t("storage-detail.transfer")}
            </Button>
          </Box>
        </Box>
        <Grid container spacing={8}>
          <Grid item sm={12} md={8} lg={6}>
            <Box sx={{ marginTop: 10 }}>
              <StorageInfo
                storageId={id}
                setMetal={setMetal}
                setPending={setPending}
                setStorageDetail={setStorageDetail}
              ></StorageInfo>
            </Box>
          </Grid>
          {showSubstorageList && (
            <Grid item sm={12} md={4} lg={6} sx={{ overflowX: "auto" /* make horizontally scrollable on mobile */ }}>
              <SubstorageList storageId={id} allowAdd={storageDetail && !storageDetail?._arades_uebergeordneter_lagerplatz_value /* Only allow adding new sub storages to main storages */}></SubstorageList>
            </Grid>
          )}
        </Grid>
        <Grid item sm={12}>
          <TransactionList
            storageId={id}
            metalName={
              metal == METAL_CHARS.GOLD
                ? t("metals.AU")
                : metal == METAL_CHARS.SILVER
                ? t("metals.AG")
                : metal == METAL_CHARS.PLATIN
                ? t("metals.PT")
                : metal == METAL_CHARS.PALLADIUM
                ? t("metals.PL")
                : ""
            }
          ></TransactionList>
        </Grid>
      </Box>
    </div>
  );
}

export default StorageDetail;
