import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { dateString, localeFormat, zeroPad } from "../../../core/utils/convert";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getLevels = async (workspaceId, level) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  const result = await axios.get(`${BASE_URL}data/structure/${workspaceId}/${level}/`, config);
  if (result?.data?.status != 200) {
    throw Error("Meine Struktur nicht geladen werden");
  }

  let list = [];
  result.data.data.value.map((item) => {
    let createdonStr =
      zeroPad(item.createyear, 4) +
      "-" +
      zeroPad(item.createmonth, 2) +
      "-" +
      zeroPad(item.createday, 0);
    let tmp = {
      accountid: item.accountid,
      arades_firmenname_partner: item["arades_firmenname_partner"],
      primaryContact:
        item["primarycontactid2@OData.Community.Display.V1.FormattedValue"],
      telephone3: item["telephone3"],
      arades_mobil_partner: item["arades_mobil_partner2"],
      emailaddress3: item["emailaddress3"],
      arades_address3_line1: item["arades_address3_line1"],
      arades_address3_line2: item["arades_address3_line2"],
      arades_address3_postalcode: item["arades_address3_postalcode"],
      arades_address3_city: item["arades_address3_city"],
      arades_land_partner:
        item["arades_land_partner2@OData.Community.Display.V1.FormattedValue"],
      sellerid: item.arades_vermittler,
      varades_firmenname_partner: item["varades_firmenname_partner"],
      vprimaryContact:
        item["vprimarycontactid2@OData.Community.Display.V1.FormattedValue"],
      vtelephone3: item["vtelephone3"],
      varades_mobil_partner: item["varades_mobil_partner2"],
      vemailaddress3: item["vemailaddress3"],
      varades_address3_line1: item["varades_address3_line1"],
      varades_address3_line2: item["varades_address3_line2"],
      varades_address3_postalcode: item["varades_address3_postalcode"],
      varades_address3_city: item["varades_address3_city"],
      varades_land_partner:
        item["varades_land_partner2@OData.Community.Display.V1.FormattedValue"],
      createdon: createdonStr,
      createdon_formatted: dateString(createdonStr),
      level: level,
      sum_arades_betrag: localeFormat(item["sum_arades_betrag"], { minimumFractionDigits: 2, currency: "EUR" }),
    };

    list.push(tmp);
  });

  return list.sort((a, b) =>
    a?.arades_firmenname_partner >= b?.arades_firmenname_partner ? -1 : 1
  );
};

const getMyStructureAction = createAsyncThunk(
  "myStructure/getmystructure",
  async (filter, { getState }) => {
    const { workspaceId } = filter;

    let level1 = await getLevels(workspaceId, 1);
    let level2 = await getLevels(workspaceId, 2);

    return level1.concat(level2);
  }
);

export default getMyStructureAction;
