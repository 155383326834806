import React, { useState } from "react";
import { useApi } from "../../../core/hooks/useApi";
import { BASE_URL, BASE_URL_UI } from "../../../core/apiconfig/environment";
import { useSnackbar } from "notistack";
import Button from "../../common/buttons/button";
import Input from "../../common/inputs/input";
import { URL } from "../../../core/constants";
import ConfirmModal from "../../common/popups/confirmModal";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "../../common/LoadingOverlay/LoadingOverlay";

import "./forgetPass.scss";

const ForgetPass = () => {
  const { post } = useApi();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async () => {
    //code verification service call
    let inputObj = {
      redirectUrl: BASE_URL_UI + "changepass",
      email: email,
    };
    setLoading(true);
    let result = await post(`${BASE_URL}user/password/forgot/email`, inputObj);
    setLoading(false);
    setConfirmOpen(true);
  };

  return (
    <LoadingOverlay show={loading}>
      <ConfirmModal
        open={confirmOpen}
        title="Die E-Mail zum Zurücksetzen des Passworts wurde gesendet"
        showCloseButton={false}
        size="lg"
        message={
          <div>
            Wir haben Ihre Anfrage, zur Wiederherstellung ihres Passwortes,
            erhalten.<br></br>
            Sollte uns Ihre eingegebene Email Adresse bekannt sein, dann haben
            wir Ihnen eine E-Mail mit einem Link zur Passwort Zurücksetzung
            zugeschickt. Bitte prüfen Sie Ihren Posteingang und gegebenfalls
            auch Ihren SPAM Ordner.
            <br></br>
            <br></br>
            Sollten Sie keine Email erhalten haben, dann nehmen Sie bitte mit
            dem Ratior Kundenservice kontakt auf.
            <br></br>
            E-Mail: Kunden@Ratior.de
            <br></br>
            Telefon: +49-(0)6227-65506-11
          </div>
        } //"Wir haben Ihr Ratior Key an Ihrer E-Mail-Adresse gesendet. Bitte überprüfen Sie Ihre E-Mail."
        okTitle="Login-Seite" //{"LOGIN PAGE"}
        onOk={() => navigate(URL.LOGIN)}
        onClose={() => setConfirmOpen(false)}
      ></ConfirmModal>
      <div className="box-wrapper">
        <div className="box-title">{"Passwort vergessen"}</div>
        <div className="step">E-Mail senden</div>
        <div className="box">
          <div className="form-item">
            <Input
              name={"email"}
              type="email"
              value={email}
              onChange={handleChange}
              label={"E-Mail"}
              className="form-item"
            />
          </div>
          <div className="padding-30">
            <Button
              disabled={!email}
              className={"form-item"}
              onClick={handleSubmit}
            >
              {"E-Mail senden"}
            </Button>
          </div>
          <div className="d-flex justify-content-center">
            <span className="down-description">
              Wenn Sie keinen Zugriff auf Ihre E-Mail-Adresse haben, wenden Sie
              sich bitte Kunden@Ratior.de, um Ihr Konto wiederherzustellen
            </span>
          </div>
          <hr className="margin-10"></hr>
          <div className="anotherLogin">
            <span
              onClick={() => {
                navigate(URL.LOGIN);
              }}
            >
              {"Melden Sie sich mit einem anderen Konto an"}
            </span>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default ForgetPass;
