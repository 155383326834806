import React from "react";
import smallLogo from "../../assets/images/smallLogo.png";
import { SelectLanguage } from "../common";
import { Box, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useIsScreenSizeDesktop } from "../../core/utils/responsive";

const Header = ({ fullname, showMenu, setShowMenu }) => {
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const isDesktop = useIsScreenSizeDesktop();

  return (
    <Box
      className="header"
      sx={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box ml="32px">
        <img src={smallLogo} width={119} alt="" />
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: "65px",
          }}
        >
          <SelectLanguage
            sx={{
              "& fieldset": {
                border: "none",
              },
            }}
          />
        </Box>
        {isDesktop && (
          <Box pr="40px" pl="10px" sx={{ display: "flex" }}>
            <Typography>{fullname}</Typography>
          </Box>
        )}
        {!isDesktop && (
          <Box pr="30px" pl="10px" sx={{ display: "flex" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={() => toggleMenu()}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Header;
