import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { FormStepperStructure } from "../../../common";
import { useDispatch } from "react-redux";
import { setPEP } from "../../../../redux/slices/storage/storage.slice";

function PEP({ handleCurrentStep }) {
  const dispatch = useDispatch();

  // const sendSMS = () => {
  //   if (!storageData.verification) {
  //     const currentAccountPhoneNumber = localStorage.getItem("mobileNumber");
  //     dispatch(setVerificationType("myself"));
  //     dispatch(
  //       verificationSendSMSCodeAction({
  //         mobileNumber: currentAccountPhoneNumber,
  //       })
  //     );
  //   }
  // };

  return (
    <>
      <Box
        sx={{
          marginTop: "32px",
        }}
      >
        <Box
          sm={12}
          sx={{
            margin: "0 auto",
            width: "100%",
            borderRadius: "8px",
            backgroundColor: "#E3F2FD",
            padding: 10,
          }}
        >
          <Typography
            mb="13px"
            as="div"
            sx={{
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Politisch Exponierte Person
          </Typography>

          <Typography
            variant="subtitle2"
            mb="13px"
            sx={{
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            Eine „politisch exponierte Person“ i.S.v. §1 Abs. 12 GwG ist eine
            natürliche Person, die ein hochrangiges wichtiges öffentliches Amt
            auf internationaler, europäischer oder nationaler Ebene oder
            unterhalb der nationalen Ebene, dessen politische Bedeutung
            vergleichbar ist, ausübt oder ausgeübt hat. Zu politisch exponierten
            Personen zählen insbesondere Staatschefs, Regierungschefs, Minister,
            Mitglieder der europäischen Kommission, stellvertretende Minister
            oder Staatssekretäre, Parlamentsabgeordnete und Mitglieder
            vergleichbarer Gesetzgebungsorgane, Mitglieder der Führungsgremien
            politischer Parteien, Mitglieder von obersten Gerichten,
            Verfassungsgerichten oder sonstigen hohen Gerichten, gegen deren
            Entscheidungen im Regelfall kein Rechtsmittel mehr eingelegt werden
            kann, Mitglieder der Leitungsorgane von Rechnungshöfen oder
            Zentralbanken, Botschafter, Geschäftsträger und
            Verteidigungsattachés, Mitglieder der Verwaltungs, Leitungs oder
            Aufsichtsorgane staatseigener Unternehmen, Direktoren,
            stellvertretene Direktoren, Mitglieder des Leitungsorgans oder
            sonstige Leiter mit vergleichbarer Funktion in einer
            zwischenstaatlichen internationalen oder europäischen Organisation.
            Familienmitglieder i.S.v. § 1 Abs. 13 GwG sind Ehepartner,
            eingetragene Lebenspartner, die Kinder und deren Ehepartner oder
            eingetragene Lebenspartner oder die Eltern. „Bekanntermaßen
            nahestehende Person“ ist eine natürliche Person, bei der Grund zur
            Annahme besteht, dass sie gemeinsam mit oder allein zugunsten einer
            politisch exponierten Person wirtschaftlich Berechtigter einer
            juristischen Person, einer Personengesellschaft, eines Trusts, einer
            nichtrechtsfähigen Stiftung mit eigennützigem Stiftungszweck oder
            einer Rechtsgestaltung, die einer solchen Stiftung entspricht, ist
            oder enge Geschäftsbeziehungen zu einer politisch exponierten Person
            unterhält.
          </Typography>
        </Box>
        <Typography
          variant="subtitle2"
          mt="30px"
          mb="13px"
          sx={{
            fontSize: "16px",
            fontWeight: "400",
          }}
        >
          Ist der Kunde eine politisch exponierte Person im nachfolgend
          genannten Sinne oder mit einer verwandt oder ihr nahe stehend?
        </Typography>
        <FormStepperStructure.Footer>
          <Button
            onClick={() => {
              dispatch(setPEP(true));
              //paricher age political bood che konim?
            }}
            fullWidth
            sx={{
              borderColor: "info.main",
              color: "info.main",
              marginRight: 10,
              maxWidth: "192px",
            }}
            variant="outlined"
          >
            Ja
          </Button>
          <Button
            onClick={() => {
              dispatch(setPEP(false));
              //sendSMS();
              handleCurrentStep("next");
            }}
            fullWidth
            sx={{ bgcolor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            Nein
          </Button>
        </FormStepperStructure.Footer>
      </Box>
    </>
  );
}

export default PEP;
