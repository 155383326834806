import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import Input from "../common/inputs/input";
import { FormStepperStructure } from "../common";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import {
  setEnterDescription,
  sellEnterDescription,
} from "../../redux/slices/sell/sell.slice";

function EnterDescriptionStep({ handleCurrentStep }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const fields = useSelector(sellEnterDescription);

  const submitForm = (data) => {
    dispatch(setEnterDescription(data.description));
    handleCurrentStep("next");
  };

  const schema = yup.object().shape({
    //description: yup.string().required(requiredMsg),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      ...fields,
      agreed: false,
    },
    resolver: yupResolver(schema),
  });
  return (
    <>
      <Box
        sx={{
          margin: "0 auto",
          minHeight: "calc(100vh - 250px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box sx={{ minWidth: "50%", margin: "0 auto" }}>
          <Grid spacing={1} container direction="column">
            <Grid xs={12} lg={8} item>
              <Typography variant="body1" mb="15px">
                {t("sell.description-description")}
              </Typography>
              <Input
                control={control}
                // error={Boolean(errors.description)}
                // helperText={errors.description?.message}
                name="description"
                label={t("sell.description")}
              />
            </Grid>
            <Grid xs={12} lg={8} item>
              <Typography variant="caption" mt="15px">
                {t("sell.description-valid-1")}
                <br></br>
                {t("sell.description-valid-2")}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <FormStepperStructure.Footer>
          <Button
            onClick={handleSubmit(submitForm)}
            fullWidth
            sx={{ backgroundColor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            {t("buttons.next")}
          </Button>
        </FormStepperStructure.Footer>
      </Box>
    </>
  );
}

export default EnterDescriptionStep;
