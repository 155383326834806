import styled from "@emotion/styled";
import { IconButton, Button, Box } from "@mui/material";
import { css } from "@emotion/react";

const setColor = ({ c }) =>
  c &&
  css`
    color: ${c};
  `;
const setPaddingLeft = ({ pl }) =>
  pl &&
  css`
    padding-left: ${pl};
  `;
const setPaddingRight = ({ pr }) =>
  pr &&
  css`
    padding-right: ${pr};
  `;
export const FormStepContainer = styled(Box, { shouldForwardProp: (prop) => prop !== "withoutFlexAlign" })`
  display: flex;
  align-items: ${({ withoutFlexAlign }) =>
    !withoutFlexAlign ? "center" : "initial"};
  flex-direction: column;
  justify-content: ${({ withoutFlexAlign }) =>
    !withoutFlexAlign ? "space-between" : "initial"};
  min-height: ${({ minHeight }) =>
    !minHeight ? "calc(100vh - 200px)" : minHeight};
`;
export const FormStepHeader = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  margin-bottom: 32px;
  clear: both;
`;
export const FormStepFooter = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 30px;
`;

export const StepBack = styled(IconButton)`
  font-size: 15px;
  padding-left: "12px";
  padding-right: "12px";
  ${setColor}
  ${setPaddingLeft}
    ${setPaddingRight}
`;
export const StepRightButton = styled(Button)`
  margin-left: auto;
  min-width: fit-content;
`;
