import React, { useState } from "react";
import { FormStepper } from "../../common";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetAll } from "../../../redux/slices/powerOfAttorney/powerOfAttorney.slice";
import { URL } from "../../../core/constants";

import PersonStep from "./choosePerson.step";
import SelecStorageStep from "./selectStorage.step";
import SelectFinalStorageAndSubstoragesStep from "./selectFinalStorageAndSubstorages.step";
import FutureSubstoragesStep from "./futureSubstorages.step";
import SummaryStep from "./summary.step";


function PowerOfAttorneyAddStepManager() {
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [shareData, setShareData] = useState({});
  const stepsCount = 5;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const HandleCurrentStep = (step,nextStepData) => {
    setShareData(nextStepData)
    window.scrollTo(0, 0); // Scroll up to the top because it is confusing on mobile if the scroll position stays
    if (isNaN(parseInt(step))) {
      if (step === "next" && currentStep < stepsCount) {
        setCurrentStep((prev) => prev + 1);
      } else if (step === "prev" && currentStep > 1) {
        setCurrentStep((prev) => prev - 1);
      }
    } else {
      if (currentStep <= stepsCount && currentStep >= 1) {
        setCurrentStep(step);
      }
    }
  };
  const handleCancel = () => {
    dispatch(resetAll());
    navigate(URL.POWER_OF_ATTORNEY_BASE);
  };


  return (
    <div className="container-card">
      <FormStepper
        currentStep={currentStep}
        setCurrentStep={HandleCurrentStep}
        loading={loading}
        noFooter={[1,2,3,4,5]}
        hasCancel={[1,2,3,4,5]}
        onCancel={handleCancel}
        headerTitles={["Power of attorney", "Select Storage","Select Substorages","Future Substorages","Select Substorages"]}
      >
        {/* Step 1 */}
        <PersonStep handleCurrentStep={HandleCurrentStep} />
        {/* Step 2 */}
        <SelecStorageStep handleCurrentStep={HandleCurrentStep} />
        {/* Step 3 */}
        <SelectFinalStorageAndSubstoragesStep handleCurrentStep={HandleCurrentStep} />
        {/* Step 4 */}
        <FutureSubstoragesStep handleCurrentStep={HandleCurrentStep} />
        {/* Step 5 */}
        <SummaryStep  handleCurrentStep={HandleCurrentStep} />
      </FormStepper>
    </div>
  );
}

export default PowerOfAttorneyAddStepManager;
