import React, { useState } from "react";
import { FormStepper } from "../common";
import { useTranslation } from "react-i18next";

import Lists from "./lists.step";
import MessageDetailStep from "./messageDetail.step";

function VermittlerDashboardStepManager() {
  const { t } = useTranslation();

  const [currentStep, setCurrentStep] = useState(1);
  const [shareData, setShareData] = useState({});
  const stepsCount = 2;

  const HandleCurrentStep = (step, nextStepData) => {
    setShareData(nextStepData);
    window.scrollTo(0, 0); // Scroll up to the top because it is confusing on mobile if the scroll position stays
    if (isNaN(parseInt(step))) {
      if (step === "next" && currentStep < stepsCount) {
        setCurrentStep((prev) => prev + 1);
      } else if (step === "prev" && currentStep > 1) {
        setCurrentStep((prev) => prev - 1);
      }
    } else {
      if (currentStep <= stepsCount && currentStep >= 1) {
        setCurrentStep(step);
      }
    }
  };

  return (
    <div className="container-card">
      <FormStepper
        currentStep={currentStep}
        setCurrentStep={HandleCurrentStep}
        noHeader={[1]}
        noFooter={[1, 2]}
        headerTitles={[
          "",
          shareData?.to ? t("inbox.sent-list") : t("inbox.received-list"),
        ]}
      >
        {/* Step 1 */}
        <Lists handleCurrentStep={HandleCurrentStep} />
        {/* Step 2 */}
        <MessageDetailStep
          shareData={shareData}
          handleCurrentStep={HandleCurrentStep}
        />
      </FormStepper>
    </div>
  );
}

export default VermittlerDashboardStepManager;
