import React from "react";
import { SnackbarProvider } from "notistack";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const NotiStackProivder = ({ children }) => {
  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  const { t } = useTranslation();

  return (
    <SnackbarProvider
      //hideIconVariant={true}
      ref={notistackRef}
      maxSnack={5}
      action={(key) => (
        <Button
          variant="outlined"
          sx={{ border: "none", color: "white" }}
          onClick={onClickDismiss(key)}
        >
          {t("buttons.close")}
        </Button>
      )}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default NotiStackProivder;
